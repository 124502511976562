<template>
    <div
      class="w-full space-y-10"
    >
      <!-- First Filters -->
      <div
        :class="getActive('priceRangeDollar','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
        class="-border -border-black  focus:outline-none cursor-pointer focus:ring-0 -bg-blue-500 px-2 flex items-center justify-between py-3 w-full h-full"
      >
        <div
          class="flex flex-col justify-between gap-3 -items-center w-full h-full"
        >
          <span class="text-[16px] font-[700] text-[#001a2c] leading-[15px]">
            Price/Indicator Relationship
          </span>
          <div class="w-[80%] gap-3 flex items-center">
           
            <select
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 16%; height: 45px; font-size: 16px"
              type="select"
              v-model="settings_price_range_dollar"
            >
              <option
                v-for="type of breakingTypeOptions"
                :value="type.value"
                :key="type.value"
              >
                {{ type.text }}
              </option>
            </select>
            
       
          </div> 
          
          <div>
            <DescriptionInput :description="`Stocks that experienced a ${getSetting('break','direction')} at the ${getSetting('break','type')} price within the last ${getSetting('break','period')} days.`" />
          </div>
        </div>
        <!-- check icon -->
        <div class="flex justify-center items-center w-auto h-full">
          <span class="cursor-pointer" @click="handleCheck('priceRangeDollar')">
            <svg
              width="28"
              height="29"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
                :fill="getActive('priceRangeDollar','isActive')?'#1C274C':'#E2E2E2'"
              />
            </svg>
          </span>
        </div>
      </div>

      
  
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import DescriptionInput from '../../../component/DescriptionInput.vue'
  export default {
    components: { DescriptionInput },
    name: 'PatternFilter',
    props: {
      dialogId: {
        type: String,
        default: 'checkbox',
      },
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        breakDirectionOptions: [
          {
            text: 'breakup',
            value: 'breakup',
          },
          {
            text: 'breakdown',
            value: 'breakdown',
          },
        ],
        breakingTypeOptions: [
          {
            text: 'High',
            value: 'high',
          },
          {
            text: 'Low',
            value: 'low',
          },
          {
            text: 'Close',
            value: 'close',
          },
        ],
        peakTypeOptions: [
          {
            text: 'High',
            value: 'high',
          },
          {
            text: 'Low',
            value: 'low',
          },
        ],
        tciBreakProps: [
          { label: 'Up Trend', value: 'up' },
          { label: 'Down Trend', value: 'down' },
          { label: 'Horizontal', value: 'neutral' },
        ],
      }
    },
    computed: {
      ...mapGetters([
        'filterSettings',
        'themePrimaryColor',
        'themeSecondaryColor',
        'eventUpdates',
        'getFilterSettings',
        'themeFontColor',
        'userChoice',
        'themeDarkMode',
      ]),
      filterSettingData() {
        return this.getFilterSettings(this.id)
      },
      settings_price_range_dollar: {
        get() {
          return this.getSetting('priceRangeDollar', 'start')
        },
        set(value) {
          this.setSetting('priceRangeDollar', 'start', value)
        },
      },
    },
    methods: {
      ...mapActions(['setEventsFilterSettings']),
      setSetting(key1, key2, value) {
        let settings = this.filterSettingData
        if(key1 == 'matchCombination'){
          settings[key1] = value
        }else{
          settings[key1][key2] = value
        }
        this.setEventsFilterSettings({
          l: 'settings_single',
          id: this.id,
          settings,
        })
      },
      handleCheck(key){
          let val = this.getActive(key,'isActive')
          this.setSetting(key,'isActive',!val)
      },
      getSetting(key1, key2) {
        let settings = this.filterSettingData
        console.log('patterns=======>', key1, key2)
        console.log('settings===>', key1, key2, settings)
        if (settings && settings[key1] && settings[key1][key2]) {
          console.log('if setting===>', settings, settings[key1][key2])
          return settings[key1][key2]
        }
        //  else {
        //   console.log("this.settings[key1][key2]",settings['priceRangeDollar']['start'])
        // //   return this.settings[key1][key2]
        // }
        // this.setEventsFilterSettings({l:"settings_single",id:this.id,settings})
      },
  
      getActive(key1, key2) {
        let settings = this.getFilterSettings(this.id)
        if(key1 == 'matchCombination'){
          return settings[key1]
        }else{
          return settings[key1][key2]
        }
      },
    },
    mounted() {},
    watch: {},
  }
  </script>
  
  <style></style>
  