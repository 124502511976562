<template>
    <div class="p-8">
      <div class="-bg-black -text-white py-2">
        <div
          class="flex justify-start !items-center gap-2 w-auto h-[40px] -bg-slate-300"
        >
          <span class="-bg-green-400 font-[700] text-[20px] text-[#454545] leading-5"
            >Heiken Ashi Trend Change Detection</span
          >
          <span
            class="w-[32px] h-[32px] flex justify-center items-center -border"
          >
            <svg
              viewBox="0 0 24 25"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              transform="matrix(-1, 0, 0, -1, 0, 0)"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12 7h1v7h-1zm1.5 9.5a1 1 0 1 0-1 1 1.002 1.002 0 0 0 1-1zm9.3-4A10.3 10.3 0 1 1 12.5 2.2a10.297 10.297 0 0 1 10.3 10.3zm-1 0a9.3 9.3 0 1 0-9.3 9.3 9.31 9.31 0 0 0 9.3-9.3z"
                ></path>
                <path fill="none" d="M0 0h24v24H0z"></path>
              </g>
            </svg>
          </span>
        </div>
        <span class="-bg-yellow-300 -pt-1">
          Identify periods where the Heiken Ashi candlestick trend shifts, signaling potential trend reversals.</span>
      </div>
      <!-- Button Tabs Components -->
      <div v-if="selectedButtonTypeName == 'Heiken Ashi'">
        <HeikenAshiComp :dialogId="dialogId" class="fontSize" :id="id" />
      </div>
    </div>
  </template>
  
  <script>
  import EventsFilter from './EventsFilter.vue'
  import PatternFilter from './PatternFilter.vue'
  import Filters from './Filters.vue'
  import HeikenAshiComp from './HeikenAshiComp.vue'
  
  export default {
    name: 'HeikenAshi',
    components: { EventsFilter, HeikenAshiComp, PatternFilter, Filters },
    props: {
      selectedButtonTypeName: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        id: 'patterns',
        dialogId: 'chartEvent',
      }
    },
    methods: {},
    computed: {},
    mounted() {},
  }
  </script>
  
  <style></style>
  