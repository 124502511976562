export default {
    state: {
        connection: null,
        clientToken: null,
    },
    mutations: {
        SET_CONNECTION(state, connection) {
            state.connection = connection;
        },
        SET_CLIENT_TOKEN(state, token) {
            state.clientToken = token;
        },
    },
    actions: {
        setConnection({commit}, connection) {
            commit('SET_CONNECTION', connection);
        },
        setClientToken({commit}, token) {
            commit('SET_CLIENT_TOKEN', token);
        },
    },
    getters: {
        getConnection(state) {
            return state.connection;
        },
        getClientToken(state) {
            return state.clientToken;
        },
    },
}