<template>
    <div
      class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden"
    >
      <div class="w-full h-full text-[#000000] !overflow-x-hidden">
  
        <div
          class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
        >
          <ModalHeader :header="header" @close="closeRemoveModal"/>
        </div>
  
        <div
          class="px-8 py-3 border-b border-t border-[#E2E8F0] w-full flex flex-col -justify-center min-h-[130px] -min-h-[158px]"
        >
          <div class="h-full flex flex-col gap-2 justify-center">
            <span class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[16px] font-normal">{{message}}</span>
            <span v-if="showError" class="text-errorText font-normal"
              >{{ showError }}
            </span>
            <span v-if="showSuccess" class="text-[#0CAF2F] font-normal"
              >{{ showSuccess }}
            </span>
          </div>
        </div>
  
        <div
          class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
        >
          <ModalFooter
            firstTitle="No, Keep this"
            secondTitle="Yes, Remove"
            colorType="color"
            :isLoading="isLoading"
            @close="closeRemoveModal"
            @save="handleSave"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import Loader from './Loading.vue'
  import ModalHeader from './ModalHeader.vue'
  import ModalFooter from './ModalFooter.vue'
  
  export default {
    name: 'RemoveAlertModal',
  
    data() {
      return {
       
      }
    },
  
    props: {
        header:String,
        message:String,
        isLoading:Boolean,
        showError:String,
        showSuccess:String,
    },
  
    components: {
      Loader,
      ModalHeader,
      ModalFooter,
    },
  
    computed: {
      ...mapGetters(['getTheme']),
    },
  
    methods: {
      closeRemoveModal() {
        this.$emit('closeModal')
      },
      handleSave(){
        this.$emit('remove')
      },
      updateScreenWidth() {
        this.screenWidth = window.innerWidth
      },
    },
  
    watch: {
    
    },
  
    mounted() {
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('resize', this.updateScreenWidth)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      window.addEventListener('resize', this.updateScreenWidth)
    },
  }
  </script>
  
  <style scoped>
  @media screen and (max-width: 500px) {
    .suggestionText {
      font-size: 8px !important;
    }
  }
  @media screen and (max-width: 375px) {
    .suggestionText {
      font-size: 7px !important;
      white-space: nowrap;
    }
  }
  
  .scroll-container {
    /* Adjust height as needed */
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
  }
  .scroll-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  </style>
  