<template>
    <div class="analyst-rating-component">
      <div class="rating-bar">
        <!-- Dynamic price marker -->
        <div
          class="current-price-marker flex flex-col"
          :style="{ left: currentPricePosition + '%' }"
        >
        <span class="text-[11px] whitepsace-nowrap text-[#001A2C] font-bold -mb-2">
            Current
        </span>
        <span class="text-[11px] text-[#001A2C]">
            {{ currentPrice?.toFixed(2) }}
        </span>
        <span :class="getArrowColor">
            ▼
        </span>
        </div>

        <div class="absolute -top-[12px] mean w-full">
            <svg width="2" height="27" viewBox="0 0 2 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="4.37113e-08" x2="0.999999" y2="27" stroke="#001A2C" stroke-width="2" stroke-dasharray="2 2"/>
            </svg>
        </div>
     
        <div class="absolute -top-[12px] median w-full">
            <svg width="2" height="44" viewBox="0 0 2 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="4.37113e-08" x2="0.999998" y2="44" stroke="#001A2C" stroke-width="2" stroke-dasharray="2 2"/>
            </svg>

        </div>

  
        <!-- Static labels for lowest, mean, median, highest -->
        <div class="rating-label lowest">
            <div>
                <svg width="15" height="15" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.70215 -7.86805e-07L8.59926 6.75L0.805035 6.75L4.70215 -7.86805e-07Z" fill="#F21E13"/>
                </svg>
            </div>
            <div class="font-bold">
                Lowest
            </div>
            <div class = "-mt-1.5">
                {{ lowest?.toFixed(2) }}
            </div>
        </div>
        <div class="rating-label mean">
            <div class=" mt-[5px] font-bold">
                Mean
            </div>
            <div class="-mt-2">
                {{ mean?.toFixed(2) }}
            </div>
        </div>
        <div class="rating-label median mt-[20px]">
            <div class="font-bold">
                Median
            </div>
            <div class="-mt-2">
                {{ median?.toFixed(2) }}
            </div>
        </div>
        <div class="rating-label highest flex flex-col items-end">
            <div>
                <svg width="15" height="15" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.70215 -7.86805e-07L8.59926 6.75L0.805035 6.75L4.70215 -7.86805e-07Z" fill="#41901C"/>
                </svg>
            </div>
            <div class="font-bold">
                Highest
            </div>
            <div class = "-mt-1.5">
                {{ highest?.toFixed(2) }}
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      lowest: {
        type: Number,
        required: true,
      },
      mean: {
        type: Number,
        required: true,
      },
      median: {
        type: Number,
        required: true,
      },
      highest: {
        type: Number,
        required: true,
      },
      currentPrice: {
        type: Number,
        required: true,
      },
    },
    computed: {
      // Calculate the current price position as a percentage of the range
      currentPricePosition() {
        const range = this.highest - this.lowest;
        if (range === 0) return 0;
        return ((this.currentPrice - this.lowest) / range) * 100;
      },
      getArrowColor(){
        if(this.currentPrice > this.median){
            return 'text-[#41901c]'
        }else{
            return 'text-red-600'
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .analyst-rating-component {
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .rating-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, rgba(255, 62, 62, 1) 0%, rgba(65, 144, 28, 1) 100%);
    margin: 60px 0 80px 0;
  }
  
  .rating-label {
    position: absolute;
    top: 10px;
    font-size: 11px;
    color: #001A2C;
    text-align: center;
    transform: translateX(-50%);
  }
  
  .lowest {
    left: 3%;
  }
  
  .mean {
    left: 30%;
  }
  
  .median {
    left: 50%;
  }
  
  .highest {
    left: 97%;
  }
  
  .current-price-marker {
    position: absolute;
    top: -55px;
    font-size: 18px;
  }
  
  .current-price-label {
    margin-top: 10px;
    font-size: 16px;
  }
  </style>
  