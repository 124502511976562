export const scannerConfig = [
    {
        id: 1,
        name: "Charting Patterns",
        lists: [
            {
                id: 1,
                name: 'Charting Patterns',
                isToggle: true,
                data: [
                    { name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true, key: 'saucarBottom' },
                    { name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false, key: 'ascendingTriangle' },
                    { name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false, key: 'descendingTriangle' },
                    { name: "Double Bottom", icon: "Double-Bottom", type: "checkbox", isSelected: false, key: 'doubleBottom' },
                    { name: "Double Top", icon: "Double-Top", type: "checkbox", isSelected: false, key: 'doubleTop' },
                    { name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bullishKeyReversal' },
                    { name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bearishKeyReversal' },
                    { name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false, key: 'triPointResistance' },
                    { name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox", isSelected: false, key: 'triPointSupport' },
                    { name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox", isSelected: false, key: 'bullishPennant' },
                    { name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox", isSelected: false, key: 'bearishPennant' },
                    { name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox", isSelected: false, key: 'bullishFlag' },
                    { name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox", isSelected: false, key: 'bearishFlag' },

                ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    // Activity
    {
        id: 2,
        name: "Activity",
        lists: [
            {
                id: 1,
                name: "Activity",
                isToggle: true,
                mode: '',
                parentSelectedRadioButton: "top",
                data: [
                    {
                        id: 1,
                        name: 'top',
                        // isSelected: false,
                        isRadioButtonSelected: true,
                        topMethod: '',
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            { name: "", type: "number", label: 'Top', topCount: '', default: 10, placeholder: '', isSelected: false },
                            { name: 'volume', label: 'stocks by', type: 'option', selectedOption: 'volume', option: ['volume', 'priceIncrease', 'priceIncreasePct', 'marketCap'], isSelected: false }
                        ],
                    },
                    {
                        id: 2,
                        name: 'closedOnHigh',
                        // isSelected: false,
                        isRadioButtonSelected: false,
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            { name: 'All stocks that closed on thrir high', label: 'All stocks that closed on thrir high', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        name: 'closedOnHighPct',
                        // isSelected: false,
                        isRadioButtonSelected: false,
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            { name: 'All stocks that closed within', label: 'All stocks that closed within', label2: "% within their high", type: 'number', default: 5, placeholder: '', isSelected: false }
                        ],
                    },
                    {
                        id: 4,
                        name: 'closedOnLow',
                        // isSelected: false,
                        isRadioButtonSelected: false,
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            { name: 'All stocks that closed on their low', label: 'All stocks that closed on their low', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        name: 'closedOnLowPct',
                        // isSelected: false,
                        isRadioButtonSelected: false,
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            { name: 'All stocks that closed within', label: 'All stocks that closed within', label2: '% within their low', type: 'number', default: 5, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        name: 'range',
                        // isSelected: false,
                        isRadioButtonSelected: false,
                        inputs: [
                            { name: "radio", type: "radio", label: '', default: true, isSelected: false },
                            // {name: "",label: 'All stocks that closed within a price range from', default: false, isSelected: false},
                            { name: 'priceRangeStart', label: 'All stocks that closed within a price range from', label2: '', priceRangeStart: '', type: 'number', default: 1, placeholder: '', isSelected: false },
                            { name: 'priceRangeEnd', label: 'to', label2: '', priceRangeEnd: '', type: 'number', default: 5, placeholder: '', isSelected: false },
                        ],
                    }
                ]
            },
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 3,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 3,
        name: "Candlestick",
        lists: [
            {
                id: 1,
                name: 'Candlestick',
                isToggle: true,
                data: [
                    { name: "Doji", icon: "Doji", type: "checkbox", default: true, isSelected: true, key: 'doji' },
                    { name: "Bullish Harami", icon: "Bullish-Harami", type: "checkbox", default: false, isSelected: false, key: 'bullishHarami' },
                    { name: "Bearish Harami", icon: "Bearish-Harami", type: "checkbox", default: false, isSelected: false, key: 'bearishHarami' },
                    { name: "Bullish Harami Cross", icon: "Bullish-Harami-Cross", type: "checkbox", default: false, isSelected: false, key: 'bullishHaramiCross' },
                    { name: "Bearish Harami Cross", icon: "Bearish-Harami-Cross", type: "checkbox", default: false, isSelected: false, key: 'bearishHaramiCross' },
                    { name: "Hammer", icon: "Hammer", type: "checkbox", default: false, isSelected: false, key: 'hammer' },
                    { name: "Inverter Hammer", icon: "Inverter-Hammer", type: "checkbox", default: false, isSelected: false, key: 'invertedHammer' },
                    { name: "Shooting Star", icon: "Shooting-Star", type: "checkbox", default: false, isSelected: false, key: 'shootingStar' },
                    { name: "Hanging Man", icon: "Hanging-Man", type: "checkbox", default: false, isSelected: false, key: 'hangingMan' },
                    { name: "Bullish Engulfing", icon: "Bullish Engulfing", type: "checkbox", default: false, isSelected: false, key: 'bullishEngulfing' },
                    { name: "Bearish Engulfing", icon: "Bearish-Pennant", type: "checkbox", default: false, isSelected: false, key: 'bearishEngulfing' },
                    // {
                    //     id: 1,
                    //     isSelected: false,
                    //     inputs: [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false},
                    //         {name: 'Scan for',  label: 'Scan for', label2: 'Multiple Inside Bars', type: 'number',default: 2, placeholder: '',isSelected:false},
                    //     ]
                    // },
                    // {
                    //     id: 2,
                    //     isSelected: false,
                    //     inputs: [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false},
                    //         {name: 'Scan for >',  label: 'Scan for >', label2: 'Inside Bars', type: 'number',default: 5, placeholder: '',isSelected:false},
                    //     ]
                    // },
                ]
            },
            // Filter
            {
                id: 2,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 3,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 4,
        name: "Technical Indicators",
        lists: [
            {
                id: 1,
                name: "Technical Indicators",
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        key: 'crossesAndAboveBelow',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: "isActive" },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                            { name: 'is Above', label: '', type: 'option', selectedOption: 'is Above', option: ['is Above', 'high', 'low'], isSelected: false },
                            { name: 'MA', label: '', type: 'option', selectedOption: 'MA', option: ['MA', 'high', 'low'], isSelected: false },
                            { name: '', label: '', label2: '', type: 'number', default: 14, placeholder: '', isSelected: false },
                        ]
                    },
                    {
                        id: 2,
                        isSelected: false,
                        key: 'ROC',
                        selectedRadio: 'overbought',
                        mode: 'mode',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: "isActive" },
                            { name: 'ROC', label: 'ROC', label2: '', type: 'number', period: 'period', default: 10, placeholder: '', isSelected: false },
                            { name: '', id: 'Overbought', value: 'Overbought', radioLabel: 'overbought', type: 'radio', isSelected: false },
                            { name: '', id: 'Oversold', value: 'Oversold', radioLabel: 'oversold', type: 'radio', isSelected: false },
                        ]
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'RSI',
                        selectedRadio: 'overbought',
                        mode: 'mode',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: "isActive" },
                            { name: 'RSI', label: 'RSI', label2: '', type: 'number', period: 'period', default: 18, placeholder: '', isSelected: false },
                            { name: '', id: 'Overbought', value: 'Overbought', radioLabel: 'overbought', type: 'radio', isSelected: false },
                            { name: '', id: 'Oversold', value: 'Oversold', radioLabel: 'oversold', type: 'radio', isSelected: false },
                        ]
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'STOC',
                        selectedRadio: 'overbought',
                        mode: 'mode',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: "isActive" },
                            { name: 'STOC', label: 'STOC', label2: '', type: 'number', period: 'period', default: 15, placeholder: '', isSelected: false },
                            { name: 'Period', label: 'Period', label2: '', type: 'number', kPercent: 'kPercent', default: 3, placeholder: '', isSelected: false },
                            { name: '%k', label: '%k', label2: '', type: 'number', dPercent: 'dPercent', default: 3, placeholder: '', isSelected: false },
                            { name: '', id: 'Overbought', value: 'Overbought', radioLabel: 'overbought', type: 'radio', isSelected: false },
                            { name: '', id: 'Oversold', value: 'Oversold', radioLabel: 'oversold', type: 'radio', isSelected: false },
                        ]
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'BANDS',
                        selectedRadio: 'top',
                        breakMode: 'breakMode',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: "isActive" },
                            { name: 'Bli', label: 'Bollinger Band of period', label2: '', type: 'number', period: 'period', default: 21, placeholder: '', isSelected: false },
                            { name: '', id: 'Top Break', value: 'Top Break', radioLabel: 'top', type: 'radio', isSelected: false },
                            { name: '', id: 'Bottom Break', value: 'Bottom Break', radioLabel: 'bottom', type: 'radio', isSelected: false },
                        ]
                    },

                ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    // Completed Scanner with response
    {
        id: 5,
        name: "Darvas Box",
        lists: [
            {
                id: 1,
                name: "Darvas Box",
                isToggle: true,
                data: [
                    {
                        id: 1,
                        // isSelected: false,
                        key: 'scanType',
                        inputs: [
                            { name: 'Scan Type', label: 'Scan Type', label2: '', type: '', default: '', placeholder: '' },
                            { name: 'long', label: '', type: 'option', selectedOption: 'long', option: ['long', 'short'] },
                        ]
                    },
                    {
                        id: 2,
                        // isSelected: false,
                        key: 'scanPeriod',
                        inputs: [
                            { name: 'Scan Period', label: 'Scan Period', label2: '', type: '', default: '', placeholder: '', },
                            { name: '', label: '', label2: '', type: 'number', default: 30, placeholder: '', },
                        ]
                    },
                    {
                        id: 3,
                        // isSelected: false,
                        key: 'topConfirmationPeriod',
                        inputs: [
                            { name: 'Top confirmation period', label: 'Top confirmation period', label2: '', type: '', default: '', placeholder: '', },
                            { name: '', label: '', label2: '', type: 'number', default: 3, placeholder: '' },
                        ]
                    },
                    {
                        id: 4,
                        // isSelected: false,
                        key: 'bottomConfirmationPeriod',
                        inputs: [
                            { name: 'Bottom confirmation period', label: 'Bottom confirmation period', label2: '', type: '', default: '', placeholder: '', },
                            { name: '', label: '', label2: '', type: 'number', default: 3, placeholder: '' },
                        ]
                    },
                    {
                        id: 5,
                        // isSelected: false,
                        key: 'percentageRiskBuffer',
                        inputs: [
                            { name: 'percentage risk buffer', label: 'percentage risk buffer', label2: '', type: '', default: '', placeholder: '' },
                            { name: '', label: '', label2: '', type: 'number', default: 2, placeholder: '' },
                        ]
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'limitTotalRisk',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'limit total risk to', label: 'limit total risk to', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            { name: '', label: '', label2: '', type: 'number', valuePct: 'valuePct', default: 20, placeholder: '', isSelected: false },
                        ]
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'volumeIncreasedOver',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'percent', label: 'Volume increased over', label2: '', type: 'number', default: 300, percent: '', placeholder: '', isSelected: false },
                            { name: 'previousBars', label: '% on previous', label2: 'bar(s)', type: 'number', default: 1, previousBars: '', placeholder: '', isSelected: false },
                            // {name: '',  label: '', label2: '', type: 'number', default: 20, placeholder: '',isSelected:false},
                        ]
                    },
                    {
                        id: 8,
                        isSelected: false,
                        selectedRadio: 'anyBox',
                        key: 'inBox',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'In a box', label: 'In a box', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            { name: '', id: 'Any Box', value: 'anyBox', radioLabel: 'anyBox', type: 'radio', isSelected: false },
                            { name: '', id: '1st Box', value: '1stBox', radioLabel: '1stBox', type: 'radio', isSelected: false },
                        ]
                    },
                    {
                        id: 9,
                        isSelected: false,
                        selectedRadio: 'close',
                        key: 'within',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'With the', label: 'With the', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            { name: 'close', label: '', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                            { name: '', id: 'close', value: 'close', radioLabel: 'close', type: 'radio', isSelected: false },
                            { name: '', id: 'High within', value: 'high', radioLabel: 'High within', type: 'radio', isSelected: false },
                            { name: 'pricePoints', label: '', label2: 'price points below the top', type: 'number', default: 5, placeholder: '', isSelected: false },
                        ]
                    },
                    {
                        id: 10,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'restrictBoxToMaxBox',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Restrict box sequences to a maximum of', label: 'Restrict box sequences to a maximum of', label2: 'boxes', type: 'number', default: 5, placeholder: '', isSelected: false },
                        ]
                    },
                    {
                        id: 11,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'restrictCurrentBoxWidth',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'start', label: 'Restrict box sequences to a maximum of', label2: '', type: 'number', default: 1, placeholder: '', isSelected: false },
                            { name: 'end', label: 'end', label2: 'bars', type: 'number', default: 10, placeholder: '', isSelected: false },
                        ]
                    },
                ]
            },
            {
                id: 2,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 3,
                name: 'Scan Type'
            }
        ]
    },
    // Working Scanner with response
    {
        id: 6,
        // name: "Fundamental",
        name: "Fundamental",
        lists: [
            {
                id: 1,
                name: "Measures",
                key: 'Measures',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: false,
                        key: "earningYield",
                        selectedRadio: 'more',
                        inputs: [
                            { name: 'check', label: 'earning yeild', type: 'checkbox', isSelected: false },
                            { name: '', label: '', label2: '%', type: 'number', default: 5, isSelected: false },
                            { name: '', id: 'more', value: 'more', radioLabel: 'more', type: 'radio', isSelected: false },
                            { name: '', id: 'less', value: 'less', radioLabel: 'less', type: 'radio', isSelected: false },
                        ]
                    },
                    {
                        id: 2,
                        isSelected: false,
                        key: 'peRatio',
                        selectedRadio: 'more',
                        inputs: [
                            { name: 'check', label: 'P/E ratio', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more', value: 'more', radioLabel: 'more', type: 'radio', isSelected: false },
                            { name: '', id: 'less', value: 'less', radioLabel: 'less', type: 'radio', isSelected: false },
                            { name: '', label: '', label2: '%', type: 'number', default: 20, isSelected: false }
                        ]
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'dividendYield',
                        selectedRadio: 'more',
                        inputs: [
                            { name: 'check', label: 'Divided yeild', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more', value: 'more', radioLabel: 'more', type: 'radio', isSelected: false },
                            { name: '', id: 'less', value: 'less', radioLabel: 'less', type: 'radio', isSelected: false },
                            { name: '', label: '', label2: '%', type: 'number', default: 5, isSelected: false }
                        ]
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'frankedPct',
                        selectedRadio: '100',
                        inputs: [
                            { name: 'check', label: 'With franking creadits', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more than', value: '100', radioLabel: '100% only', type: 'radio', isSelected: false },
                            { name: '', id: 'less than', value: 'anyCredit', radioLabel: 'any credit', type: 'radio', isSelected: false },
                        ]
                    }
                ]
            },
            {
                id: 2,
                name: "Capitalisation",
                key: 'capitalisation',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: false,
                        key: 'topSymbols',
                        inputs: [
                            { name: 'check', label: 'Must be in the top', type: 'checkbox', isSelected: false },
                            { name: '', label: '', label2: 'stocks by the market cap', type: 'number', default: 20, isSelected: false },
                        ]
                    },
                    {
                        id: 2,
                        isSelected: false,
                        key: 'sharesIssued',
                        selectedRadio: 'less',
                        inputs: [
                            { name: 'check', label: 'Shared issued', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more than', value: 'more', radioLabel: 'more than', type: 'radio', isSelected: false },
                            { name: '', id: 'less than', value: 'less', radioLabel: 'less than', type: 'radio', isSelected: false },
                            { name: '', label: '', label2: 'million', type: 'number', default: 5, isSelected: false }
                        ]
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'marketCap',
                        selectedRadio: 'more',
                        inputs: [
                            { name: 'check', label: 'Shares issued', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more', value: 'more', radioLabel: 'more than', type: 'radio', isSelected: false },
                            { name: '', id: 'less', value: 'less', radioLabel: 'less than', type: 'radio', isSelected: false },
                            { name: '', label: '', label2: 'million', type: 'number', default: 5, isSelected: false }
                        ]
                    },
                ]
            },
            {
                id: 3,
                name: "Returns",
                isToggle: true,
                key: 'returns',
                data: [
                    {
                        id: 1,
                        isSelected: false,
                        selectedRadio: 'less',
                        inputs: [
                            { name: 'check', label: 'Returns', type: 'checkbox', isSelected: false },
                            { name: '', id: 'more than', value: 'more', radioLabel: 'more than', type: 'radio', isSelected: false },
                            { name: '', id: 'less than', value: 'less', radioLabel: 'less than', type: 'radio', isSelected: false },
                            { name: '', label: '', label2: '% for the last', type: 'number', default: 100, isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'return12m', option: ['return12m', 'return3m', 'return6m', 'return1m'], isSelected: false },
                            { name: '', label: '', label2: '', type: 'number', default: 12, isSelected: false },
                        ]
                    },
                ]
            },
            // Filter
            {
                id: 2,
                name: 'Filter',
                isToggle: true,
                data: [
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        name: "Scan Type"
                    }
                ]
            }
        ]
    },
    {
        id: 7,
        name: "Capitulation Days",
        lists: [
            {
                id: 1,
                name: 'Capitulation Days',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isToggle: true,
                        inputs: [
                            { name: 'volumeMultiplier', label: 'Any volume of the last two days is at least', type: 'number', label2: '', default: 2, placeholder: '', isSelected: false },
                            { name: 'lastVolumeAvgPeriod', label: 'times greater than the average volume of the', type: 'number', label2: 'days before them', default: 5, placeholder: '', isSelected: false },
                        ]
                    }
                ]
            },
            {
                id: 2,
                name: "Scan Type"
            }

        ]
    },
    {
        id: 8,
        name: "Higher High, Higher Low",
        isToggle: true,
        lists: [
            {
                id: 1,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 10,
        name: "Bollinger Squeeze",
        lists: [
            {
                name: "Bollinger Squeeze",
                isToggle: true,
                id: 1,
                data: [
                    {
                        id: 1,
                        isSelected: false,
                        key: "minScanningPeriod",
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Min scanning period', label: 'Min scanning period', label2: '', type: 'number', default: 200, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        key: 'maxScanningPeriod',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Max scanning period', label: 'Max scanning period', label2: '', type: 'number', default: 250, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'dayFromToday',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Days from today when squeeze started breaking out', label: 'Days from today when squeeze started breaking out', label2: '', type: 'number', default: 5, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'minSqueezeLength',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Min squeeze length', label: 'Min squeeze length', label2: '', type: 'number', default: 200, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'maxSqueezeLength',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Max squeeze length', label: 'Max squeeze length', label2: '', type: 'number', default: 5, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ratioOfSpikeHeight',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false },
                                { name: 'Ratio of spike height to a squeeze height', label: 'Ratio of spike height to a squeeze height', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 2,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 11,
        name: "Big Candle",
        lists: [
            {
                name: "Big Candle",
                isToggle: true,
                id: 1,
                data: [
                    {
                        id: 1,
                        isToggle: true,
                        inputs: [
                            { name: 'Find a candle', key: 'candleSizeMultiplier', label: 'Find a candle', type: 'number', label2: '', default: 2, placeholder: '', isSelected: false },
                            { name: '', key: 'previousCandlePeriod', label: 'times larger than previous', type: 'number', label2: 'candles', default: 5, placeholder: '', isSelected: false },
                        ]
                    }
                ]
            },
            {
                id: 2,
                name: "Scan Type"
            }

        ]
    },
    {
        id: 12,
        name: "Heiken Ashi",
        lists: [
            {
                name: "Heiken Ashi",
                isToggle: true,
                id: 1,
                data: [
                    {
                        id: 1,
                        isToggle: true,
                        inputs: [
                            { name: 'Find a period of', key: 'period', label: 'Find a period of', type: 'number', label2: 'when Heiken Ashi trend changes', default: 10, placeholder: '', isSelected: false },
                        ]
                    }
                ]
            },
            {
                id: 2,
                name: "Scan Type"
            }

        ]
    },

    {
        id: 13,
        name: "Pro Reversal Scan",
        lists: [
            {
                id: 1,
                name: 'Pro Reversal Scan',
                isToggle: true,
                data: [
                    { name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true, key: 'saucarBottom' },
                    { name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false, key: 'ascendingTriangle' },
                    { name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false, key: 'descendingTriangle' },
                    { name: "Double Bottom", icon: "Double-Bottom", type: "checkbox", isSelected: false, key: 'doubleBottom' },
                    { name: "Double Top", icon: "Double-Top", type: "checkbox", isSelected: false, key: 'doubleTop' },
                    { name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bullishKeyReversal' },
                    { name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bearishKeyReversal' },
                    { name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false, key: 'triPointResistance' },
                    { name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox", isSelected: false, key: 'triPointSupport' },
                    { name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox", isSelected: false, key: 'bullishPennant' },
                    { name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox", isSelected: false, key: 'bearishPennant' },
                    { name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox", isSelected: false, key: 'bullishFlag' },
                    { name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox", isSelected: false, key: 'bearishFlag' },

                ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 14,
        name: "Pro Swing Scan",
        lists: [
            {
                id: 1,
                name: 'Pro Swing Scan',
                isToggle: true,
                data: [
                    { name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true, key: 'saucarBottom' },
                    { name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false, key: 'ascendingTriangle' },
                    { name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false, key: 'descendingTriangle' },
                    { name: "Double Bottom", icon: "Double-Bottom", type: "checkbox", isSelected: false, key: 'doubleBottom' },
                    { name: "Double Top", icon: "Double-Top", type: "checkbox", isSelected: false, key: 'doubleTop' },
                    { name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bullishKeyReversal' },
                    { name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox", isSelected: false, key: 'bearishKeyReversal' },
                    { name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false, key: 'triPointResistance' },
                    { name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox", isSelected: false, key: 'triPointSupport' },
                    { name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox", isSelected: false, key: 'bullishPennant' },
                    { name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox", isSelected: false, key: 'bearishPennant' },
                    { name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox", isSelected: false, key: 'bullishFlag' },
                    { name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox", isSelected: false, key: 'bearishFlag' },

                ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 14,
        name: "Pro Trade Scan",
        lists: [
            {
                id: 1,
                name: 'Pro Trade Scan',
                isToggle: true,
                // data: [
                //     {name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true,key: 'saucarBottom'},
                //     {name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false,key: 'ascendingTriangle'},
                //     {name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false,key: 'descendingTriangle'},
                //     {name: "Double Bottom", icon: "Double-Bottom", type: "checkbox",  isSelected: false,key: 'doubleBottom'},
                //     {name: "Double Top", icon: "Double-Top", type: "checkbox",  isSelected: false,key: 'doubleTop'},
                //     {name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false,key: 'bullishKeyReversal'},
                //     {name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox",  isSelected: false,key: 'bearishKeyReversal'},
                //     {name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false,key: 'triPointResistance'},
                //     {name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox",  isSelected: false,key: 'triPointSupport'},
                //     {name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox",  isSelected: false,key: 'bullishPennant'},
                //     {name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox",  isSelected: false,key: 'bearishPennant'},
                //     {name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox",  isSelected: false,key: 'bullishFlag'},
                //     {name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox",  isSelected: false,key: 'bearishFlag'},

                // ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 14,
        name: "Pro Down Trade Scan",
        lists: [
            {
                id: 1,
                name: 'Pro Down Trade Scan',
                isToggle: true,
                // data: [
                //     {name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true,key: 'saucarBottom'},
                //     {name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false,key: 'ascendingTriangle'},
                //     {name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false,key: 'descendingTriangle'},
                //     {name: "Double Bottom", icon: "Double-Bottom", type: "checkbox",  isSelected: false,key: 'doubleBottom'},
                //     {name: "Double Top", icon: "Double-Top", type: "checkbox",  isSelected: false,key: 'doubleTop'},
                //     {name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false,key: 'bullishKeyReversal'},
                //     {name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox",  isSelected: false,key: 'bearishKeyReversal'},
                //     {name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false,key: 'triPointResistance'},
                //     {name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox",  isSelected: false,key: 'triPointSupport'},
                //     {name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox",  isSelected: false,key: 'bullishPennant'},
                //     {name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox",  isSelected: false,key: 'bearishPennant'},
                //     {name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox",  isSelected: false,key: 'bullishFlag'},
                //     {name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox",  isSelected: false,key: 'bearishFlag'},

                // ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 14,
        name: "Pro Divergence Scan",
        lists: [
            {
                id: 1,
                name: 'Pro Divergence Scan',
                isToggle: true,
                // data: [
                //     {name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true,key: 'saucarBottom'},
                //     {name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false,key: 'ascendingTriangle'},
                //     {name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false,key: 'descendingTriangle'},
                //     {name: "Double Bottom", icon: "Double-Bottom", type: "checkbox",  isSelected: false,key: 'doubleBottom'},
                //     {name: "Double Top", icon: "Double-Top", type: "checkbox",  isSelected: false,key: 'doubleTop'},
                //     {name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false,key: 'bullishKeyReversal'},
                //     {name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox",  isSelected: false,key: 'bearishKeyReversal'},
                //     {name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false,key: 'triPointResistance'},
                //     {name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox",  isSelected: false,key: 'triPointSupport'},
                //     {name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox",  isSelected: false,key: 'bullishPennant'},
                //     {name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox",  isSelected: false,key: 'bearishPennant'},
                //     {name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox",  isSelected: false,key: 'bullishFlag'},
                //     {name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox",  isSelected: false,key: 'bearishFlag'},

                // ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
    {
        id: 14,
        name: "Run Pro Breakout",
        lists: [
            {
                id: 1,
                name: 'Run Pro Breakout',
                isToggle: true,
                // data: [
                //     {name: "Saucar Button", icon: "Saucar-Button", type: "checkbox", isSelected: true,key: 'saucarBottom'},
                //     {name: "Ascending Triangle", icon: "Ascending-Triangle", type: "checkbox", isSelected: false,key: 'ascendingTriangle'},
                //     {name: "Descending Triangle", icon: "Descending-Triangle", type: "checkbox", isSelected: false,key: 'descendingTriangle'},
                //     {name: "Double Bottom", icon: "Double-Bottom", type: "checkbox",  isSelected: false,key: 'doubleBottom'},
                //     {name: "Double Top", icon: "Double-Top", type: "checkbox",  isSelected: false,key: 'doubleTop'},
                //     {name: "Bullish Key Reversal", icon: "Bullish-Key-Reversal", type: "checkbox", isSelected: false,key: 'bullishKeyReversal'},
                //     {name: "Bearish Key Reversal", icon: "Bearish-Key-Reversal", type: "checkbox",  isSelected: false,key: 'bearishKeyReversal'},
                //     {name: "Tri-Point Resistance", icon: "Tri-Point-Resistance", type: "checkbox", isSelected: false,key: 'triPointResistance'},
                //     {name: "Tri-Point Support", icon: "Tri-Point-Support", type: "checkbox",  isSelected: false,key: 'triPointSupport'},
                //     {name: "Bullish Pennant", icon: "Bullish-Pennant", type: "checkbox",  isSelected: false,key: 'bullishPennant'},
                //     {name: "Bearish Pennant", icon: "Bearish-Pennant", type: "checkbox",  isSelected: false,key: 'bearishPennant'},
                //     {name: "Bullish Flag", icon: "Bullish-Flag", type: "checkbox",  isSelected: false,key: 'bullishFlag'},
                //     {name: "Bearish  Flag", icon: "Bearish-Flag", type: "checkbox",  isSelected: false,key: 'bearishFlag'},

                // ]
            },
            // Events
            {
                id: 2,
                name: 'Events',
                isToggle: true,
                data: [
                    {
                        id: 1,
                        isSelected: true,
                        selectedRadio: '',
                        key: 'break',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'bar(s)', label: '', type: 'number', default: 30, placeholder: '', period: 'period' },
                            { name: 'breakup', label: '', type: 'option', selectedOption: 'breakup', key: 'direction', option: ['breakup', 'breakedown'], isSelected: false },
                            { name: 'close', label: '', type: 'option', selectedOption: 'close', payloadType: 'type', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 2,
                        isSelected: false,
                        selectedRadio: '',
                        key: 'prebreak',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: '%', label: 'Within', type: 'number', default: 50, placeholder: '', isSelected: false, period: 'period' },
                            { name: 'bar(s)', label: 'of last', type: 'number', default: 2, placeholder: '', isSelected: false, key: 'pct' },
                            { name: 'close', label: '', type: 'option', payloadType: 'type', selectedOption: 'close', option: ['close', 'high', 'low'], isSelected: false },
                        ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'volumeIncrease',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Increase volume for', label: 'Increase volume for', type: 'number', default: 4, period: 'period', placeholder: '', isSelected: false },
                            { name: 'of data', label: 'of data', type: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'tciBreak',
                        direction: 'direction',
                        selectedRadio: 'down',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI Break', label: 'TCI Break', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up', value: 'Up', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down', value: 'Down', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Up', value: 'All', radioLabel: 'All', type: 'radio', isSelected: false },
                        ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'tciTrend',
                        selectedRadio: 'up',
                        direction: 'direction',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'TCI', label: 'TCI', type: '', label2: '', default: false, placeholder: '', isSelected: false },
                            { name: '', id: 'Up Trend', value: 'Up Trend', radioLabel: 'up', type: 'radio', isSelected: false },
                            { name: '', id: 'Down Trend', value: 'Down Trend', radioLabel: 'down', type: 'radio', isSelected: false },
                            { name: '', id: 'Horizontal', value: 'Horizontal', radioLabel: 'Horizontal', type: 'radio', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 21, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'macd',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'MACD', label: 'MACD', type: 'number', label2: 'from a crossover', default: 2, period: 'period', placeholder: '', isSelected: false },
                            { name: 'fastPeriod', key: 'fastPeriod', default: 12, value: 1 },
                            { name: 'slowPeriod', key: 'slowPeriod', default: 26, value: 3 },
                            { name: 'signalPeriod', key: 'signalPeriod', default: 9, value: 2 },
                        ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'obv',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'OBV', label: 'OBV', type: 'number', key: 'pctAbove', label2: 'about the level of', default: 1, placeholder: '', isSelected: false },
                            { name: '', label: '', type: 'number', label2: '', default: 2, period: 'period', placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'volumeMore',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Vol. more than', label: 'Vol. more than', type: 'number', label2: '', default: 1300, key: 'pct', placeholder: '', isSelected: false },
                            { name: '', label: 'above highest vol. of the previous', type: 'number', label2: '', period: 'period', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 9,
                        isSelected: false,
                        key: 'trendLineCrossing',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Trend line crossing in the last', label: 'Trend line crossing in the last', period: 'period', type: 'number', label2: '', default: 1, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 10,
                        isSelected: false,
                        key: 'gapUp',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap up by at least', cents: "cents", label: 'Find any stock that left a gap up by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 11,
                        isSelected: false,
                        key: 'gapDown',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Find any stock that left a gap down by at least', cents: "cents", label: 'Find any stock that left a gap down by at least', type: 'number', label2: '', default: 10, placeholder: '', isSelected: false },
                        ],
                    },
                    {
                        id: 12,
                        isSelected: false,
                        key: 'matchCombination',
                        inputs: [
                            { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                            { name: 'Match selected events in combination', label: 'Match selected events in combination', type: '', label2: '', default: '', placeholder: '', isSelected: false },
                        ],
                    },

                ]
            },
            // Filter
            {
                id: 3,
                name: 'Filter',
                isToggle: true,
                data: [
                    // {
                    //     id: 1,
                    //     ikey: '',sSelected: false,

                    //     inputs:
                    //     [
                    //         {name: 'check',  label: '', type: 'checkbox',isSelected: false, isActive: 'isActive'},
                    //         {name: 'close',  label: '', type: 'option',selectedOption: 'close',option: ['close','high','low'],isSelected:false},
                    //         {name: 'is Above',  label: '', type: 'option',selectedOption: 'is Above',option: ['is Above','high','low'],isSelected:false},
                    //         {name: 'MA',  label: '', type: 'option',selectedOption: 'MA',option: ['MA','high','low'],isSelected:false},
                    //         {name: '',  label: '', type: 'number',default: 20,isSelected:false},
                    //     ],
                    //  },
                    {
                        id: 2,
                        isSelected: true,
                        key: 'priceRangeDollar',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Price range in dollars from', key: 'start', label: 'Price range in dollars from', type: 'number', default: 1, placeholder: '', isSelected: false },
                                { name: 'to', label: 'to', type: 'number', key: 'end', default: 100, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 3,
                        isSelected: false,
                        key: 'ignoreResults',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have less than', key: 'minDays', label: 'Ignore results that have less than', label2: 'of data', type: 'number', default: 280, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 4,
                        isSelected: false,
                        key: 'isIgnoreResultsWithNoRangeForLastDay',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have no trading range for the last day', label: 'Ignore results that have no trading range for the last day', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 5,
                        isSelected: false,
                        key: 'onlyShowResultsThatHaveTraded',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Only show results that have traded in the past', key: 'pastDays', label: 'Only show results that have traded in the past', label2: '', type: 'number', default: 10, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 6,
                        isSelected: false,
                        key: 'ignoreResultsWithValueLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have value less than', key: 'value', label: 'Ignore results that have value less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 7,
                        isSelected: false,
                        key: 'ignoreResultsWithVolumeLess',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Ignore results that have volume less than', key: 'volume', label: 'Ignore results that have volume less than', label2: '', type: 'number', default: 25000, placeholder: '', isSelected: false },
                            ],
                    },
                    {
                        id: 8,
                        isSelected: false,
                        key: 'isExcludeIntraDayData',
                        inputs:
                            [
                                { name: 'check', label: '', type: 'checkbox', isSelected: false, isActive: 'isActive' },
                                { name: 'Exclude intra-day data', label: 'Exclude intra-day data', label2: '', type: '', default: '', placeholder: '', isSelected: false },
                            ],
                    },
                ]
            },
            {
                id: 4,
                name: "Scan Type"
            }
        ]
    },
]



export const scannerTabs = {
    patterns: [
        { name: 'Charting Patterns' },
        { name: 'Events' },
        { name: 'Filters' },
    ],
    activity: [{ name: 'Activity' }, { name: 'Filters' }],
    candlesticks: [{ name: 'Candlestick' }, { name: 'Filters' }],
    indicators: [
        { name: 'Technical Indicators' },
        { name: 'Events' },
        { name: 'Filters' },
    ],
    darvasScan: [{ name: 'Darvas Box' }, { name: 'Filters' }],
    fundementals: [
        { name: 'Measures' },
        { name: 'Capitalization' },
        { name: 'Returns' },
        { name: 'Filters' },
    ],
    capitulation: [{ name: 'Capitulation Scan' }],
    hhhl: [{ name: 'Higher High, Higher Low' }],
    bollingerSqueeze: [{ name: 'Bollinger Squeeze' }],
    divergenceScan: [{ name: 'Divergense Scan' }],
    bigCandle: [{ name: 'Big Candle' }],
    heikenAshi: [{ name: 'Heiken Ashi' }],
    pocketPivot: [{ name: "Pocket Pivot" }],
    proReversalSignal: [{ name: 'ProReversal Signal' }, { name: 'Filters' }],
    proDowntrendMonitor: [{ name: 'ProDowntrend Monitor' }, { name: 'Events' }, { name: 'Filters' }],
    proBreakoutAlert: [{ name: 'ProBreakout Alert' }, { name: 'Filters' }],
    proDivergenceWatch: [
        { name: 'ProDivergence Watch' },
        { name: 'Events' },
        { name: 'Filters' },
    ],
    proSwingBoost: [
        { name: 'ProSwing Boost' },
        { name: 'Events' },
        { name: 'Filters' },
    ],
    proSwingDrop: [
        { name: 'ProSwing Drop' },
        { name: 'Events' },
        { name: 'Filters' },
    ],
}