<template>
  <div class="input-container">
    <input
      :type="type"
      :placeholder="placeholder"
      :value="value"
      v-model="inputValue"
      @input="handleInput"
      @keydown.enter="handleEnterPress"
      :style="{
        border: isError ? '1px solid red' : '1px solid #E0E0E0',
        color: isError ? '#F20E17' : '#333333',
      }"
      class="hide-number-arrows hoverBorder focus:outline-none focus:ring-0"
      :class="inputColor"
    />
    <span
      v-if="iconImg"
      class="input-icon"
      :style="{
        background: imageColor ? '#f6f6f6' : 'white',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
      }"
    >
      <img :src="iconImg" @click="togglePasswordVisibility" />
    </span>
  </div>
</template>
  
  <script>
export default {
  name: 'DynamicInput',
  props: {
    imageColor: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: 'Enter text',
    },
    iconImg: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    inputColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.inputValue)
    },
    togglePasswordVisibility() {
      this.$emit('password-visibility-toggled') // Emit the custom event
    },
    handleEnterPress() {
      this.$emit('enter-pressed')
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.inputValue = newVal
      },
      immediate: true,
    },
  },

  mounted() {},
}
</script>
  
  <style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
}

.input-container input {
  padding: 10px 15px 10px 15px;
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
input::placeholder {
  color: #bdbdbd;
}

.hoverBorder:hover {
  border: 1px solid #bdbdbd !important;
}
input:focus {
  outline: none;
  border: 1px solid #828282;
}
input {
  padding-left: 20px !important; /* You can adjust the value as needed */
  font-size: 16px;
}

.input-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  margin-right: 2px;
  padding: 8px;
}
.hide-number-arrows::-webkit-inner-spin-button,
.hide-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
</style>