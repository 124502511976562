<template>
  <div>
    <select
      v-model="inp.selectedOption"
      :class="val.isSelected ? '!border-appBlue' : '!border-appBlue'"
      class="w-full radio-no-outline px-3 dark:border-[#2D2D2D] border-[1px] text-[#7A7A7A] dark:text-[#6C707A] dark:bg-[#FFFFFF1A] py-2 rounded-[5px] focus:outline-none !focus:ring-0"
    >
      <option
        v-for="option in inp.option"
        :key="option"
        :value="option"
        class="text-[#7A7A7A] dark:text-[#6C707A] dark:bg-[#FFFFFF1A]"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    inp: Object,
    val: Object,
  },
}
</script>

<style scoped>
.radio-no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid gray !important;
}
</style>
