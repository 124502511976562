<template>
  <div
    class="w-full !h-full flex justify-between gap-3 -border-t -border-[#E2E8F0] dark:border-[#2D2D2D] !items-center"
  >
    <div>
      <span
        v-if="!hideSave"
        :class="[
          infoBtnTitle == 'Add to favourite' || infoBtnTitle == 'Add'
            ? 'block'
            : 'hidden',
          width <= 600
            ? 'w-[100px] flex justify-center overflow-hidden rounded'
            : '',
        ]"
      >
        <ButtonComp
          :title="infoBtnTitle"
          colorType="green"
          @press="handleAddToFavourite"
        />
      </span>
    </div>
    <ProgressiveBar
      :stockMessage="stockMessage"
      :percentage="progressPercentageProps"
      v-if="isProgressBar && getIsShowPro"
    />
    <div class="flex items-center gap-3">
      <span
        v-if="!hideClose"
        :class="[
          firstTitle == '' ? 'hidden' : 'block',
          firstTitle == 'Cancel' && width <= 600
            ? '!w-[100px] bg-slate-400 border flex justify-center overflow-hidden rounded'
            : '',
        ]"
      >
        <ButtonComp :title="firstTitle" @press="closeModal" />
      </span>
      <span
        v-if="!hideSave && !isLoading"
        :class="[
          secondTitle == '' ? 'hidden' : 'block',
          secondTitle == 'Save' && width <= 600
            ? '!w-[100px] border flex justify-center overflow-hidden rounded'
            : '',
        ]"
      >
        <ButtonComp
          :title="secondTitle"
          :colorType="colorType"
          :isDisable="isDisable"
          :hideSave="hideSave"
          @press="handleSave"
          :isLoading="isLoading"
        />
      </span>
      <span v-if="isLoading" :class="thirdTitle == '' ? 'hidden' : 'block'">
        <ButtonComp
          :title="thirdTitle"
          :colorType="colorType"
          :hideSave="hideSave"
          @press="stopScan"
          :isLoading="isLoading"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonComp from './ButtonComp.vue'
import ProgressiveBar from './ProgressiveBar.vue'

export default {
  name: 'ModalFooter',
  data() {
    return {
      width: window.innerWidth,
    }
  },
  props: [
    'infoBtnTitle',
    'firstTitle',
    'secondTitle',
    'thirdTitle',
    'isLoading',
    'colorType',
    'isDisable',
    'hideSave',
    'hideClose',
    'addIcon',
    'isProgressBar',
    'progressPercentageProps',
    'scannerId',
    'getIsShowPro'
  ],
  components: {
    ButtonComp,
    ProgressiveBar,
   
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleSave() {
      this.$emit('save')
    },
    stopScan() {
      this.$emit('stopScan')
    },
    handleAddToFavourite() {
      this.$emit('addToFav')
    },
    onResize() {
      this.width = window.innerWidth
    },
  },
  computed: {
    ...mapGetters(['getProgressiveBarForPattern','getProgressiveBarForGuru','getIsShowProgressiveBar']),
    stockMessage() {
      return this.progressPercentageProps ?  `${this.progressPercentageProps} scanned out of 6,5000 stocks` : "Progress"
    },
    idForBar(){
      return this.getProgressiveBarForPattern(this.scannerId)
    },
    getIsShowPro(){
      return this.getIsShowProgressiveBar
    },
    
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    console.log("scannerIdFooter==>",this.scannerId);
  },
  beforeDestroy() {
    window.addEventListener('resize')
  },
}
</script>
