
import ToolSettings from './ToolSettings.vue'
import Main from './main.js'
Main.__name__ = 'tool-settings'

const widgets = { ToolSettings }
const components = {  }
const overlays = {  }
const colorpacks = {  }
const skins = {  }

const Pack = {
  widgets,
  ToolSettings,
  components,
  overlays,
  colorpacks,
  skins,
  Main
}

export default Pack

export {
  widgets,
  ToolSettings,
  components,
  overlays,
  colorpacks,
  skins,
  Main
}
