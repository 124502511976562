var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_vm._m(0),_c('ul',{staticClass:"!w-full h-full bg-white -overflow-y-scroll dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] overflow-y-scroll scroll-container"},[_c('div',{staticClass:"w-full h-full flex flex-col -space-y-1"},_vm._l((_vm.navBtns),function(chartType,index){return _c('li',{key:index,staticClass:"flex w-full h-[5rem] items-center space-x-2 gap-2 --px-8 py-4 -border-b text-appBtnTxt dark:!text-[#6C707A] -rounded-md",class:[
          _vm.isActive == chartType?.name
            ? 'bg-appBtnActive dark:!bg-transparent !text-appBtnActiveTxt dark:!text-[#F6F6F6] !border-appBtnActive'
            : '-hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover -!border-[transparent]',
          _vm.getDisable(chartType.key)
            ? 'pointer-events-none opacity-50 cursor-default'
            : 'cursor-pointer',
        ]},[_c('div',{staticClass:"flex px-4 py-6 items-center space-x-2",class:[chartType?.name == 'indicator' ? 'w-[90%]' : 'w-full',_vm.isActive == chartType?.name
            ? 'bg-appBtnActive dark:!bg-transparent !text-appBtnActiveTxt dark:!text-[#F6F6F6] !border-appBtnActive'
            : '-hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover -!border-[transparent]' ],on:{"click":function($event){return _vm.newSettings(chartType.name)}}},[_c('div',{class:chartType?.name === _vm.isActive
                ? '!text-appBtnActiveTxt'
                : 'text-[#323232]'},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getSvg(chartType.icon, _vm.isActive))}})]),_c('div',{staticClass:"!text-[16px]"},[_vm._v(_vm._s(chartType.btn))])]),(_vm.getSelectedIndicators?.length > 0 && _vm.ohlcv?.length > 0)?_c('div',{staticClass:"bg-white rounded-sm p-2 absolute right-6",class:chartType?.name == 'indicator' ? '' : 'hidden',attrs:{"title":"Delete all indicators"},on:{"click":_vm.toggleRemoveIndicator}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getSvg(chartType.deleteIcon))}})]):_vm._e()])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"},[_c('span',[_vm._v("Nav buttons")])])
}]

export { render, staticRenderFns }