<template>
  <div v-if="open" class="overflow-hidden" style="z-index: 12005">
    <BaseModal>
      <div
        class="qm:w-[45vw] lg:!w-[35vw] sm:w-[50vw] md:w-[24vw] xxs:w-[88vw] h-[27vh] !overflow-hidden flex flex-col"
      >
        <!-- <div class="h-[15%] flex items-center px-4 justify-between">
          <span class="text-[18px] font-sans dark:text-white font-[500]">{{
            header
          }}</span>
          <div
            @click="onClose"
            class="cursor-pointer p-[8px] rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                width="18"
                height="18"
              >
                <path
                  stroke="currentColor"
                  stroke-width="1.2"
                  d="m1.5 1.5 15 15m0-15-15 15"
                ></path>
              </svg>
            </span>
          </div>
        </div> -->

        <!--Header-->

        <div
          class="-w-full !h-[50px] -!bg-slate-500 shrink px-4 flex flex-row justify-between items-center space-x-3"
        >
          <ModalHeader :header="`${header}`" @close="onClose" />
        </div>

        <!-- Main -->
        <div
          class="h-[52%] -bg-slate-300 border-t border-[#E2E8F0] dark:border-[#2D2D2D] py-2 overflow-y-auto scroll-container"
        >
          <!-- <div class="w-full -h-[full] bg-black overflow-auto"> -->
          <textarea
            class="w-full h-full px-4 -bg-black text-appDarkGray dark:text-white focus:ring-0 border-none"
            v-model="text"
            style="font-size: 2rem"
          ></textarea>
          <!-- </div> -->
        </div>

        <!-- <div class="flex justify-end gap-3 h-[15%] px-4 items-center">
          <div
            @click="onClose"
            class="text-[#292929] font-[600] text-[12px] hover:bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] dark:text-[#F6F6F6] px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px]"
          >
            Close
          </div>
          <div
            @click="ok"
            class="text-[#FFFFFF] bg-appBlue font-[600] flex items-center space-x-2 text-[12px] hover:bg-blueHover dark:!border-[#2D2D2D] px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px]"
          >
            <span> Save </span>
          </div>
        </div> -->

        <!-- Footer -->

        <div
          class="-w-full !h-[50px] border-t border-[#E2E8F0] px-4 flex flex-row justify-between items-center space-x-3 shrink-0 !z-20"
        >
          <ModalFooter
            firstTitle="Close"
            secondTitle="Save"
            colorType="color"
            :isLoading="isLoading"
            @close="closeDownloadModal"
            @save="ok"
          />
        </div>
      </div>
    </BaseModal>
    <!-- <dialog-box :modalHeader="header" :open="open" :action="false">
    <div style="width: 25vw; padding: 0; margin: 0">
      <sui-form @submit.prevent="ok" style="width: 90%; padding: 0; margin: 0;">
        <textarea 
        :style="themeDarkMode ? {background:'#151515 !important',borderColor:'#505050',color:'#A2A2A2'}:{}"
        v-model="text" style="font-size: 2rem"></textarea>
      </sui-form>
    </div>
    <template v-slot:action >
      <sui-modal-actions :style="themeDarkMode ? {background:'#151515 !important',borderColor:'#505050',color:'#A2A2A2'}:{}">
        <sui-button @click.prevent.native="onClose" class="hover:!bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:!bg-[#FFFFFF1A] dark:hover:!bg-[#232323] dark:!text-[#F6F6F6]"
          >Cancel</sui-button
        >
        <sui-button :disabled="!valid" negative @click.prevent.native="ok" class="!bg-appBlue hover:!bg-blueHover">
          OK
        </sui-button>
     
      </sui-modal-actions>
    </template>
  </dialog-box> -->
  </div>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import BaseModal from '@/component/BaseModal.vue'
import ModalHeader from '@/component/ModalHeader.vue'
import ModalFooter from '@/component/ModalFooter.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TextSettings',
  components: {
    DialogBox,
    BaseModal,
    ModalHeader,
    ModalFooter,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'Enter Text',
    },
    initialText: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.text = this.initialText
  },
  data() {
    return {
      text: '',
      dark_Theme_Col: '',
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    ok() {
      this.$emit('ok', this.text)
    },
  },
  computed: {
    ...mapGetters([
      'themeDarkMode',
      'themePrimaryColor',
      'themeSecondaryColor',
    ]),
    valid() {
      return this.text.length > 0
    },
  },
  watch: {
    // themeDarkMode(val){
    //   if(val){
    //     this.dark_Theme_Col = '#151515'
    //   }
    //   else{
    //     this.dark_Theme_Col = this.themePrimaryColor.backgroundColor
    //   }
    // }
  },
}
</script>

<style>
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
/* .actions{
  background-color: v-bind(dark_Theme_Col) !important;
} */
</style>
