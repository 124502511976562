<template>
  <div class="w-full h-full flex items-center justify-between">
    <div class="w-8/12 -bg-[#000000]">
      <div class="flex items-center space-x-5 w-full">
        <div
          class="w-14 h-11 !bg-[#E2E8F0] flex items-center justify-center dark:!bg-[#676868] dark:text-[#F6F6F6] rounded-full"
        >
          {{ item?.symbol[0] || 'I' }}
        </div>
        <div class="flex flex-col w-full">
          <div
            @click="handleSymbolClick"
            class="text-[#000000] w-fit dark:!text-[#e9ecf8] cursor-pointer hover:underline text-[14px] font-sans font-bold"
          >
            {{ item?.symbol || 'AAPL' }}
          </div>
          <div
            :title="item?.companyName"
            class="text-[11px] font-sans leading-5 text-[#7c7c7c] dark:!text-[#a4a4a4] font-medium"
          >
            {{
              getTruncateText(item?.companyName) ||
              'International Business Machines Corporation'
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-4/12 items-end flex flex-col">
      <div class="text-[18px] font-sans font-medium dark:!text-[#34c244]">
        {{
          item?.close
            ? `$${item?.close}`
            : '$185.79'
        }}
      </div>
      <div class="text-[#CE1717] font-sans font-medium text-[12px]">
        {{
          item?.changepercent
            ? `${item?.changepercent}%`
            : '-1.11(-0.59%)'
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SymbolDetailComp',

  data() {
    return {
      screenWidth: window.innerWidth,
    }
  },

  props: {
    item: Object,
  },
  methods: {
    handleSymbolClick() {
      if (this.item) {
        this.$emit('handlePress', this.item)
      }
    },
    getTruncateText(val) {
      let maxLength = 15
      if (this.screenWidth > 500) {
        if (val?.length > maxLength && this.screenWidth < 1151) {
          return val?.substring(0, maxLength) + '..'
        } else {
          if (val?.length > 30) {
            return val?.substring(0, 30) + '..'
          } else {
            return val
          }
        }
      }
    },
  },
}
</script>
