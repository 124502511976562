<template>
    <div class="w-full space-y-10 ">
        <div class="w-full h-full -bg-slate-400 ">
            <div class="flex items-center space-x-2 space-y-6">
                <span class="text-[#454545] uppercase font-[700] text-[16px]">
                    <span :class="tag == 'Bullish' ? 'text-[rgb(65,144,28)]' : 'text-[#f20e1c]'">{{ tag }}</span>
                    {{headingName }}
                </span>
            </div>
            <div v-if="getShowDescription" class="text-[13px] font-[500] mb-3  text-[#5C5C5C]">
                {{ detail  }}
                <span class="font-[700]" >{{ highlighted }}</span>
            </div>
            <div class="w-auto h-auto px-3 py-2 border rounded-[6px] mt-1">
                <div class="w-full h-auto space-y-2">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSvgByName } from '../assets/leftsidebarsvgs/Utils/SvgUtils'
import {mapGetters} from 'vuex'

export default {
    name:'PatternWrapper',
    props:{
        data:Array,
        settingData:Object,
        headingName:{
            type: String,
            default: ''
        },
        name:{
            type: String,
            default: ''
        },
        highlighted:{
            type: String,
            default: ''
        },
        desc:{
            type: String,
            default: ''
        },
        tag:{
            type: String,
            default: ''
        },
        detail:{
            type: String,
            default: ''
        },
        backgroundTrue:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        getSvg(name) {
            return getSvgByName(name)
        },
        handleClick(val){
            this.$emit('onPress',val)
        },
    },
    computed:{
        ...mapGetters(['getShowDescription'])
    }
}

</script>