<template>
  <div id="chart" class="w-full h-full">
    <apexchart
      type="scatter"
      height="100%"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>


export default {
  name: 'ScatterChart',
  props:{
    series:Array
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: 'scatter',
          height: 350,
          toolbar: {
            show: false, // Disable header tools
          },
        },
        xaxis: {
          labels: {
            show: false, // Hide x-axis labels
          },
        },
        yaxis: {
          min: 0,
          max: 3,
          tickAmount: 4,
          opposite: true, // Position y-axis on the right side
          labels: {
            formatter: (value) => value.toFixed(2), // Format y-axis values
          },
          
          title: {
            text: undefined, // Remove "Earnings" label
          },
        },
        markers: {
          size: 8,
          colors: ['#110024'],
        },
        legend: {
          position: 'bottom',
          markers: {
            fillColors: ['#110024'],
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false, // Remove grid lines on x-axis
            },
          },
        },
      },
    }
  },
}
</script>

<style scoped>
#chart {
  max-width: 400px;
  margin: 35px auto;
}
</style>
