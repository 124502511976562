<template>
  <div class="flex flex-col w-ful h-full">
    <div class="flex flex-row gap-[8px]">
      <div
        @click="handleButton(button)"
        :class="
          selectedBtn === button?.name
            ? 'bg-[#001A2C] text-white'
            : 'bg-[#e2e2e2] text-[#3d3d3d]'
        "
        class="px-[24px] flex justify-between items-center py-[8px] gap-2 rounded-[22px] cursor-pointer shrink-0"
        v-for="(button, index) in btnList"
        :key="index"
      >
        <span class="xxs:w-auto shrink-0">{{ button?.name }}</span>
        <span
          class="flex items-center justify-center w-6 h-6 rounded-full text-sm"
          :class="
            selectedBtn === button.name
              ? 'bg-white text-gray-900'
              : 'bg-gray-800 text-white'
          "
        >
          {{ getTotal(button?.name) ?? 0 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scannerTabs } from '../utils/scanner'
import { chartEvents } from '../chartEvents'

export default {
  name: 'ButtonTabs',

  data() {
    return {
      // selectedButton: 0,
      btnList: [
        { name: 'Charting Patterns' },
        { name: 'Events' },
        { name: 'Filters' },
      ],
      selectedBtn: 'Charting Patterns',
    }
  },
  props: {
    buttonsProps: {
      type: Array,
      default: [],
    },
    id: {
      type: String,
      default: '',
    },
    // selectedButtonTypeName: String,
    scannerNameList: {
      type: Object,
      default: {},
    },
  },
  methods: {
    handleButton(button) {
      // const { name, index } = button
      // console.log('btnNames====>', btnName, index)
      this.selectedBtn = button.name
      this.$emit('selected-button', this.selectedBtn)
      // console.log('name===>', button, this.scannerNameList)
    },
    favPress() {
      this.handleButton(this.btnList[0])
    },
    getTotal(name) {
      
      if (name == 'Capitulation Scan') {
        return 1
      }
      if (name == 'Higher High, Higher Low') {
        return 1
      }
      if (name == 'Bollinger Squeeze') return 4
      if (name == 'Divergense Scan') return 2
      if (name == 'Big Candle') return 1
      if (name == 'Heiken Ashi') return 1
      if (name == 'Pocket Pivot') {
        const result = this.getPocketPivot ? 1 : 0
        return result
      }
      if (name == 'Measures') {
        let obj = this.getPatternSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (
              (typeof value === 'object' && key == 'dividendYield') ||
              key == 'earningYield' ||
              key == 'peRatio' ||
              key == 'frankedPct'
            ) {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Capitalization') {
        let obj = this.getPatternSettings(this.id).capitalisation
        console.log('objec==>', obj)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Returns') {
        let obj = this.getPatternSettings(this.id).returns
        console.log('objec==>', obj)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Darvas Box') {
        let obj = this.getPatternSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 4)

        const trueCount = countTrueValues(obj)
        return trueCount + (this.getPercentageRiskBufferToggle ? 1 : 0)
      }
      if (name == 'Charting Patterns') {
        let obj = this.getPatternSettings(this.id)
        console.log('activity object====>', obj)
        let len = Object.keys(obj).filter((key) => obj[key] == true)?.length
        
        return len
      }
      if (name == 'Candlestick') {
        let obj = this.getPatternSettings(this.id)
        let len = Object.keys(obj).filter((key) => obj[key] == true)?.length
        return len
      }
      if (name == 'Activity') {
        let obj = this.getPatternSettings(this.id)
        console.log('activity object====>', obj)
        return 1
      }
      if (name == 'Technical Indicators') {
        let obj = this.getPatternSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Events') {
        let obj = this.getEventsSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Filters') {
        let obj = this.getFilterSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
    },
  },

  computed: {
    ...mapGetters([
      'getPatternSettings',
      'getEventsSettings',
      'getFilterSettings',
      'getPocketPivot',
      'getPercentageRiskBufferToggle',
    ]),
  },

  watch: {
    scannerNameList(val) {
      this.btnList = scannerTabs[val?.value]
      console.log('btn==>', this.btnList)
      this.selectedBtn = this.btnList[0]?.name
    },
  },

  mounted() {
    chartEvents.$on('favClick', this.favPress)
  },
  beforeDestroy() {
    chartEvents.$off('favClick')
  },
}
</script>

<style></style>
