<template>
    <div @click="handleClick" class="p-1 cursor-pointer hover:bg-appBtnHover dark:hover:bg-[#2D2D2D] rounded-[4px]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BackgroundHover',
   
    methods: {
        handleClick(){
            this.$emit('press')
        }
    }
}
</script>