<template>
  <div class="w-full h-full pb-0 -bg-red-400">
    <div
      v-if="selectedScanResults?.length > 0"
      class="w-full h-full -bg-black"
    >
      <span
        v-if="
          // scanResultsCompValue[scanResultsCompValue?.length - 1]?.results
          selectedScanResults?.length > 0
        "
      >
      <!-- v-for="(item, index) in getScanResult[getScanResult.length - 1].data.results" -->
        <div
          v-for="(item, index) in selectedScanResults"
          :key="index"
          class="w-full h-[4rem] flex border-b items-center justify-between cursor-default hover:bg-appBtnHover -bg-slate-400 xxs:px-2 xs:px-3"
        >
          <!-- Icon Brand Name -->
          <div class="w-[30%] flex justify-start items-center gap-2">
            <!-- Brand Icon -->
            <div
              class="min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] rounded-full text-white text-[18px] bg-[#6B7280] flex justify-center items-center"
            >
              <TickerIcon :ticker="item?.symbol" />
            </div>
            <!-- Brand Name -->
            <div
              class="xxs:min-w-[3.5rem] xs:min-w-[4.5rem] -bg-slate-400 h-full flex flex-col -!bg-black"
            >
              <span
                @click="handleSymbolPress(item)"
                class="xxs:text-[11px] cursor-pointer hover:underline xs:text-[14px]"
                >{{ item?.symbol || "N/A" }} </span 
              >
              <span class="xxs:text-[10px] xs:text-[12px]">Tesla Inc</span>
            </div>
          </div>

          <!-- Candles -->
          <div
            class="-w-[25%] w-auto h-[50%] xxs:px-3 xs:px-4 py-2 rounded-xl bg-[#dbd6d6] flex justify-center items-center"
          >
            {{ item?.patternName || 'Not Found' }}
          </div>

          <!-- Price -->
          <div class="flex flex-col items-end justify-center">
            <span
              class="text-[#131722] xxs:!text-[11px] qm:text-[14px] font-[400]"
              >{{ item?.candleData?.close || "Not Found" }}
            </span>
            <span
              class="text-[green] xxs:!text-[11px] qm:text-[14px] font-[400]"
            >
              +9.66 (4.59%)
            </span>
          </div>
        </div>
      </span>
      <span v-else>
        <div
          class="flex h-[20rem] w-full justify-center items-center text-center"
        >
          <NoData title="No result found." />
        </div>
      </span>
    </div>
    <!-- @click="handleRun" -->
    <div
      v-else
      class="flex -h-[20rem] w-full justify-center mt-32 -bg-red-500 items-center text-center"
    >
      <div>
        <span
          @click="handleRun('scanner')"
          class="hover:underline cursor-pointer text-[14px] text-appBlue font-medium hover:text-darkBlue pl-2"
          >Click here to start scanning.</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { chartEvents } from '../../../chartEvents'
import TickerIcon from '../../TickerIcon.vue'
import NoData from '../../NoData.vue'
import ScannerResult from './ScannerResult.vue'
export default {
  name: 'App',
  data() {
    return {
      showBtn: false,
      selectedScan: 'none',
      selectedSymbol: {
        patternName: null,
        symbol: '',
      },
      selectedScan: 'none',
      selectedScanResultIndex: 0,
      lastScannerResult: null,
    }
  },
  components: {
    TickerIcon,
    NoData,
  },
  props: {
    selectedRight: String,
    scannerResultArray: {
      type: Array,
      default: [],
    },
  },
  methods: {
    ...mapActions(['changeSymbolToScannedSymbol']),

    handleSymbolPress(sym) {
      console.log("symbol=======>",sym);
      this.selectedSymbol = sym
      chartEvents.$emit('setChartRange', undefined, 'ScannedSymbolSelect')
      chartEvents.$emit('load-symbol', sym?.symbol)
      chartEvents.$emit("renderChartFirstScanResult",sym?.symbol)
    },

    handleRun() {
      chartEvents.$emit('handleRun')
      console.log('handleRun scanner result')
    },

    showSymbolScanDetails(symbolName) {
      //  console.log("symbolName====>",symbolName,this.getScans()[0]);
      const { timeFrame, isDrawing, scanType, settings } = this.getScans()[0]
      console.log("showSymbolScanDetails==>",symbolName,timeFrame,isDrawing,scanType,settings)
      this.changeSymbolToScannedSymbol({
        symbolData: symbolName,
        timeFrame,
        isDrawing,
        scanType,
        settings,
      }).then((d) => {})
    },
    getScans() {
      return this.scanResults.filter(
        (scan) =>
          `${scan.scanType} - ${scan.timeFrame} - ${scan.date}` ===
          this.selectedScan
      )
    },
    AssignSymbol(sym,index=0,setIndex=false){
      console.log("assignSymbol==>");
    },
  },
  computed: {
    ...mapGetters(['scanResults','getScanResult']),
    scanResultsCompValue() {
      return this.scanResults
    },
    scannerSymbols() {
      return this.getScannerResult
    },
    selectedScanResults() {
      if (this.selectedScan === 'none' || this.selectedScan === '') return []

      return this.getScans()[0].results
    },
  },
  mounted() {
    console.log('scannerSymbols', this.scanResults)
    console.log("getScanResult",this.getScanResult);
    // chartEvents.$on('GET_SOCKET_SCANNER_REUSLT',this.updateScannerResult)
  },
  watch: {
    scanResults(newVal) {
      console.log("newValue==>",newVal);
      if(newVal.length === 0) return
      const latest = newVal[newVal.length - 1];
      console.log("latest====>",latest);
      this.lastScannerResult = [newVal[newVal.length - 1]]
      this.selectedScan = `${latest?.scanType} - ${latest?.timeFrame} - ${latest?.date}`

      if(latest.results.length - 1){
        this.selectedScanResultIndex = 0;
        this.selectedSymbol = {...latest.results[0]}
        // this.AssignSymbol(latest.results[0])

        console.log("results===>",this.selectedScanResultIndex,this.selectedSymbol);
      }
    },
    selectedSymbol(symbolName){ 
      if(!symbolName) return
      chartEvents.$emit("renderChartFirstScanResult",symbolName?.symbol)
      console.log("symbolName==>",symbolName); 
      console.log("maaal hain majood!");
      this.showSymbolScanDetails(symbolName)
    },
    getScanResult(val){
      console.log("getScanResult=====>",this.getScanResult[this.getScanResult.length - 1].data.results);
    },
    lastScannerResult(val){
      console.log("lastScannerResult",val);
    }
  },
  
}
</script>
<style scoped></style>
