<template>
  <div
    class="w-full !h-full flex justify-end gap-3 dark:border-[#2D2D2D] !items-center"
  >
    <span v-if="!hideClose">
      <ButtonComp :title="firstTitle" @press="closeModal" />
    </span>
  </div>
</template>

<script>
import ButtonComp from '../ButtonComp.vue'

export default {
  name: 'DropDownFooter',
  props: [
    'firstTitle',
    'secondTitle',
    'isLoading',
    'colorType',
    'hideSave',
    'hideClose',
  ],
  components: {
    ButtonComp,
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleSave() {
      this.$emit('save')
    },
  },
}
</script>
