<template>
  <!-- <dialog-box
    modalHeader="Trailing Stop"
    :open="isOpen"
    @onClose="onClose"
    @ok="updateData"
  > -->
  <!-- themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:themeFontColor -->
  <!-- themeDarkMode ? {color:'#A2A2A2'}:themeFontColor -->
  <BaseModal :open="isOpen"> 
  <div class="xxs:w-[100vw] xxs:!h-[100svh] sm:w-[53rem] sm:!h-[40vh] bg-white dark:bg-[#151515] border dark:border-[#2D2D2D] rounded-xl flex flex-col justify-between">
    <div class="xxs:h-[8%] border-b sm:h-[15%] flex items-center px-4 justify-between">
          <span class="text-[18px] dark:text-white font-sans font-[500]">Trailing Stop</span>
            <div
             @click="onClose"
              class="cursor-pointer p-[8px] rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
            >
          <span>
            <svg
              class="dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="18"
              height="18"
            >
              <path
                stroke="currentColor"
                stroke-width="1.2"
                d="m1.5 1.5 15 15m0-15-15 15"
              ></path>
            </svg>
          </span>
        </div>
    </div>
      <div class="w-full xxs:h-[84%] sm:h-[70%] dark:border-[#2D2D2D] pt-3 px-4">
        <sui-form style="font-size: 14px;">
          <label class="dark:!text-[#A2A2A2] xxs:text-[16px] xxs:pb-2 sm:text-[14px]">Entry price</label>
        <sui-form-field>
          <sui-form-field>
            <input
            class="dark:!bg-[#505050] dark:!border-[#505050] xxs:!text-[18px] sm:!text-[16px] dark:!text-[#A2A2A2] xxs:!py-4 xxs:!px-3 sm:!p-2"
              type="number"
              placeholder="Entry Price"
              step="0.01"
              min="0"
              v-model="entryPrice"
            />
          </sui-form-field>
        </sui-form-field>

        <sui-form-fields>
          <sui-form-field width="eight">
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[14px] pb-2">Trailing Type</label>
            <sui-dropdown
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] sm:!text-[16px] xxs:!py-4 xxs:!px-3 sm:!p-02"
              selection
              :options="trailingType"
              v-model="selectedTrailingType"
            />
          </sui-form-field>

          <sui-form-field
            v-if="selectedTrailingType === 'step-price'"
            width="three"
            class="xxs:!mt-3 sm:!mt-0"
          >
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] xxs:pb-2 sm:!text-[14px]">Step Price</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[16px] xxs:!py-5 xxs:!px-3 sm:!p-2"
              
              name="stepPrice"
        
              placeholder="Step Price"
              min="0"
              step="0.01"
              v-model="stepPrice"
            />
          </sui-form-field>

          <sui-form-field width="three" v-if="selectedTrailingType === 'value'" class="xxs:!mt-3 sm:!mt-0">
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[14px] shrink-0">Position Size</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] sm:!text-[16px] xxs:!py-4 xxs:!px-3 sm:!p-2"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === 'value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor" class=" xxs:!text-[16px] sm:!text-[14px] xxs:ml-2 sm:ml-0">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'value'">
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[14px]">Risk Value</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] sm:!text-[16px] xxs:!py-4 xxs:!px-3 sm:!p-2"
              type="number"
              name="riskedValue"
              placeholder="Risk Value"
              value="500"
            />
          </sui-form-field>

          <!-- % -->
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value' "
            class="xxs:!mt-3 sm:!mt-0 mb-1"
          >
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[14px]">Position Size</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] sm:!text-[16px] xxs:!py-4 xxs:!px-3 sm:!p-2"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === '%value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor" class="xxs:!text-[16px] sm:!text-[14px] xxs:ml-2 sm:ml-0">and</h3>
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value'"
          >
            <label  class="dark:!text-[#A2A2A2] xxs:pb-2 sm:!text-[14px]">Risk %</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] !mt-[-7px] sm:!text-[16px] xxs:!py-4 xxs:!px-3 sm:!p-2"
              type="number"
              name="riskedValue"
              placeholder="Risk Value %"
              value="10"
            />
          </sui-form-field>

          <!-- atr -->
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'" class='xxs:!mt-3 sm:!mt-0'>
            <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] xxs:pb-2 sm:!text-[14px]">Day Period</label>
            <input
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[18px] sm:!text-[16px] xxs:!py-5 xxs:!px-3 sm:!p-2"
              type="number"
              name="atrPeriod"
              placeholder="ATR Period"
              value="10"
            />
          </sui-form-field>

          <h3 v-if="selectedTrailingType === 'atr'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor" class="xxs:ml-2 xxs:!text-[16px] xxs:pb-2 sm:!text-[14px]">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'">
            <label  class="dark:!text-[#A2A2A2] xxs:!text-[16px] xxs:pb-2 sm:!text-[14px]">Multiplier</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[16px] xxs:!py-5 xxs:!px-3 sm:!p-2"
              type="number"
              name="multiplier"
              placeholder="Multiplier"
              value="5"
            />
          </sui-form-field>
        </sui-form-fields>

        <sui-form-field
          v-if="
            selectedTrailingType === 'atr' ||
            selectedTrailingType === 'step-price'
          "
          class="xxs:!mt-3 sm:!mt-0"
        >
          <label class="dark:!text-[#A2A2A2] xxs:!text-[16px] xxs:pb-2 sm:!text-[14px]">Stop Price</label>
          <sui-form-field>
            <input
            class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] xxs:!text-[16px] sm:!text-[16px] xxs:!py-5 xxs:!px-3 sm:!p-2"
              type="number"
              placeholder="Stop Price"
              step="0.01"
        
              min="0"
              v-model="stopPrice"
            />
          </sui-form-field>
        </sui-form-field>
        </sui-form>
      </div>

      <div class="flex justify-end border-t gap-3 xxs:h-[8%] -bg-orange-500 sm:h-[15%]  px-4 items-center pt-2">
        <div @click="onClose" class="text-[#292929] font-[600] text-[12px] hover:bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] dark:text-[#F6F6F6] xxs:px-10 sm:px-6 cursor-pointer xxs:py-4 sm:py-2 border-[#BCBCBC] border rounded-[6px]">
          Close
        </div>
        <div @click="updateData" class="text-[#FFFFFF] bg-appBlue  font-[600] flex items-center space-x-2 text-[12px] hover:bg-blueHover dark:!border-[#2D2D2D]  xxs:px-10 sm:px-6 cursor-pointer xxs:py-4 sm:py-2 border-[#BCBCBC] border rounded-[6px]">
        <span>
          Save
        </span>       
        </div>
      </div>

    </div>
  </BaseModal>
  <!-- </dialog-box> -->
</template>

<script>
import DialogBox from "@/baseComponents/DialogBox.vue";
import { mapActions, mapGetters } from "vuex";
import {chartEvents} from "../../chartEvents";
import BaseModal from "@/component/BaseModal.vue";

export default {
  name: "TrailingStop",
  components: {
    DialogBox,
    BaseModal
  },
  props: {
    trialingStopData:{
      type:Object,
      default: () => {}
    }
  },
  methods: {
    onClose() {
      this.$emit("hideTrailingStop");
    },
    updateData() {
      let identity = "UpdateTrailingStopSettings"+this.trialingStopData?.$uuid;
      // let identity = "UpdateTrailingStopSettings";
      chartEvents.$emit(identity,{
        stepPrice:this.stepPrice,
        stopPrice:this.stopPrice,
        entryPrice:this.entryPrice,
        uuid:this.trialingStopData?.$uuid,
      });
      this.$emit("hideTrailingStop");
      
    },
  },
  data() {
    return {
      isOpen:false,
      entryPrice:0,
      stopPrice:0,
      stepPrice:0,
      trailingType: [
        { text: "based on step price", value: "step-price" },
        { text: "$ value of a parcel", value: "value" },
        { text: "% value of a parcel", value: "%value" },
        { text: "ATR", value: "atr" },
      ],
      selectedTrailingType: "step-price",
    };
  },
  computed: {
    ...mapGetters([ "themeDarkMode", "themeFontColor", "themeSecondaryColor", "themePrimaryColor"]),
  },
  created() {
    console.log("TrailingStopTool",this.$props.trialingStopData)
    if(this.$props?.trialingStopData){
      this.stepPrice = this.$props?.trialingStopData?.stepPrice
      this.entryPrice = this.$props?.trialingStopData?.entryPrice
      this.stopPrice = this.$props?.trialingStopData?.stopPrice
    }
  },
  mounted() {
    if(this.$props?.trialingStopData){
      this.isOpen = true;
    }
  }
};
</script>

<style>
@media screen and (max-width: 600px){


.ui.selection.dropdown .menu{
  min-height: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
}

.ui.selection.dropdown .menu .item{
  font-size: 16px;
  display: flex;
  border-bottom: 1px solid #D1D1D1;
  padding: 0px;
  align-items: center;
  height: 50px !important;
  /* background-color: aqua; */
}
}
</style>
