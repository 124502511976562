var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-[600] text-[12px] min-w-[120px] w-[120px] h-[36px] cursor-pointer flex items-center justify-center space-x-2 -py-2 border-[#BCBCBC] border rounded-[6px] !shrink-0",class:[
    _vm.colorType == 'color' 
      ? 'text-appBtnActiveTxt bg-appBtnActive hover:bg-BlackBtnHover dark:!border-[#2D2D2D]'
      : _vm.colorType == 'green'
      ? 'text-[#FFFFFF] bg-[#00c92e] hover:bg-[#0caf2f] dark:!border-[#2D2D2D]'
      : ' hover:bg-appBtnHover dark:!border-[#2D2D2D] dark:bg-[rgba(255,255,255,0.1)] dark:hover:bg-[#232323] dark:text-[#F6F6F6] text-[#292929]',
      _vm.colorType == 'isValidScanner'  ? 'pointer-events-none opacity-50' :  '',
      _vm.addIcon == 'addicon' ? 'h-[46px]' : ''
  ],on:{"click":_vm.handlePress}},[(_vm.title === 'Add')?_c('span',[_c('img',{attrs:{"src":_vm.addIcon,"alt":"Add Icon"}})]):_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isLoading)?_c('span',[_c('Loader',{attrs:{"customClass":"w-4 h-4"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }