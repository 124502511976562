<template>
  <div style="" v-if="!isLoading"> 
    <div style="height: 10%; " class="sm:p-[20px]">
      <Header></Header>
    </div>
    <div
      style="
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        justify-content: end;
      "
    >
      <!-- <sui-form @submit.prevent="clickLogout">
        <sui-button class="ui secondary button" fluid type="submit"
          >Go to signin</sui-button
        >
      </sui-form> -->
    </div>
    <div style="" class="login-container gap-y-6">
   

        <div style="justify-content: center" class="">
          <h1>Please select a subscription</h1>
        </div>

        <div style="margin-top: 20px; display: flex">
          <div
            @click="cardClicked('M')"
            :style="
              selectedCardClass == 'monthly'
                ? 'border:2px solid #27292A;  margin-right: 30px; padding-right:15px; padding-left:15px'
                : 'background:white; margin-right: 30px; padding-right:15px; padding-left:15px'
            "
            class="card"
          >
            <div class="container">
              <h3><b>Monthly</b></h3>
              <p>USD 30.00 per month</p>
            </div>
          </div>
          <div
            @click="cardClicked('Y')"
            class="card"
            :style="
              selectedCardClass == 'yearly'
                ? 'border:2px solid #27292A; padding-right:15px; padding-left:15px'
                : 'background:white; padding-right:15px; padding-left:15px'
            "
          >
            <div class="container">
              <h3><b>Yearly</b></h3>
              <p>USD 25.00 per month</p>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px; width: 300px">
          <sui-form @submit.prevent="onSubmit">
            <div class="ui red" v-if="showError" style="color: red">
              {{ msg }}
            </div>
            <sui-button fluid class="ui secondary button" type="submit">Subscribe</sui-button>
            <div class="w-full text-right mt-1">
           
              <span @click="handleBackToSignIn" class="w-fit cursor-pointer text-black hover:underline">
                Back to signin
              </span>
            </div>
          </sui-form>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isClientAccountActive, subscribeToPackage,getUserData } from '../services/user'
import Header from '../component/Header.vue';
import moment from 'moment'

export default {
  data() {
    return {
      isLoading: true,
      selectedCard: '',
      selectedCardClass: '',
      msg: '',
      showError: false,
    }
  },
  computed: {
    ...mapGetters(['']),
  },
  components: {
    Header
  },
  methods: {
    handleBackToSignIn(){
      localStorage.clear()
      this.$router.push('/signin')
    },
    async checkSubscriptionStatus() {
      try {
        this.isLoading = true
        const response = await isClientAccountActive()
        if (response?.status === 'active'){
          localStorage.setItem('auth',true)
          this.$router.push('/')
        }
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
       
      }
      this.isLoading = false
    },
    clickLogout() {
      localStorage.removeItem('state')
      localStorage.removeItem('stripeSession')
      // localStorage.setItem('auth', true)
      this.$router.push('/signin')
    },
    cardClicked(value) {
      if (value == 'M') {
        this.selectedCard = 'price_1QI7ClDHu1BvqcVrZWkrLecU'
        this.selectedCardClass = 'monthly'
      } else {
        this.selectedCard = 'price_1QI7D4DHu1BvqcVrGOwRK4aG'
        this.selectedCardClass = 'yearly'
      }
    },
    async onSubmit() {
      if (this.selectedCard && this.selectedCardClass) {
        const url = await subscribeToPackage(this.selectedCardClass)
        console.log('url', url)
        if (url && url.error) {
          this.showError = true
          this.msg = url.message
          setTimeout(() => {
            this.showError = false
          }, 5000)
        } else {
          window.location = url.data
        }
      } else {
        this.showError = true
        this.msg = 'Please Select Product'
        setTimeout(() => {
          this.showError = false
        }, 3000)
      }
    },
    checkTrialStatus(val) {
      const isoDate = new Date(val).toISOString();
      const startDate = moment(isoDate);
      const expirationDate = startDate.add(90, 'days');
      // const expirationDate = startDate.add(2, 'minutes');
      if (moment().isBefore(expirationDate)) {
        return "valid";
      } else {
        return "expired";
      }
    },
  },
  async mounted() {
    let token = localStorage.getItem('state')
    if(token){
      const data = await getUserData()
      if(data && data?.trialStartDate){
        const trialStatus = this.checkTrialStatus(data?.trialStartDate)
        if(trialStatus == 'valid'){
          this.$router.push('/')
        }else{
          this.checkSubscriptionStatus()
        }
      }else{
        this.$router.push('/signin')
      }
    }else{
      this.$router.push('/signin')
    }
    this.isLoading = false;
  },
}
</script>

<style>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 180px; */
  padding: 40px;
  cursor: pointer;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  /* padding: 2px 16px; */
  justify-content: center;
  cursor: pointer;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
}
@media screen and (max-width: 1200px) {
  .login-container {
    margin-left: 20%;
    width: 60%;
    padding-top: 5%;
  }
}
@media screen and (max-width: 700px) {
  .login-container {
    margin-left: 10%;
    width: 80%;
    padding-top: 5%;
  }
}
/*
@media screen and (max-width: 800px) {
  .login-container{
  margin-left:2%;
  margin-right: 2%;
  width:100%;
  padding-top: 5%;
}
} */

.n-input {
  padding: 0.8rem !important;
}
.navigate-notice {
  margin-top: 0.5rem;
}
.product-error-msg {
}
</style>
