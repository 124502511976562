<template>
  <div
    class="w-full -h-[95vh] -mt-[0.2rem] relative flex flex-col items-center"
  >
    <div
      v-if="openCursorDropdown"
      class="w-[150px] h-auto bg-white shadow-lg xxs:ml-[0.5rem] md:ml-0 xxs:!mt-[-6rem] md:!top-[10rem] fixed -absolute left-20"
      style="z-index: 9999 !important"
    >
      <div
        v-for="(item, i) in cursorData"
        :key="i"
        @click="handleCursor(item)"
        :class="
          item.icon == getChartCursor?.icon
            ? '!bg-btnActiveLite !text-BtnActiveTxtLite cursor-default'
            : ' cursor-pointer hover:bg-appBtnHover'
        "
        class="flex w-full items-center space-x-2  xxs:px-4 xxs412:px-6 xs:px-2 py-2 -border text-[#323232] dark:!text-[#6C707A] -rounded-md"
      >
        <div>
          <div
            v-html="getSvg(item?.icon, item.icon == getChartCursor?.icon)"
          ></div>
        </div>
        <div>
          {{ item.name }}
        </div>
      </div>
    </div>

    <div
    v-if="openEmoji"
     class="top-[30rem] fixed -absolute xxs:left-20 sm:left-0 sm:mb-0 md:left-20 xxs:!mt-[-2.5rem] sm:!mt-[0rem] md:!mt-[-20rem]"
      style="z-index: 9999 !important"
    >
      <VuemojiPicker @emojiClick="handleEmojiClick" :isDark="false" />
    </div>
    <div class="w-full flex justify-center -px-3">
      <div
        :class="width > 880 ? '!flex-col flex' : 'flex flex-row'"
        class="md:!min-w-[70%] xxs:w-full h-auto flex py-2 xxs:flex-grow justify-around items-center --overflow-x-auto --scroll-container"
      >
       <!-- :title="getChartCursor?.name" -->
        <div
          ref="cursorRef"
          :class="
            !getCurrentDrawing
              ? 'bg-btnActiveLite'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
          "
           v-tooltip.right="{ content: 'Change cursor', class: 'tooltip-custom' }"
          @click="openCursorDropdown = !openCursorDropdown"
          class="toolTipCon w-[4rem] h-[3.3rem] md:w-[43.2px] md:h-[43.2px] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
        >
          <div
            :class="
              !getCurrentDrawing
                ? 'bg-btnActiveLite'
                : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
            "
          >
            <div
              v-html="getSvg(getChartCursor?.icon, !getCurrentDrawing)"
            ></div>
          <!-- <span class="toolTipText">{{ getChartCursor?.name }}</span> -->

          </div>
        </div>

        <div
          v-for="(item, i) in toolButtons"
          :key="i"
           v-tooltip.right="item.toolTip"
          @click="item.onClick"
          :class="
            item.key == getCurrentDrawing
              ? 'bg-btnActiveLite'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
          "
          class="toolTipCon w-[4rem] h-[3.3rem] md:w-[43.2px] md:h-[43.2px] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
        >
          <div>
            <div
              v-html="getSvg(item?.img, item.key == getCurrentDrawing)"
            ></div>
          </div>
          <!-- <span class="toolTipText">{{ item?.toolTip }}</span> -->
        </div>
        <!-- title="Delete all drawings" -->

        <div
          @click="toggleDeleteDrawingModal = true"
          v-tooltip.right="'Delete all drawings'"
          class="toolTipCon w-[4rem] h-[3.3rem] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover justify-center"
        >
          <div>
            <div  v-html="getSvg('delete')"></div>
            
          </div>
          <!-- <span class="toolTipText">Delete all drowings</span> -->
        </div>
      </div>
    </div>

    <BaseModal v-if="toggleDeleteDrawingModal">
     <RemoveAlertModal header="Remove All Drawings" message="Are you sure you want to remove all the drawings?" @closeModal="handleCloseDeleteModal" @remove="handleDelete" />
    </BaseModal>

    <trailing-stop
      v-if="showTrailingStop"
      :trialingStopData="trialingStopData"
      @hideTrailingStop="trailingStopDialogOnClose"
    />
  </div>
</template>
<script>
import { getSvgByName } from '../assets/leftsidebarsvgs/Utils/SvgUtils'
import { chartEvents } from '../chartEvents'
import TrailingStop from './dialogBoxes/TrailingStop.vue'
import { mapGetters,mapActions } from 'vuex'
import ChartAdding from './ChartAdding'
import store from '@/store'
import BaseModal from './BaseModal.vue'
import Overlays from '../overlays'
import RemoveAlertModal from './RemoveAlertModal.vue'
import { VuemojiPicker } from 'vuemoji-picker'
import { debounce } from '../utils/helper'
import Vue from 'vue'
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import {
  updateDrawingDataV2,
} from '@/services/drawing'

Vue.use(Tooltip);

export default {
  name: 'LeftBar',
  mixins: [ChartAdding],
  data() {
    return {
      showTrailingStop: false,
      trialingStopData: {},
      toggleDeleteDrawingModal:false,
      selectedDrawing: null,
      width: window.innerWidth,
      cursorData: [
        { name: 'Cross', icon: 'crosshair' },
        { name: 'Arrow', icon: 'default' },
        // {name:'Dot',icon:'dot'},
      ],
      cursorType: 'crosshair',
      openCursorDropdown: false,
      toolButtons: [
        {
          img: 'ruler',
          onClick: () => this.selectTool('RangeTool:PriceTime'),
          toolTip: 'Price Measurement Tool',
          toolTipMobile: 'Measure Tool',
          key: 'RangeTool:PriceTime',
          style: 'background-color : red',
        },
        {
          img: 'trendLine',
          onClick: () => this.selectTool('TrendLine:Trend'),
          toolTip: 'Line Tool',
          toolTipMobile: 'Line Tool',
          key: 'TrendLine:Trend',
          style: 'background-color : blue',
        },

        {
          img: 'horizontalLine',
          onClick: () => this.selectTool('HLineTool:Segment'),
          toolTip: 'Horizontal Line Tool',
          toolTipMobile: 'Horizontal Tool',
          key: 'HLineTool:Segment',
          style: 'background-color : green',
        },
        {
          img: 'arc',
          onClick: () => this.selectTool('ArcTool:Arc'),
          toolTip: 'Arc Tool',
          toolTipMobile: 'Arc Tool',
          key: 'ArcTool:Arc',
          style: 'background-color : blue',
        },
        {
          img: 'T',
          onClick: () => this.selectTool('TextTool:Label'),
          isText: true,
          toolTip: 'Text Tool',
          key: 'TextTool:Label',
          toolTipMobile: 'Text Tool',
        },
        {
          img: 'emoji',
          onClick: () => this.handleOpenEmoji(),
          isText: true,
          toolTip: 'Emoji Tool',
          key: 'EmojiTool:Label',
          toolTipMobile: 'Emoji Tool',
        },
        {
          img: 'rectangle',
          onClick: () => this.selectTool('RectangleTool:Shape'),
          toolTip: 'Box Tool',
          key: 'RectangleTool:Shape',
          toolTipMobile: 'Box Tool',
        },
        {
          img: 'parallelLine',
          onClick: () => this.selectTool('ChannelTool:Segment'),
          toolTip: 'Parellel Lines Tool',
          key: 'ChannelTool:Segment',
          toolTipMobile: 'Parellel Tool',
        },
        {
          img: 'fibRetracement',
          onClick: () => this.selectTool('FibonacciRetrace:Segment'),
          toolTip: 'Fibonacci Retracement Tool',
          toolTipMobile: 'Fibonacci Tool',
          key: 'FibonacciRetrace:Segment',
        },
        {
          img: 'tradePlanner',
          onClick: () => this.selectTool('TradeVisualizerTool:PL'),
          toolTip: 'Trade Visualizer',
          key: 'TradeVisualizerTool:PL',
          toolTipMobile: 'Trade Visualizer',
        },

        {
          img: 'tradeStop',
          onClick: () => this.selectTool('TrailingStopTool:PL'),
          toolTip: 'Trailing Stop',
          toolTipMobile: 'Trailing Stop',
          key: 'TrailingStopTool:PL',
        },
      ],

      isActive: false,
      activeBtn: null,
      openEmoji:false,
      selectedEmoji: null,
    }
  },
  components: { TrailingStop,BaseModal,RemoveAlertModal,VuemojiPicker },
  methods: {
    ...mapActions(['setEmojiIcon']),

    updateScreenWidth() {
      this.width = window.innerWidth
    },
    trailingStopDialogOnClose() {
      this.showTrailingStop = false
    },
    handleCloseDeleteModal(){
      this.toggleDeleteDrawingModal = false
    },
    selectTool(toolID) {
      console.log('toolID', toolID)
      store.commit('SET_CURRENT_DRAWING', toolID)
      chartEvents.$emit('generateCustomEvent', 'tool-selected', [toolID])
    },
    handleOpenEmoji(){
      this.openEmoji = !this.openEmoji
     
    },
    handleEmojiClick(detail){
      this.openEmoji = false
      localStorage.setItem('emoji', detail?.emoji?.unicode)
      // let emoji = {text:detail?.emoji?.unicode}
      this.selectedEmoji = {text:detail?.emoji?.unicode}
      this.selectTool('EmojiTool:Label')  


      // setTimeout(()=>{
      //   this.changeSettings(emoji)
      // },3000)
    },

    changeSettings(newSettings){
      this.dc.merge(this.dc.data.selected + '.settings', newSettings)
      this.$nextTick( debounce( () => {
        const xx = this.dc.get_one(this.dc.data.selected)
        console.log("xx==>",xx)
        updateDrawingDataV2({ data:{
            data:JSON.stringify(xx),
            modeId:xx.settings?.$uuid
            // modeId:xx.settings.modeId
          }, userId: this.getUserId, symbolName: this.symbolName, })

        // console.log("xx",xx)
    console.log("tool setting",newSettings,xx.settings.settingsList)

        if (xx.settings.settingsList) {
          let data = {}
          xx.settings.settingsList.forEach((sett) => {
            data[sett] = newSettings[sett] || xx.settings[sett]
          })
          let overlay_tv = Object.values(Overlays)
          let currentOverlay = overlay_tv.find(d => d.name === xx.type)
          if(currentOverlay){
            // console.log("local storage",currentOverlay.methods.tool)
            if(currentOverlay.methods.tool){
              let toolSettings = currentOverlay.methods.tool()
              let toolId=`${xx.type}:${toolSettings.type}`
              let singleTool  = this.dc.data.tools.find(d => d.type === toolId)
              if(singleTool){
                singleTool.settings = {...singleTool.settings,...newSettings}
              }
              // console.log("local storage toolSettings",toolSettings)
            }
          }
          if (data) {
            localStorage.setItem(xx.type, JSON.stringify(data))
          }
        }

      }))
      this.selectedEmoji = null
    },

 
    handlePlus() {
      this.openCursorDropdown = true
    },
    handleCursor(val) {
      this.openCursorDropdown = false
      store.commit('SET_CHART_CURSOR', val)
    },
    handleDelete() {
      this.handleCloseDeleteModal()
      chartEvents.$emit('onDeleteButtonClick')
    },
    toggleActive(active) {
      this.activeBtn = active
      this.isActive = !this.isActive
    },
    getSvg(name, isTrue = false) {
      return getSvgByName(name, isTrue)
    },
    handleClickOutside(event) {
      if (!this.$refs.cursorRef.contains(event.target)) {
        this.openCursorDropdown = false
      }
    },
  },
  computed: {
    ...mapGetters(['getCurrentDrawing','dc','symbolName','getUserId', 'getChartCursor','getEmojiIcon']),
  },
  mounted() {
    const drawingModeOff = () => {
      store.commit('SET_CURRENT_DRAWING', '')
    }

    window.addEventListener('resize', this.updateScreenWidth)

    chartEvents.$on('emoji-selected',()=>{
      if(this.selectedEmoji){
        this.changeSettings(this.selectedEmoji)
      }
    })

    chartEvents.$on('updateTrailingStopModal', (params) => {
      console.log('TrailingStopTool', params)
      this.trialingStopData = params
      this.showTrailingStop = true
    })
    chartEvents.$on('drawingOff', drawingModeOff)
    chartEvents.$emit('bindComponentEvent', 'remove-tool', this.onToolRemoved)

    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    chartEvents.$off('drawingOff')
    chartEvents.$off('emoji-selected')
    window.addEventListener('resize')

  },
  watch: {
    // getEmojiIcon(val){
    //   setTimeout(()=>{
    //     this.changeSettings({text:val})
    //   },2000)
    // }
  },
}
</script>
<style scoped>

.tooltip-custom {
  background-color: #fff; /* Dark background */
  color: #000; /* Light text */
  animation-duration: 1.5s;
  animation-delay: 1s;
  font-size: 0.875rem; /* Adjust font size */
  padding: 8px 12px; /* Inner padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Drop shadow */
  border: 1px solid #666; /* Border color */
  right: -100px;
  position: absolute;
  max-width: 200px; /* Limit width */
  z-index: 1000000 !important; /* Ensure it’s above other elements */
}




/* .toolTipText{
  position: absolute;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  width: auto;
  padding: 5px 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 1;
  transform: translateX(70%);
  align-self: flex-end;
  transition: opacity 0.3s ease;
  z-index: 10000;
} */
/* .toolTipText::before{
  content: "";
  position: absolute; */
  /* top: 15%; */
  /* left: -50%; */
  /* right:110%; */
  /* margin-right: ;
  transform: translateX(50%) rotate(90deg);
  border: 10px solid;
  border-color: #000 #0000 #0000 #0000;
} */


/* .toolTipText::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
} */
/* .toolTipText{ */
  /* right: -150%; */
  /* visibility: visible; */
  
/* } */
/* .toolTipCon:hover .toolTipText{
  visibility: visible;
  opacity: 1;
} */
</style>
