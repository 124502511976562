<template>
  <div class="p-4">
    <CusrtomizePatternWrapper
      :headingName="' Pivot Detection'"
      :tag="'Pocket'"
      :detail="'Identify key points where a stock shows strong buying interest,'"
      :highlighted="'often signaling the start of an upward trend.'"
    >
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Pocket Pivot Period
            </div>
            <div class="flex xxs:flex-col gap-2 md:flex-row md:items-center md:space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                > Find pocket pivot with a period of days.</span
              >
              <div class="w-auto flex gap-2 md:items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <!-- here was the select and options -->
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    tabindex="3"
                    v-model="settings.period"
                  />
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Set the scan to detect pocket pivots within a specified period, ${settings.period} indicating potential bullish momentum.`"
              />
            </div>
          </div>
        </div>

        <div @click="isToggleHandler">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="isToggle ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CusrtomizePatternWrapper from '../../CusrtomizePatternWrapper.vue'
import DescriptionInput from '../../DescriptionInput.vue'
export default {
  name: 'PocketPivotScans',
  components: { CusrtomizePatternWrapper, DescriptionInput },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isToggle: true,
      settingsData: {
        period: 5,
      },
    }
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    settings: {
      get() {
        return this.gurusScanSettings.pocketPivot || this.settingsData
      },
      set(value) {
        this.setPocketPivotScanSettings(value)
      },
    },
  },
  methods: {
    ...mapActions([
      'setPocketPivotScanSettings',
      'sendPocketPivotScanRequest',
      'cancelGuruScans',
      'pocketPivotChange'
    ]),
    isToggleHandler() {
      
      // this.isToggle = !this.isToggle
      // this.pocketPivotChange()
    },
  },
}
</script>

<style>
</style>