var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"hide-number-arrows hoverBorder focus:outline-none focus:ring-0",class:_vm.inputColor,style:({
      border: _vm.isError ? '1px solid red' : '1px solid #E0E0E0',
      color: _vm.isError ? '#F20E17' : '#333333',
    }),attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,_vm.value)>-1:(_vm.inputValue)},on:{"input":_vm.handleInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnterPress.apply(null, arguments)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"hide-number-arrows hoverBorder focus:outline-none focus:ring-0",class:_vm.inputColor,style:({
      border: _vm.isError ? '1px solid red' : '1px solid #E0E0E0',
      color: _vm.isError ? '#F20E17' : '#333333',
    }),attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.inputValue,_vm.value)},on:{"input":_vm.handleInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnterPress.apply(null, arguments)},"change":function($event){_vm.inputValue=_vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"hide-number-arrows hoverBorder focus:outline-none focus:ring-0",class:_vm.inputColor,style:({
      border: _vm.isError ? '1px solid red' : '1px solid #E0E0E0',
      color: _vm.isError ? '#F20E17' : '#333333',
    }),attrs:{"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":_vm.value,"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value},_vm.handleInput],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnterPress.apply(null, arguments)}}}),(_vm.iconImg)?_c('span',{staticClass:"input-icon",style:({
      background: _vm.imageColor ? '#f6f6f6' : 'white',
      height: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    })},[_c('img',{attrs:{"src":_vm.iconImg},on:{"click":_vm.togglePasswordVisibility}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }