<template>
  <div
    class="w-full h-full flex items-center justify-between py-2 pl-2 -border-b dark:border-[#2D2D2D] border-[#E2E8F0]"
  >
    <div>
      <span class="md:text-[18px] font-sans font-[600]">{{ header }}</span>
    </div>

    <div
      @click="onCloseHandler"
      class="cursor-pointer p-[8px] rounded-md hover:bg-appBtnHover dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          width="18"
          height="18"
        >
          <path
            stroke="currentColor"
            stroke-width="1.2"
            d="m1.5 1.5 15 15m0-15-15 15"
          ></path>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: ['header'],

  methods: {
    onCloseHandler() {
      this.$emit('close')
    },
  },
}
</script>
