<template>
  <div class="w-full h-full">
    <FormLayout>
      <div v-if="success" class="w-full h-full xxs:mt-[20px]">
        <div class="w-full h-full flex xxs:justify-center sm:justify-start xxs:items-center sm:items-start -bg-green-200">
          <span class="largeText" style="font-size: 44px"
            >Your password has been reset.
            <router-link :to="{ name: 'SignIn' }">
              <span
                style="color: #f20e17"
                class="hover:underline cursor-pointer"
                >Sign In</span
              >
            </router-link>
          </span>
        </div>
      </div>
      <div v-else class="w-full h-full -md:space-y-6 2lg:space-y-0 overflow-auto no-scrollbar -bg-slate-300 ">

        <div class="w-full h-[45%] -bg-slate-200 sm:h-[20%]">
          <img src="../assets/registrationSvgs/Reset-password.svg" class="w-full h-full sm:hidden">
        </div>

        <div
          class="formFreeTrial mt-[1.5rem]"
          style="
            height: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 10px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <span class="headerTitle text-appGrayDark"> Reset Password </span>
          <span
            v-if="showResponseError"
            class="errorText"
            style="
              color: #f20e17;
              font-size: 15px;
              margin-top: 4%;
              font-weight: 300;
            "
            >{{ showResponseError }}</span
          >
        </div>

        <!-- New password -->

        <div class="inputHeight " style="height: 8%">
          <DynamicInput
            :type="showPassword ? 'text' : 'password'"
            placeholder="New password"
            :iconImg="
              require(`@/assets/${
                isPasswordError
                  ? 'redEye.svg'
                  : showPassword
                  ? 'openEye.svg'
                  : 'closeEye.svg'
              }`)
            "
            v-model="password"
            :isError="isPasswordError"
            @password-visibility-toggled="handlePasswordVisibilityToggle"
          />
        </div>

        <div class="mt-3 ml-4 mb-4">
          <div class="w-full" style="display: flex; align-items: center">
            <div
              class=""
              :style="{
                height: '0.18rem',
                background: weakPassword === 0 ? '#F20E17' : '#BDBDBD',
                width: '31%',
              }"
            ></div>
            <div
              class=""
              :style="{
                height: '0.18rem',
                background: mediumPassword === 1 ? '#e2e522' : '#BDBDBD',
                width: '31%',
                margin: '0 2% 0 2%',
              }"
            ></div>
            <div
              class=""
              :style="{
                height: '0.18rem',
                background: strongPassword === 2 ? '#4ece31' : '#BDBDBD',
                width: '31%',
              }"
            ></div>
          </div>
          <div style="height: 4%; margin-top: 1%">
            <span
              v-if="passwordErrorMsg"
              style="color: #f20e17; font-size: 13px"
              class="errorText"
              >{{ showWarningIcon ? '⚠' : '' }} {{ passwordErrorMsg }}</span
            >
          </div>
          <div style="height: 4%">
            <span
              v-if="strongPassword"
              style="color: #333333; font-size: 13px"
              class="errorText"
              >Your Password is strong. Good Job!</span
            >
          </div>
        </div>

        <!-- <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            passwordErrorMsg
          }}</span>
        </div> -->
        <div class="inputHeight mt-[2rem]" style="height: 8%">
          <DynamicInput
            :type="showPassword ? 'text' : 'password'"
            placeholder="Confirm password"
            :iconImg="
              require(`@/assets/${
                isNewPasswordError
                  ? 'redEye.svg'
                  : showPassword
                  ? 'openEye.svg'
                  : 'closeEye.svg'
              }`)
            "
            v-model="confirmPassword"
            :isError="isNewPasswordError"
            @enter-pressed="handleReset"
            @password-visibility-toggled="handlePasswordVisibilityToggle"
          />
        </div>
        <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            newPasswordErrorMsg
          }}</span>
        </div>

        <div style="height: 8%" class="btnStyle inputHeight !mt-10">
          <button
            :class="
              password && confirmPassword
                ? 'bg-appBlue hover:bg-blueHover'
                : 'bg-[#E0E0E0] hover:bg-lightGrayHover'
            "
            @click="handleReset"
            :style="{
              color: password && confirmPassword ? 'white' : '#828282',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
            }"
            style="
              height: 100%;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
          >
            <span v-if="isLoading" style="margin-right: 10px">
              <Loader customClass="w-8 h-8" />
            </span>
            <span>
              {{ isLoading ? 'Sending link' : 'Send link' }}
            </span>
            <div v-if="password && confirmPassword" style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>

        <div class="font-[300] mt-8 text-justify sm:hidden">
          <span class="text-[#828282] leading-1">
            <span class="font-bold ">IMPORTANT NOTICE:</span class="!text-[#d13030] text-[104px]" style="color: #828282 !important;"> ProTrading Scans website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. ProTrading Scans are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.
          </span>
        <!-- </div> -->
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import FormLayout from '../component/FormLayout.vue'
import DynamicInput from '../component/DynamicInput.vue'
import Loader from '../component/Loading.vue'
import { ResetPassword } from '../services/user'

export default {
  name: 'ResetPassword',
  components: {
    FormLayout,
    DynamicInput,
    Loader,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordErrorMsg: '',
      newPasswordErrorMsg: '',
      showPassword: false,
      isPasswordError: false,
      isNewPasswordError: false,
      showResponseError: '',
      weakPassword: null,
      mediumPassword: null,
      strongPassword: null,
      isLoading: false,
      success: false,
      passwordRegix:
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,}$/,
    }
  },
  methods: {
    calculatePasswordStrength(pass) {
      if (pass?.length > 0) {
        if (pass?.length <= 3) {
          this.weakPassword = 0
          ;(this.mediumPassword = null), (this.strongPassword = null)
        } else if (pass?.length <= 8) {
          this.weakPassword = 0
          ;(this.mediumPassword = 1), (this.strongPassword = null)
        } else if (pass?.length > 9) {
          if (this.passwordRegix.test(pass)) {
            this.weakPassword = 0
            this.mediumPassword = 1
            this.strongPassword = 2
          }
        }
      } else {
        this.weakPassword = null
        this.mediumPassword = null
        this.strongPassword = null
      }
    },
    async handleReset() {
      if (!this.password && !this.confirmPassword) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = '*Required Field'
        return
      }

      if (!this.password) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        return
      }
      if (!this.confirmPassword) {
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = '*Required Field'
        return
      }
      if (this.password) {
        if (!this.passwordRegix.test(this.password)) {
          this.isPasswordError = true
          this.strongPassword = null
          this.showWarningIcon = true
          this.passwordErrorMsg =
            'The password must be more than 9 characters including uppercase,lowercase,numbers and special characters.'
          return
        }
      }

      if (this.password != this.confirmPassword) {
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = 'Passwords do not match.'
        return
      }

      this.isLoading = true
      let en = this.$route.query.en
      let iv = this.$route.query.iv
      const payload = {
        password: this.password,
        confirmPassword: this.confirmPassword,
        en: en,
        iv: iv,
      }

      const res = await ResetPassword(payload)
      if (!res?.data?.error) {
        this.success = true
      } else {
        this.showResponseError = res?.data?.message
        setTimeout(() => {
          this.showResponseError = ''
        }, 5000)
      }
      this.isLoading = false
    },
    handlePasswordVisibilityToggle() {
      this.showPassword = !this.showPassword
    },
  },
  watch: {
    password(newPassword) {
      this.calculatePasswordStrength(newPassword)
      this.isPasswordError = false
      this.passwordErrorMsg = ''
      this.showWarningIcon = false
    },
    confirmPassword(newPassword) {
      this.isNewPasswordError = false
      this.newPasswordErrorMsg = ''
    },
  },
}
</script>
