<template>
  <div
    class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden"
    :class="isLoading ? 'pointer-events-none' : ''"
  >
    <div class="w-full h-full text-[#000000]">
      <!--Header-->

      <div
        class="w-full !h-[50px] px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="
            getCopySymbol?.length > 0
              ? 'Copy symbol to new watchlist'
              : `Update your watchlist name`
          "
          @close="closeEditModal"
        />
      </div>
      <div
        class="border-t border-b border-[#E2E8F0] py-3 !px-8 w-full flex flex-col min-h-[130px]"
      >
        <div class="">
          <div
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[14px] font-normal"
          >
            Enter a new name in the input box to update your watchlist name.
          </div>
          <div
            class="flex items-center -h-[40%] w-[70%] space-x-1 dark:!border-[#2D2D2D] mt-3 dark:hover:bg-[#23232370] px-2 py-1.5 cursor-pointer text-sm hover:bg-[#eaeaea] dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
            :class="
              getTheme ? '!border-[#1a1a1a] border' : 'border border-[#D1D1D1]'
            "
          >
            <input
              v-model="inputValue"
              @keydown.enter="saveUpdatedWatchlist"
              value="inputValue"
              class="bg-transparent text-[#181818] dark:text-[#ffffff80] placeholder:text-[#323232] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
            />
          </div>
        </div>

        <div v-if="errorMessage && !successMessage" class="pt-2 text-errorText font-normal">
          <span>{{ errorMessage }}</span>
        </div>
      <div v-if="successMessage && !errorMessage" class="pt-2 text-green-600 font-normal">
         <span>{{ successMessage }}</span>
      </div>
      </div>

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3 dark:bg-[#1A1A1A]"
      >
        <ModalFooter
          firstTitle="Close"
          secondTitle="Save"
          colorType="color"
          :isLoading="isLoading"
          @close="closeEditModal"
          @save="saveUpdatedWatchlist"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Loader from './Loading.vue'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'

export default {
  name: 'EditWatchlistModal',

  data() {
    return {
      inputValue: '',
      errorMessage: '',
      screenWidth: window.innerWidth,
      isLoading: false,
      successMessage: '',
    }
  },

  props: {
    isAdd: Boolean,
  },

  components: {
    Loader,
    ModalHeader,
    ModalFooter,
  },

  computed: {
    ...mapGetters([
      'getTheme',
      'watchLists',
      'getSelectedWatchlist',
      'getCopySymbol',
    ]),
  },

  methods: {
    ...mapActions([
      'updatedWatchlist',
      'createNewWatchList',
      'setSelectedWatch',
      'renameWatchListItem',
    ]),

    checkName() {
      let isPresent = this.watchLists?.some(
        (item) => item?.name == this.inputValue
      )
      return isPresent
    },

    async handleCreateWatchlist() {
      this.isLoading = true
      let payload = {
        name: this.inputValue,
        updateModal: !this.isAdd,
      }
      let res = await this.createNewWatchList(payload)
      console.log("response===>",res);
      if (res?.data) {
        this.successMessage = `Watchlist created successfully.`
        const index = res?.data?.findIndex((x) => {
          return x.name == this.inputValue
        })
        this.setSelectedWatch(index)
        this.isLoading = false
        setTimeout(() => {
          this.closeEditModal()
        }, 1000);
      } else {
        this.errorMessage = 'Some error occured while creating watchlist'
      }
      this.isLoading = false
    },

    async handleUpdateWatchlist() {
      this.isLoading = true
      let res = await this.renameWatchListItem(this.inputValue)
      if (!res?.isError) {
        this.isLoading = false
       
        this.closeEditModal()
      } else {
        this.errorMessage = 'Some error occured updating watchlist name'
      }
      this.isLoading = false
    },

    async saveUpdatedWatchlist() {
      let nameAlreadyPresent = this.checkName()
      const regex = /^\s+/

      if (this.inputValue == '') {
        this.errorMessage = 'Empty watchlist name is not allowed'
      } else if (regex.test(this.inputValue)) {
        this.errorMessage = 'Multiple spaces are not allowed'
      } else if (nameAlreadyPresent) {
        this.errorMessage = 'Same name of watchlist already present'
      } else if (this.inputValue?.length > 15) {
        this.errorMessage = 'Watchlist name is too long'
      } else {
        if (this.isAdd && this.inputValue) {
          this.handleCreateWatchlist()
        } else {
          this.handleUpdateWatchlist()
        }
      }
    },
    closeEditModal() {
      this.$emit('close-Edit-Modal')
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },

  watch: {
    inputValue() {
      this.errorMessage = ''
    },
  },

  mounted() {
    if (!this.isAdd) {
      setTimeout(() => {
        this.inputValue = this.getSelectedWatchlist?.name
      }, 200)
    } else {
      this.inputValue = `Untitled-${this.watchLists?.length + 1}`
    }
    // Add event listener when the component is mounted
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}

.scroll-container {
  /* Adjust height as needed */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>
