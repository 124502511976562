<template>
  <div class="w-full h-full justify-between flex xxs:flex-col xl:flex-row">
    <div
      class="xxs:w-full  xl:w-[50%] xl:!pr-4 -bg-black xl:border-r h-fit flex gap-y-8 pb-[2rem] xl:dark:border-[#181818] flex-col"
    >
      <div
        class="w-full flex gap-y-4 flex-col"
        v-for="(item, index) in accountSettingData"
        :key="index"
      >
        <div class="flex items-center space-x-3" :key="index">
          <span
            class="text-[#000000] font-[700] dark:text-[#F6F6F6] text-[16px]"
          >
            {{ item?.heading }}
          </span> 
        </div>
        <div
          class="errorText text-errorText"
          style="font-size: 15px; margin-top: 4%; font-weight: 300"
          v-if="showMessage"
        >
          {{ showMessage }}
        </div>
        <div v-for="(sett, index) in item.settings" :key="index">
          <div :key="index" class="flex flex-col space-y-2 mt-[0.8rem]">
            <div>
              <span
                class="text-[#000000] text-[12px] dark:text-[#F6F6F6] font-[400]"
                >{{ sett?.name }}</span
              >
            </div>
            <div
              class="w-full flex rounded-[6px] border border-[#32323280] dark:!border-[#2d2d2d] bg-[#f6f6f6] dark:bg-[#181818]"
            >
              <div
                v-if="
                  sett?.type == 'text' ||
                  (sett?.type == 'password') 
                "
                class="w-full h-[2.5rem]   flex overflow-hidden"
                
              >
                <input
                :class="sett.key == 'email' ? 'pointer-events-none' : ''"
                  :type="sett.type"
                  v-model="sett.defaultValue"
                  @input="updateChartSetting()"
                  class="w-[85%] !pl-3 focus:outline-none dark:text-[#F6F6F6] focus:ring-0 h-full rounded-tl-[6px] rounded-bl-[6px] border-none bg-transparent"
                />  
                <div
                  v-if="sett?.icon"
                  @click="$emit('seletedInputEmit',sett)"
                  class="bg-transparent flex items-center cursor-pointer justify-center w-[15%] rounded-tr-[6px] rounded-br-[6px]"
                >
                  <img
                    :src="require(`../assets/${sett?.icon}.svg`)"
                    class="w-5 h-5"
                  />
                </div>
              </div>
            </div>
            <div
              class="errorText text-errorText"
              style="font-size: 12px; font-weight: 300"
              v-if="errors[sett.key]"
            >
              {{ errors[sett.key] }}
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="xxs:w-full xl:w-[48%] h-fit flex gap-y-8 pb-[2rem] flex-col">
      <div
        class="w-full flex gap-y-4 flex-col"
        v-for="(item, index) in themeSetting"
        :key="index"
      >
        <div class="flex items-center space-x-3" :key="index">
          <span
            class="text-[#000000] dark:text-[#ffffff] font-[700] text-[16px]"
          >
            {{ item?.heading }}
          </span>
        </div>

        <div v-for="(sett, index) in item.settings" :key="index">
          <div
            v-if="sett?.isGroup?.length > 0"
            class="w-full space-y-3 rounded-[12px] bg-[#EFEFEF] dark:bg-[#181818]"
          >
            <div
              v-for="(val, ind) in sett?.isGroup"
              :key="ind"
              @click="handleCheckBoxes(item, val)"
              :class="[customClass(item, val),item.key == 'subscription' ? 'pointer-events-none':'']"
           
              class="w-full p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]"
            >
              <div>
                <div
                  v-if="val?.type == 'color'"
                  class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden"
                >
                  <input
                    type="color"
                    class="w-full h-full cursor-pointer bg-transparent"
                    :value="val.defaultValue"
                  />
                </div>
                <div
                  v-if="val?.type == 'checkbox'"
                  :class="
                    val.defaultValue ? 'text-[#037950]' : 'text-[#989898] '
                  "
                  class="py-1.5 mx-1 cursor-pointer flex rounded-full overflow-hidden"
                >
                  <svg
                    class="w-[20px] h-[20px]"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]"
              >
                {{ val?.name }}
              </div>
            </div>

           
            <div v-if="item.key == 'subscription'" class="px-3 space-y-2" >
              <div v-if="cancelSubError" class="mt-1 text-red-500" >
                {{ cancelSubError }}
              </div>
              <div class="w-full flex items-center gap-3 justify-end pb-2">

              <div
               v-if="getUserSubscriptionDetail"
               @click="openCancelSubscriptionModal=true"
                class="cursor-pointer text-[#fff] bg-appBlue hover:bg-blueHover !border-none border dark:border-[#2D2D2D] border-[#D1D1D1] px-[20px] py-[6px] rounded-[10px]"
              >
               {{loading?'Canceling':'Cancel'}}
              </div>
              <div v-else>
                 {{ getRemainingDays() }}
              </div>
            </div>
             
            </div>
          </div>
          <div
            v-else
            :key="index"
            @click="handleCheckBoxes(item, sett)"
            class="w-full cursor-pointer p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]"
          >
            <div>
              <div
                v-if="sett?.type == 'color'"
                class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden"
              >
                <input
                  type="color"
                  class="w-full h-full cursor-pointer"
                  :value="sett.defaultValue"
                />
              </div>
              <div
                v-if="sett?.type == 'checkbox'"
                :class="
                  sett.defaultValue ? 'text-[#037950]' : 'text-[#989898] '
                "
                class="py-1.5 mx-1 flex rounded-full overflow-hidden"
              >
                <svg
                  class="w-[20px] h-[20px]"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div
              class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]"
            >
              {{ sett?.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseModal v-if="openCancelSubscriptionModal">
        <RemoveAlertModal header="Subscription" @remove="handleCancelPlan" @closeModal="handleCloseCancel" message="Are you sure, you want to cancel the subscription?" />
    </BaseModal>
  
  </div>
</template>

<script>
import DynamicInput from './DynamicInput.vue'
import {mapGetters} from 'vuex'
import BaseModal from './BaseModal.vue';
import RemoveAlertModal from './RemoveAlertModal.vue';
import { deleteSubscription } from '../services/user';
import moment from 'moment';

export default {
    name: 'AccountSetting',
    props: {
        subscriptionSettingProp: Array,
        accountSettingProp: Array,
        showMessage: String,
        errors: Object,
        isPasswordErrorProps: Boolean,
    },
    data() {
        return {
            accountSettingData: JSON.parse(JSON.stringify(this.accountSettingProp)),
            themeSetting: JSON.parse(JSON.stringify(this.subscriptionSettingProp)),
            showPassword: false,
            openCancelSubscriptionModal:false,
            loading:false,
            cancelSubError:null,
        };
    },
    computed: {
      ...mapGetters(['getUserSubscriptionDetail','getTrialStartDate'])
    },
    methods: {
      async handleCancelPlan(){
        this.openCancelSubscriptionModal = false
       const payload = this.getUserSubscriptionDetail?.subscriptionId
        if(payload){
          this.loading = true
          try{
            let res = await deleteSubscription(payload)
            if(res?.id){
              window.location.reload()
            }else{
            this.cancelSubError = "Some error occurred"
            }
            console.log("res==>",res)
          }catch(error){
            this.loading = false
            this.cancelSubError = "Internal server error"
          }
          this.loading = false
        }
      },
      handleCloseCancel(){
        this.openCancelSubscriptionModal = false
      },
      getRemainingDays(){
        if(this.getTrialStartDate){
          const isoDate = new Date(this.getTrialStartDate).toISOString();
          const startDate = moment(isoDate);
          // const expirationDate =  startDate.add(2, 'minutes');
          const expirationDate =  startDate.add(90, 'days');
          if (moment().isBefore(expirationDate)) {
            const daysRemaining = expirationDate.diff(moment(), 'days');
            // const daysRemaining = expirationDate.diff(moment(), 'minutes');
            return `Free trial ends in ${daysRemaining} day(s)` 
          }else{
            window.location.reload()
          }
        }
      },
     
      handlePasswordVisibilityToggle(){
        this.showPassword = !this.showPassword
      },
        updateChartSetting() {
            this.$emit('update-account-settings', this.accountSettingData);
        },
        updateParentTheme() {
            this.$emit('update-theme-settings', this.themeSetting);
        },
        customClass(item, sett) {
            if (item?.key == 'upDownBarChart') {
                if (sett?.key == 'barChartColor') {
                    if (item?.isToggle) {
                        return 'pointer-events-none opacity-40 dark:opacity-50';
                    }
                    else {
                        return '';
                    }
                }
                if (sett?.key == 'barUpColor' || sett?.key == 'barDownColor') {
                    if (item?.isToggle) {
                        return '';
                    }
                    else {
                        return 'pointer-events-none opacity-40 dark:opacity-50';
                    }
                }
            }
            if (item?.key == 'upDownVolume') {
                if (sett?.key == 'volumeColor') {
                    if (item?.isToggle) {
                        return 'pointer-events-none opacity-40 dark:opacity-50';
                    }
                    else {
                        return '';
                    }
                }
                if (sett?.key == 'upVolumeColor' || sett?.key == 'downVolumeColor') {
                    if (item?.isToggle) {
                        return '';
                    }
                    else {
                        return 'pointer-events-none opacity-40 dark:opacity-50';
                    }
                }
            }
            if (item?.key == 'crossHair') {
                if (sett?.key == 'crossLineColor' || sett?.key == 'magnet') {
                    if (item?.isToggle) {
                        return '';
                    }
                    else {
                        return 'pointer-events-none opacity-40 dark:opacity-50';
                    }
                }
            }
        },
        handleCheckBoxes(item, sett) {
            if (item.key == 'subscription') {
                let themeList = item?.settings[0]?.isGroup;
                const updatedArr = themeList?.map((val) => {
                    return {
                        ...val,
                        defaultValue: val.key === sett.key ? true : false,
                    };
                });
                this.themeSetting = this.themeSetting?.map((data) => {
                    if (data?.key === item?.key) {
                        return {
                            ...data,
                            settings: [
                                {
                                    isGroup: updatedArr,
                                },
                            ],
                        };
                    }
                    else {
                        return data;
                    }
                });
                this.$emit('update-subscription-settings', this.themeSetting);
            }
        },
    },
    watch: {
      cancelSubError(val){
        if(val){
          setTimeout(() => {
            this.cancelSubError = ""
          }, 3000);
        }
        },
        subscriptionSettingProp: {
            handler(newVal) {
                this.themeSetting = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true,
        },
        accountSettingProp: {
            handler(newVal) {
                this.accountSettingData = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true,
        },
    },
    components: { DynamicInput,BaseModal,RemoveAlertModal }
}
</script>

<style scoped>
input[type='color'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
}

/* Hide default color input's inner box */
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 50%;
}

input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

input[type='color']::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}
</style>
