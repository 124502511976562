<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Measures'">
      <Measures @measures="measures" :id="id" />
    </div>

    <div v-if="selectedButtonTypeName == 'Capitalization'">
      <Capitalization @capitalization="capitalization"  :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Returns'">
      <Returns @returns="returns" :id="id" />
    </div>
  </div>
</template>

<script>
import Filters from './Filters.vue'
import Measures from './Measures.vue'
import Capitalization from './Capitalization.vue'
import Returns from './Returns.vue'
export default {
  components: { Filters, Measures, Capitalization, Returns },
  name: 'FundamentalScanner',
  data() {
    return {
      id: 'fundementals',
    }
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {
    measures(m){
      this.$emit('measures',m)
    },
    capitalization(cap){
      this.$emit("capitalization",cap)
    },
    returns(ret){
      this.$emit('returns',ret)
    }
  },
  computed: {},
  watch: {},
}
</script>

<style>
</style>  