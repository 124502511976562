<template>
  <div class="space-y-2 w-full h-full">
    <!-- first filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('top')"
      :selectedKey="'top'"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full -bg-black"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">Top Performers</h3>
        <div
          class="w-[100%] gap-3 flex xxs:flex-col sm:flex-row sm:items-center"
        >
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="top"
              v-model="settings.mode"
            />
          </div>
          <div class="space-x-3 -bg-orange-500">
            <span>top</span>
            <input
              class="px-3 w-[100px] rounded-[2.5px] border-2 border-appBlue"
              style="height: 45px; font-size: 16px"
              type="number"
              v-model="settings.topCount"
            />
          </div>
          <div class="space-x-3 -bg-green-500">
            <span>stocks by</span>

            <select
              v-model="settings.topMethod"
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 150px; height: 45px; font-size: 16px"
              type="select"
            >
              <option
                v-for="topMode in topMethodOptions"
                :key="topMode.value"
                :value="topMode.value"
              >
                {{ topMode.text }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Show the top $${settings.topCount} stocks based on ${settings.topMethod}.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- second filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('closedOnHigh')"
      :selectedKey="'closedOnHigh'"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full py-3.5"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">High Close Stocks</h3>
        <div class="w-[100%] -bg-slate-400 gap-3 flex items-center">
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="closedOnHigh"
              v-model="settings.mode"
            />
          </div>
          <span
            >Include all stocks that closed at their highest price of the
            day.</span
          >
        </div>

        <div>
          <DescriptionInput
            :description="`Select all stocks that closed at their daily high.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- third filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('closedOnHighPct')"
      :selectedKey="'closedOnHighPct'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">Near High Close</h3>
        <div class="w-[100%] gap-3 flex items-center">
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="closedOnHighPct"
              v-model="settings.mode"
            />
          </div>
          <span> Include all stocks that closed within </span>

          <input
            class="px-3 w-[100px] rounded-[2.5px] border-2 border-appBlue"
            style="height: 45px; font-size: 16px"
            type="number"
            v-model="highPct"
          />
          <span>% of their daily high.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Select all stocks that closed within ${highPct}% of their daily high.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- fourth filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('closedOnLow')"
      :selectedKey="'closedOnLow'"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full py-3.5"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">Low Close Stocks</h3>
        <div class="w-[100%] gap-3 flex items-center">
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="closedOnLow"
              v-model="settings.mode"
            />
          </div>
          <span>{{
            `Include all stocks that closed at their lowest price of the day.`
          }}</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Select all stocks that closed at their daily low.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- fifth filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('closedOnLowPct')"
      :selectedKey="'closedOnLowPct'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">Near Low Close</h3>
        <div class="w-[90%] gap-3 flex items-center">
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="closedOnLowPct"
              v-model="settings.mode"
            />
          </div>

          <span>Include all stocks that closed within </span>
          <input
            class="px-3 w-[100px] rounded-[2.5px] border-2 border-appBlue"
            style="height: 45px; font-size: 16px"
            type="number"
            v-model="lowPct"
          />
        </div>

        <div>
          <DescriptionInput
            :description="`Select all stocks that closed within ${lowPct}% of their daily low.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- fifth filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('range')"
      :selectedKey="'range'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">Price Range Close</h3>
        <div class="w-[100%] gap-3 flex xxs:flex-col sm:flex-row xxs:items-start sm:items-center">
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              style="width: 20px; height: 20px"
              type="radio"
              class="hidden"
              id="activity_settings~top"
              name="activity_settings"
              value="range"
              v-model="settings.mode"
            />
          </div>
          <div class="w-auto">
            <span>{{
              `Include all stocks that closed within the price range of `
            }}</span>
            <input
              class="px-3 w-[100px] rounded-[2.5px] border-2 border-appBlue"
              style="height: 45px; font-size: 16px"
              type="number"
              tabindex="3"
              v-model="settings.priceRangeStart"
            />
          </div>
          <div class="w-auto space-x-3">
            <span>to</span>
            <input
              class="px-3 w-[100px] rounded-[2.5px] border-2 border-appBlue"
              style="height: 45px; font-size: 16px"
              type="number"
              tabindex="3"
              v-model="settings.priceRangeEnd"
            />
            <span>dollars.</span>
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Select all stocks that closed within ${settings.priceRangeEnd}% of their daily low.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
const DEFAULT_SETTINGS = {
  mode: 'top',
  topCount: 10,
  topMethod: 'volume',
  withinPct: 1.0,
  priceRangeStart: 1,
  priceRangeEnd: 5,
}
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'Filters',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedTopMethod: 'volume',
      lowPct: 5,
      highPct: 5,
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      topMethodOptions: [
        {
          text: 'Volume',
          value: 'volume',
        },
        {
          text: 'Price Increase',
          value: 'priceIncrease',
        },
        {
          text: '% Price Increase',
          value: 'priceIncreasePct',
        },
        {
          text: 'Market Cap',
          value: 'marketCap',
        },
      ],
    }
  },
  components: { DescriptionInput, Wrapper },
  computed: {
    ...mapGetters([
      'filterSettingsForScan',
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'favSelectedScanId',
      'themeDarkMode',
      'getActivitySettings',
    ]),
    modalID() {
      return this.$props.id
    },
  },
  methods: {
    ...mapActions([
      'setScanFilterSettings',
      'setScanFilterSettingsOld',
      'setActivityScanSettings',
      'resetGurusSettings',
      'getUserScanList',
      'setScanFilterSettings',
    ]),

    isActiveHandler(key) {
      this.settings.mode = key
      // const val = this.getActive(key, 'isActive')
      // this.setSetting(, 'isActive', !val)
    },
    // setSetting(key1, key2, val) {
    //   const filters = this.filterSettingsForScan(this.modalID)
    //   if (key1 == 'isExcludeIntraDayData') {
    //     filters[key1] = val
    //   } else if (key1 == 'isIgnoreResultsWithNoRangeForLastDay') {
    //     filters[key1] = val
    //   } else {
    //     filters[key1][key2] = val
    //   }
    //   const payload = {
    //     l: 'settings_single',
    //     id: this.id,
    //     filters,
    //   }
    //   this.setScanActivitySettings(payload)
    // },
    getActive(key1, key2) {
      return this.settings.mode == key1
      // const filters = this.filterSettingsForScan(this.modalID)
      // if (key1 === 'isIgnoreResultsWithNoRangeForLastDay') {
      //   console.log('isIgnore===>', key1)
      //   return filters[key1]
      // } else if (key1 == 'isExcludeIntraDayData') {
      //   return filters[key1]
      // } else {
      //   return filters[key1][key2]
      // }
    },
  },
  mounted() {
    let set = this.getActivitySettings('activity')
    this.settings = set
  },
  watch: {
    settings: {
      handler: function (newSettings) {
        this.setActivityScanSettings(newSettings)
      },
      deep: true,
    },
    highPct(newVal) {
      if (this.settings.mode === 'closedOnHighPct') {
        this.settings.withinPct = newVal
      }
    },
    lowPct(newVal) {
      if (this.settings.mode === 'closedOnLowPct') {
        this.settings.withinPct = newVal
      }
    },
  },
}
</script>

<style></style>
