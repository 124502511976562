<template>
  <div class="w-full h-full">
    <FormLayout>
      <div v-if="linkSent" class="w-full h-full xxs:mt-[20px]">
        <div
          class="w-full h-full flex xxs:justify-center sm:justify-start xxs:items-center sm:items-start -bg-green-200"
        >
          <span class="largeText" style="font-size: 44px"
            >Reset password link has been sent to your
            <span style="color: #f20e17">Email</span></span
          >
        </div>
      </div>
      <div v-else class="w-full h-full md:space-y-6 2lg:space-y-0 overflow-auto no-scrollbar -bg-slate-300 ">
        <div class="w-full h-[50%] -bg-green-400 sm:h-[20%] -bg-slate-200">
          <img src="../assets/registrationSvgs/Forgot-password.svg" class="w-full h-full sm:hidden">
        </div>
        <div
          class="formFreeTrial mt-[1.5rem]"
          style="
            height: 22% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 20px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <span class="headerTitle  text-appGrayDark"> Forgot Password </span>
          <span
            v-if="showResponseError"
            class="errorText"
            style="
              color: #f20e17;
              font-size: 15px;
              margin-top: 4%;
              font-weight: 300;
            "
            >{{ showResponseError }}</span
          >
        </div>
        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            type="text"
            placeholder="Email"
            @enter-pressed="handleForget"
            :isError="isEmailError"
            :iconImg="
              require(`@/assets/${isEmailError ? 'redEmail.svg' : 'email.svg'}`)
            "
            v-model="email"
          />
        </div>
        <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            emailErrorMsg
          }}</span>
        </div>

        <div style="height: 8%" class="btnStyle inputHeight !mt-10">
          <button
            :class="
              email
                ? 'bg-appBlue hover:bg-blueHover'
                : 'bg-[#E0E0E0] hover:bg-lightGrayHover'
            "
            @click="handleForget"
            :style="{
              color: email ? 'white' : '#828282',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
            }"
            style="
              height: 100%;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
          >
            <span v-if="isLoading" style="margin-right: 10px">
              <Loader customClass="w-8 h-8" />
            </span>
            <span>
              {{ isLoading ? 'Sending link' : 'Send link' }}
            </span>
            <div v-if="email" style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
        <div class="w-full flex justify-end">
          <router-link :to="{ name: 'SignIn' }">
            <div
              class="w-fit hover:underline text-[#F20E17] text-appBlue cursor-pointer"
            >
              Back to Sign In
            </div>
          </router-link>
        </div>

        <div class="font-[300] mt-8 text-justify sm:hidden">
          <span class="text-[#828282] leading-1">
            <span class="font-bold ">IMPORTANT NOTICE:</span class="!text-[#d13030] text-[104px]" style="color: #828282 !important;"> ProTrading Scans website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. ProTrading Scans are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.
          </span>
        <!-- </div> -->
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import FormLayout from '../component/FormLayout.vue'
import DynamicInput from '../component/DynamicInput.vue'
import Loader from '../component/Loading.vue'
import { forgetPassword } from '../services/user'

export default {
  name: 'ForgetPassword',
  components: {
    FormLayout,
    DynamicInput,
    Loader,
  },
  data() {
    return {
      isUnderstood: false,
      email: '',
      emailErrorMsg: '',
      isEmailError: false,
      showResponseError: '',
      isLoading: false,
      emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      linkSent: false,
    }
  },
  methods: {
    async handleForget() {
      if (!this.email) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        return
      }

      if (this.email) {
        if (!this.emailRegix.test(this.email)) {
          this.isEmailError = true
          this.emailErrorMsg = 'Invalid Email'
          return
        }
      }

      this.isLoading = true
      const payload = {
        email: this.email,
      }

      console.log('payload', payload)
      const res = await forgetPassword(payload)
      console.log('forget password', res)
      if (!res?.data?.isError) {
        this.linkSent = true
      } else {
        this.showResponseError = res?.data?.message
        setTimeout(() => {
          this.showResponseError = ''
        }, 5000)
      }
      this.isLoading = false
    },
  },
  watch: {
    email() {
      this.isEmailError = false
      this.emailErrorMsg = ''
    },
  },
}
</script>
