<template>
  <div
    class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[38vw] 2lg:w-[30vw] space-y-1 bg-white !overflow-hidden"
  >
    <div class="w-full h-full text-[#000000] ">
      <!--Header-->

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="`Choose a file format to download
          ${getSelectedWatchlist.name}`"
          @close="closeDownloadModal"
        />
      </div>

      <div
        class="w-full min-h-[159px] border-t border-b border-[#E2E8F0] flex justify-center items-center"
      >
      <!-- CSV -->
        <div
          class="xxs:w-[45%]  qm:w-[40%] -shrink-0 -border flex justify-center text-[#9E9E9E]"
        >
          <!-- icon dark:bg-[#FFFFFF1A] -->
          <!-- icon dark:text-[#B0B0B0] -->
          <!-- btn-bg dark:hover:bg-[#232323]  -->
          <!-- btn-bg-light dark:hover:bg-[#F6F6F6]  -->
          <!-- txt dark:text-[#F6F6F6] -->
          <!-- dark:!border-[#2D2D2D] -->

          <!-- csv -->
          <!-- if true -->
          <div
            @click="downloadCsvFile"
            :class="
              fileType == 'csv'
                ? '!border-[#58ba2a] dark:!border[#58ba2a] !bg-[#f3fbed] dark:!bg-[#FFFFFF1A] text-[#41901C]'
                : ''
            "
            class="flex gap-2 justify-around items-center dark:hover:bg-[#232323] text-[#9E9E9E] px-3 mt-4 mb-4 w-[90%] h-[8vh] !border dark:border-[#2D2D2D] rounded-md cursor-pointer"
          >
            <div class="h-auto w-auto flex flex-col gap-2 justify-center">
              <span class="text-[#6D6D6D] text-[13px] font-normal">
                <!-- true -->
                <!-- Download CSV -->
                <div v-if="fileType == 'csv'">
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.5 18C5.25736 18 3.13604 18 1.81802 16.6819C0.5 15.364 0.5 13.2426 0.5 9C0.5 4.75736 0.5 2.63604 1.81802 1.31802C3.13604 -1.07288e-07 5.25736 0 9.5 0C13.7426 0 15.864 -1.07288e-07 17.1819 1.31802C18.5 2.63604 18.5 4.75736 18.5 9C18.5 13.2426 18.5 15.364 17.1819 16.6819C15.864 18 13.7426 18 9.5 18ZM13.1273 6.2727C13.3909 6.5363 13.3909 6.9637 13.1273 7.22727L8.62727 11.7273C8.36366 11.9909 7.93634 11.9909 7.6727 11.7273L5.8727 9.92727C5.6091 9.66366 5.6091 9.23634 5.8727 8.97273C6.1363 8.70912 6.5637 8.70912 6.8273 8.97273L8.15 10.2954L12.1727 6.2727C12.4363 6.0091 12.8637 6.0091 13.1273 6.2727Z"
                      fill="#41901C"
                    />
                  </svg>
                </div>

                <!-- false -->
                <!-- Download CSV -->
                <div v-else>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.17158 16.3284L2.17157 16.3284C1.62095 15.7778 1.31759 15.0411 1.16029 13.8711C1.00106 12.6867 1 11.1354 1 9C1 6.86454 1.00106 5.31325 1.16029 4.1289C1.31759 2.95893 1.62095 2.2222 2.17158 1.67158L2.17158 1.67158C2.7222 1.12095 3.45893 0.817593 4.6289 0.660294C5.81325 0.501062 7.36454 0.5 9.5 0.5C11.6354 0.5 13.1867 0.501062 14.3711 0.660294C15.5411 0.817593 16.2778 1.12095 16.8284 1.67157L16.8284 1.67158C17.379 2.2222 17.6824 2.95893 17.8397 4.1289C17.9989 5.31325 18 6.86454 18 9C18 11.1354 17.9989 12.6867 17.8397 13.8711C17.6824 15.0411 17.379 15.7778 16.8284 16.3284L16.8284 16.3284C16.2778 16.879 15.5411 17.1824 14.3711 17.3397C13.1867 17.4989 11.6354 17.5 9.5 17.5C7.36454 17.5 5.81325 17.4989 4.6289 17.3397C3.45893 17.1824 2.7222 16.879 2.17158 16.3284Z"
                      stroke="#BCBCBC"
                    />
                  </svg>
                </div>
              </span>
            </div>

            <div class="h-auto w-auto flex flex-col gap-2 justify-center">
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.19113 0C4.43988 0 3.02051 1.42 3.02051 3.17125V36.8219C3.02051 38.5738 4.43988 39.9931 6.19113 39.9931H34.7999C36.5505 39.9931 37.9711 38.5738 37.9711 36.8219V12.6938L26.1568 0L6.19113 0Z"
                  fill="#45B058"
                />
                <path
                  d="M15.6916 26.9991C15.7704 27.0891 15.8154 27.2016 15.8154 27.3253C15.8154 27.5616 15.6241 27.7753 15.376 27.7753C15.2635 27.7753 15.1398 27.7303 15.061 27.6291C14.6223 27.1003 13.8791 26.7622 13.1816 26.7622C11.5385 26.7622 10.2898 28.0228 10.2898 29.8122C10.2898 31.5903 11.5391 32.8616 13.1816 32.8616C13.8904 32.8616 14.5879 32.5578 15.061 32.0059C15.1391 31.9159 15.2635 31.8603 15.376 31.8603C15.6354 31.8603 15.8154 32.0847 15.8154 32.3216C15.8154 32.4341 15.7704 32.5466 15.6916 32.6366C15.106 33.2441 14.3073 33.6947 13.1823 33.6947C11.0216 33.6947 9.32227 32.1191 9.32227 29.8122C9.32227 27.5053 11.0216 25.9297 13.1823 25.9297C14.3073 25.9303 15.1173 26.3803 15.6916 26.9991ZM19.9429 33.6953C18.8291 33.6953 17.951 33.3241 17.3098 32.7609C17.2085 32.6709 17.1635 32.5472 17.1635 32.4234C17.1635 32.1978 17.3323 31.9503 17.6023 31.9503C17.6923 31.9503 17.7935 31.9728 17.8723 32.0403C18.3898 32.5022 19.1098 32.8616 19.9766 32.8616C21.316 32.8616 21.7435 32.1416 21.7435 31.5672C21.7435 29.6316 17.2985 30.7009 17.2985 28.0222C17.2985 26.7847 18.401 25.9403 19.8754 25.9403C20.8429 25.9403 21.676 26.2322 22.2835 26.7391C22.3848 26.8291 22.441 26.9528 22.441 27.0766C22.441 27.3016 22.2498 27.5266 22.0016 27.5266C21.9116 27.5266 21.8104 27.4928 21.7316 27.4253C21.1804 26.9753 20.4941 26.7728 19.8073 26.7728C18.9073 26.7728 18.2654 27.2566 18.2654 27.9659C18.2654 29.6541 22.7104 28.6859 22.7104 31.4884C22.711 32.5809 21.9685 33.6953 19.9429 33.6953ZM30.5641 26.6616L27.9648 33.1209C27.841 33.4247 27.5366 33.6272 27.2223 33.6272H27.1998C26.8735 33.6272 26.5698 33.4247 26.4454 33.1209L23.8566 26.6616C23.8341 26.6053 23.8229 26.5491 23.8229 26.4816C23.8229 26.2566 24.0248 25.9859 24.3291 25.9859C24.5204 25.9859 24.7004 26.0984 24.7791 26.2897L27.2098 32.5347L29.6404 26.2897C29.7079 26.1097 29.8879 25.9859 30.0904 25.9859C30.3829 25.9859 30.5966 26.2222 30.5966 26.4816C30.5973 26.5378 30.586 26.6053 30.5641 26.6616Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38.0004 12.7231V13.3481H30.0004C30.0004 13.3481 26.0554 12.5606 26.1704 9.15625C26.1704 9.15625 26.3004 12.7231 29.9223 12.7231H38.0004Z"
                  fill="#41901C"
                />
                <path
                  opacity="0.5"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M26.1865 0.00390625V9.10453C26.1865 10.1395 26.8765 12.7239 30.0015 12.7239H38.0015L26.1865 0.00390625Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="h-auto w-[80px] -!border">
              <span
                class="flex flex-col justify-center -bg-black mr-4"
                :class="fileType == 'csv' ? 'text-[#41901C]' : ''"
                >CSV</span
              >
            </div>
          </div>
        </div>
        <!-- Plain Text -->
        <div
          class="xxs:w-[45%] qm:w-[40%]  -shrink-0s -border flex justify-center text-[#9E9E9E]"
        >
          <!-- txt -->
          <!-- if true -->
          <div
            @click="downloadTxtFile"
            :class="
              fileType == 'txt'
                ? '!border-[#58ba2a] dark:!border[#58ba2a] !bg-[#f3fbed] dark:!bg-[#FFFFFF1A] text-[#41901C]'
                : ''
            "
            class="flex gap-2 justify-around items-center dark:hover:bg-[#232323] dark:border-[#2D2D2D] px-3 mt-4 mb-4 w-[90%] h-[8vh] !border rounded-md cursor-pointer"
          >
            <div
              class="h-auto w-auto flex flex-col gap-2 justify-center -bg-black"
            >
              <span class="text-[13px] font-normal">
                <!-- true -->
                <!-- Download TXT -->
                <svg
                  v-if="fileType == 'txt'"
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.5 18C5.25736 18 3.13604 18 1.81802 16.6819C0.5 15.364 0.5 13.2426 0.5 9C0.5 4.75736 0.5 2.63604 1.81802 1.31802C3.13604 -1.07288e-07 5.25736 0 9.5 0C13.7426 0 15.864 -1.07288e-07 17.1819 1.31802C18.5 2.63604 18.5 4.75736 18.5 9C18.5 13.2426 18.5 15.364 17.1819 16.6819C15.864 18 13.7426 18 9.5 18ZM13.1273 6.2727C13.3909 6.5363 13.3909 6.9637 13.1273 7.22727L8.62727 11.7273C8.36366 11.9909 7.93634 11.9909 7.6727 11.7273L5.8727 9.92727C5.6091 9.66366 5.6091 9.23634 5.8727 8.97273C6.1363 8.70912 6.5637 8.70912 6.8273 8.97273L8.15 10.2954L12.1727 6.2727C12.4363 6.0091 12.8637 6.0091 13.1273 6.2727Z"
                    fill="#41901C"
                  />
                </svg>

                <!-- false -->
                <!-- Download TXT -->
                <svg
                  v-else
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.17158 16.3284L2.17157 16.3284C1.62095 15.7778 1.31759 15.0411 1.16029 13.8711C1.00106 12.6867 1 11.1354 1 9C1 6.86454 1.00106 5.31325 1.16029 4.1289C1.31759 2.95893 1.62095 2.2222 2.17158 1.67158L2.17158 1.67158C2.7222 1.12095 3.45893 0.817593 4.6289 0.660294C5.81325 0.501062 7.36454 0.5 9.5 0.5C11.6354 0.5 13.1867 0.501062 14.3711 0.660294C15.5411 0.817593 16.2778 1.12095 16.8284 1.67157L16.8284 1.67158C17.379 2.2222 17.6824 2.95893 17.8397 4.1289C17.9989 5.31325 18 6.86454 18 9C18 11.1354 17.9989 12.6867 17.8397 13.8711C17.6824 15.0411 17.379 15.7778 16.8284 16.3284L16.8284 16.3284C16.2778 16.879 15.5411 17.1824 14.3711 17.3397C13.1867 17.4989 11.6354 17.5 9.5 17.5C7.36454 17.5 5.81325 17.4989 4.6289 17.3397C3.45893 17.1824 2.7222 16.879 2.17158 16.3284Z"
                    stroke="#BCBCBC"
                  />
                </svg>
              </span>
            </div>
            <div class="h-auto w-auto flex flex-col gap-2 justify-center">
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4974_58643)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.2191 -0.0234375C4.46723 -0.0234375 3.04785 1.39656 3.04785 3.14781V36.7984C3.04785 38.5503 4.46723 39.9697 6.2191 39.9697H34.8278C36.5785 39.9697 37.9991 38.5503 37.9991 36.7984V12.6703L26.1854 -0.0234375H6.2191Z"
                    fill="#F9CA06"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.0053 12.6964V13.3214H30.0059C30.0059 13.3214 26.0609 12.5339 26.1753 9.12891C26.1753 9.12891 26.3053 12.6964 29.9278 12.6964H38.0053Z"
                    fill="#F7BC04"
                  />
                  <path
                    opacity="0.5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26.1914 -0.0234375V9.07719C26.1914 10.1122 26.8814 12.6972 30.0064 12.6972H38.0058L26.1914 -0.0234375Z"
                    fill="white"
                  />
                  <path
                    d="M14.7265 26.9032H12.6784V33.1826C12.6784 33.4413 12.4759 33.6438 12.2059 33.6438C11.9471 33.6438 11.7446 33.4413 11.7446 33.1826V26.9032H9.70773C9.48273 26.9032 9.30273 26.7232 9.30273 26.4757C9.30273 26.2507 9.48273 26.0707 9.70773 26.0707H14.7265C14.9515 26.0707 15.1315 26.2507 15.1315 26.4988C15.1315 26.7232 14.9515 26.9032 14.7265 26.9032ZM22.039 33.6551C21.904 33.6551 21.7796 33.5995 21.7009 33.4863L19.3715 30.3801L17.0309 33.4863C16.9521 33.5995 16.8284 33.6551 16.6934 33.6551C16.4459 33.6551 16.2434 33.4638 16.2434 33.2051C16.2434 33.1151 16.2659 33.0138 16.3334 32.9351L18.764 29.7282L16.5021 26.712C16.4459 26.6332 16.4121 26.5432 16.4121 26.4532C16.4121 26.2388 16.5921 26.0026 16.8621 26.0026C16.9971 26.0026 17.1321 26.0701 17.2221 26.1826L19.3715 29.0745L21.5209 26.1707C21.5996 26.0582 21.7234 26.002 21.859 26.002C22.0952 26.002 22.3202 26.1932 22.3202 26.4526C22.3202 26.5426 22.2977 26.6326 22.2415 26.7113L19.9796 29.7163L22.4109 32.9345C22.4671 33.0132 22.4896 33.1032 22.4896 33.1938C22.4896 33.4413 22.2865 33.6551 22.039 33.6551ZM29.0359 26.9032H26.9877V33.1826C26.9877 33.4413 26.7859 33.6438 26.5152 33.6438C26.2565 33.6438 26.054 33.4413 26.054 33.1826V26.9032H24.0171C23.7921 26.9032 23.6121 26.7232 23.6121 26.4757C23.6121 26.2507 23.7921 26.0707 24.0171 26.0707H29.0359C29.2609 26.0707 29.4409 26.2507 29.4409 26.4988C29.4415 26.7232 29.2615 26.9032 29.0359 26.9032Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4974_58643">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="h-auto w-[80px] -!border">
              <span
                class="flex justify-center -bg-black"
                :class="fileType == 'txt' ? 'text-[#41901C]' : ''"
                >Plain Text</span
              >
            </div>
          </div>
        </div>

      </div>

      <!-- Footer -->

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3 dark:bg-[#1A1A1A]"
      >
        <ModalFooter
          firstTitle="Cancel"
          secondTitle="Download"
          colorType="color"
          :isLoading="isLoading"
          @close="closeDownloadModal"
          @save="downloadSelectedWatchlistSymbols(fileType)"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'
export default {
  name: 'DownloadSymbolModal',

  data() {
    return {
      inputValue: '',
      selectedAlphabet: { key: 'a-d', value: 'A-D' },
      selectedVal: { key: 'all', value: 'All' },
      screenWidth: window.innerWidth,
      tickerInfo: [],
      symbolAdded: false,
      isDownloadTxt: false,
      isDownloadCsv: false,
      symbolStringData: [],
      symbolStringNames: [],
      fileType: 'csv',
    }
  },

  components: {
    ModalHeader,
    ModalFooter,
  },

  computed: {
    ...mapGetters(['getSelectedWatchlist']),
    // ...mapGetters(['getTheme']),
    // filteredContent() {
    //   return this.tickerInfo?.filter((item) =>
    //     item?.ticker?.toLowerCase().includes(this.inputValue?.toLowerCase())
    //   )
    // },
  },

  methods: {
    // ...mapActions(['removedWatchlist']),

    closeDownloadModal() {
      this.$emit('close-Download-Modal')

      console.log('closeModal', this.symbolData)
    },

    downloadCsvFile() {
      this.fileType = 'csv'
      console.log('fileType', this.fileType)
    },

    downloadTxtFile() {
      this.fileType = 'txt'
      console.log('fileType', this.fileType)
    },
    downloadSelectedWatchlistSymbols(type) {
      if (!this.getSelectedWatchlist.symbolList) {
        alert('Please select an item.')
        console.log(
          'Please select an item.',
          this.getSelectedWatchlist.symbolLisst
        )
        return
      }

      let content = ''
      let fileName = ''

      if (type === 'csv') {
        // Prepare CSV content
        content = `Name\n${this.getSelectedWatchlist.symbolList
          .map((item) => item.name)
          .join('\n')}\n`
        fileName = `${this.getSelectedWatchlist.name}.csv`
      } else if (type === 'txt') {
        // Prepare plain text content
        content = `${this.getSelectedWatchlist.symbolList
          .map((item) => item.name)
          .join('\n')}\n`
        fileName = this.getSelectedWatchlist.name
      }

      // Create a blob with the appropriate content and download it
      console.log('content txt', this.getSelectedWatchlist.symbolList)

      const blob = new Blob([content], { type: 'text/plain;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  props: {
    symbolData: Object,
  },
  watch: {},

  mounted() {
    console.log('SymbolData', this.symbolData)
    this.symbolStringData = this.symbolData.symbols
    console.log('symbolStringData', this.symbolStringData)
  },
  beforeDestroy() {},
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}

.scroll-container {
  /* Adjust height as needed */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>
