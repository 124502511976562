<template>
  <div class="w-full flex flex-row justify-center items-center">
    <div v-if="showTitle" class="w-[15%]">230 stocks found.</div>
    <div class="h-9 relative !bg-[#E7E7E7] rounded-sm w-full">
      <div class="h-full bg-[#32D58E] rounded-sm" :style="progressStyle">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {{ stockMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgrogessiveBar',
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    stockMessage:{
      type: String,
      default: ""
    }
  },
  computed: {
    progressStyle(){
      return {
        width: `${String(this.percentage) + '%'}`
      }
    }
  },
  methods: {
  },
  watch: {
    percentage(val) {
      console.log('progressiveBar Percentage==>', val)
    },
    style(val){
        console.log("val==>",val);
    }
  },
}
</script>

<style >
</style>