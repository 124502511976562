var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-[25rem] w-[40rem] flex flex-col"},[_c('div',{staticClass:"h-[15%] p-4 w-full border flex flex-row justify-between border-b-4"},[_c('modal-header',{attrs:{"header":'Update Profile Settings'},on:{"close":_vm.closeHandler}})],1),_c('div',{staticClass:"flex flex-1 flex-col gap-3 p-4 w-full h-65%"},[_c('label',[_vm._v(_vm._s(_vm.isPasswordVerfied ? ' Enter your new email address' : 'Please verify your password'))]),(_vm.isPasswordVerfied)?_c('div',{staticClass:"inputHeight space-y-10",staticStyle:{"height":"25%","width":"60%"}},[(_vm.isPasswordVerfied)?_c('DynamicInput',{attrs:{"type":"text","placeholder":"Enter a new email","value":_vm.email,"isError":_vm.isEmailError,"iconImg":require(`@/assets/${_vm.isEmailError ? 'redEmail.svg' : 'email.svg'}`),"inputColor":'bg-[#f6f6f6]',"imageColor":'bg[#f6f6f6]'},on:{"enter-pressed":_vm.onSubmitHandler},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e(),_c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"w-full h-full"},[(_vm.emailVerified)?_c('DynamicInput',{attrs:{"type":"text","placeholder":"Enter 6-digit OTP code","value":_vm.userOtp,"isError":_vm.isPasswordError,"iconImg":require(`@/assets/${
                _vm.isEmailError ? 'redEmail.svg' : 'email.svg'
              }`),"inputColor":'bg-[#f6f6f6]',"imageColor":'bg[#f6f6f6]'},on:{"enter-pressed":_vm.onSubmitHandler},model:{value:(_vm.userOtp),callback:function ($$v) {_vm.userOtp=$$v},expression:"userOtp"}}):_vm._e(),(_vm.otpErrorMessage)?_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"text-[13px]"},[_vm._v(_vm._s(_vm.otpMessage))])]):_vm._e()],1)])],1):_vm._e(),(!_vm.isPasswordVerfied)?_c('div',{staticClass:"inputHeight",staticStyle:{"height":"25%","width":"60%"}},[_c('DynamicInput',{attrs:{"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Password","value":_vm.password,"isError":_vm.isPasswordError,"iconImg":require(`@/assets/${
            _vm.isPasswordError
              ? 'redEye.svg'
              : _vm.showPassword
              ? 'openEye.svg'
              : 'closeEye.svg'
          }`),"inputColor":'bg-[#f6f6f6]',"imageColor":'bg[#f6f6f6]'},on:{"password-visibility-toggled":_vm.handlePasswordVisibilityToggle,"enter-pressed":_vm.onSubmitHandler},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e(),(_vm.passwordErrorMsg)?_c('div',{staticClass:"-mt-3",staticStyle:{"height":"2%"}},[_c('span',{staticClass:"errorText text-errorText text-[13px]"},[_vm._v(_vm._s(_vm.showWarningIcon ? '⚠' : '')+" "+_vm._s(_vm.passwordErrorMsg))])]):_vm._e(),(_vm.isError && !_vm.passwordErrorMsg)?_c('div',{staticClass:"-mt-3"},[_c('span',{staticClass:"errorText text-errorText text-[13px]"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()]),_c('div',{staticClass:"p-4 h-[20%] border border-t-1 w-full flex gap-2 flex-row justify-end"},[_c('ModalFooter',{attrs:{"firstTitle":"Close","secondTitle":"Save","colorType":"color","isLoading":_vm.isLoading},on:{"close":_vm.closeHandler,"save":_vm.onSubmitHandler}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }