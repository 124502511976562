<template>
  <div class="w-full h-full">
    <div class="w-full -bg-green-300">
      <RightBarHead
        :mainHeading="'Training Mode'"
        :subHeading="selectedCandle"
        :selectedRight="selectedRight"
        :disablePlayContainer="isTrainingMode"
        :disableNext="disableNext"
        :disablePrev="disablePrev"
        :togglePlay="getTogglePlay"
        @back="handleBack"
        @play="handlePlay"
        @next="handleNext"
        @refresh="handleRefresh"
        @openTrainingModal="handleOpenTrainingModal"
      />
    </div>
    <div class="w-full h-full">
      <TrainingModeData :data="ohlcvData" />
    </div>

    <div v-if="trainingModal">
      <BaseModal>
        <TrainingModeModal @close="handleCloseTrainingModal" />
      </BaseModal>
    </div>
  </div>
</template>
<script>
import RightBarHead from '../../RightBarHead.vue'
import TrainingModeData from './TrainingModeData.vue'
import {mapGetters,mapActions} from 'vuex'
import BaseModal from '../../BaseModal'
import TrainingModeModal from '../../dialogBoxes/TrainingMode'

import moment from 'moment'
export default {
  name: 'TrainingMode',
  data() {
    return {
      disableNext:false,
      disablePrev:false,
      intervalId: null,
      trainingModal:false,
    }
  },
  components: {
    RightBarHead,
    TrainingModeData,
    BaseModal,
    TrainingModeModal
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
  },
  methods: {
    ...mapActions(['updateTrainingModeState','updateTogglePlay']),
    handleBack(){
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index - 1,
          chartHit: false,
        })
      } else {
        this.candleIndex--
      }
    },
    handlePlay(){
      console.log("play from right",this.traningModeIndex)
       this.updateTogglePlay(this.traningModeIndex)
    },

    handleOpenTrainingModal(){
      this.trainingModal = true
    },  
   
    handleCloseTrainingModal(){
      this.trainingModal = false
    },  
   


    handleNext(){
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index + 1,
          chartHit: false,
        })
      } else {
        this.candleIndex++
      }
    },
    setTimeStampToSplitter(timeStamp, indexBased = false) {
      if (indexBased) {
        const chartData = this.dc.get_one(`chart.data`)
        // debugger
        if (chartData.length && chartData.length >= timeStamp) {
          let candleData = chartData[timeStamp]
          if (candleData && candleData[0]) {
            timeStamp = candleData[0]
          }
        } else {
          return
        }
      }
      const splitters = this.dc.get(`onchart.Splitters`)
      if (splitters) {
        splitters.forEach((splitter) => {
          // console.log("splitter.name",splitter.name)
          if (splitter.name === 'CurrentCandle') {
            return this.dc.set(`${splitter.id}.data`, [[timeStamp]])
          }
        })
      }
    },
    handleRefresh(){
      if (this.isTrainingMode) {
        return this.updateTrainingModeState({
          state: !this.isTrainingMode,
        })
      }
    }
  },
  computed: {
       ...mapGetters([ 'trainingModeData','trainingModeDataRaw','dc','isTrainingMode','formattedSymbolData','trainingModeStartingIndex', 'candleIndexJumpSelected','trainingOffset', 'candleIndexSelected','trainingModeStartingIndexSubset',  'getSymbolData','getTogglePlay']),
       candleIndex: {
      get() {
        return this.candleIndexSelected?.index
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },
    ohlcvData() {
      return [
        {
          left: 'Open',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.open?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.open?.toFixed(3),
        },
        {
          left: 'High',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.high?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.high?.toFixed(3),
        },
        {
          left: 'Low',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.low?.toFixed(3)
            : this.formattedSymbolData?.[this.candleIndex]?.low?.toFixed(3),
        },
        {
          left: 'Close',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.close?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.close?.toFixed(3),
        },
        {
          left: 'Volume',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.volume?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.volume?.toLocaleString(),
        },
        {
          left: 'Value',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.value?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.value?.toLocaleString(),
        },
        {
          left: 'VWAP',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.vwap?.toLocaleString()
            : this.formattedSymbolData?.[this.candleIndex]?.vwap?.toFixed(3),
        },
      ]
    },
    traningModeIndex: {
      get() {
        console.log("training",(
          this.trainingModeStartingIndex +
          (this.candleIndexJumpSelected?.index - this.trainingOffset)
        ))
        return (
          this.trainingModeStartingIndex +
          (this.candleIndexJumpSelected?.index - this.trainingOffset)
        )
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },
    maxLength() {
      return this.formattedSymbolData?.length
    },

    selectedCandle() {

      return this.isTrainingMode
        ? moment(
            this.formattedSymbolData?.[this.traningModeIndex]?.date
          ).format('Do MMM YYYY')
        : moment(this.formattedSymbolData?.[this.candleIndex]?.date).format(
            'Do MMM YYYY'
          )

    },

    
  },

  watch:{
    
    traningModeIndex(val){
      if(this.isTrainingMode){
        if(val === 0){
          this.disablePrev = true
        }else{
          this.disablePrev = false
        }
      }

      if(val >= this.maxLength - 1){
        this.disableNext = true
      }else{
        this.disableNext = false
      }
    },
    getTogglePlay(val){
      if(val){
        this.disablePrev = true
        this.disableNext = true
      }else{
        this.disablePrev = false
        this.disableNext = false
      }
    },
    maxLength(newValue, oldValue) {
      if (!oldValue && newValue && !this.candleIndex) {
        this.candleIndex = newValue - 1
      }
    },
    async candleIndexSelected(newValue, oldValue) {
      if (!oldValue) return

      if (newValue?.index < this.getSymbolData?.length) {
        const timeFrame = this.getSymbolData[newValue?.index][0]
        this.setTimeStampToSplitter(timeFrame)
      }
    },

    formattedSymbolData(newValue) {
      if (newValue?.length > 0 && this.maxLength) {
        console.log("formated change")
        this.candleIndex = this.maxLength - 1
        const splitters = this.dc.get('onchart.Splitters')
        if (splitters) {
          splitters?.forEach((splitter) => {
            if (splitter.name === 'CurrentCandle') {
              return this.dc.set(`${splitter.id}.data`, [
                [
                  moment(
                    this.formattedSymbolData?.[this.candleIndex]?.date
                  ).valueOf(),
                ],
              ])
            }
          })
        }
      }
    },

    isTrainingMode() {
      console.log("rightbar training mode",this.isTrainingMode)
      if (this.isTrainingMode) {
        this.traningModeIndex = this.trainingModeStartingIndexSubset
      } else {
        this.candleIndex = this.formattedSymbolData.length - 1
      }
    },

    async candleIndexJumpSelected(newValue, oldValue) {
      console.log('SET_CANDLE_INDEX traningModeIndex', newValue, oldValue)
      if (!oldValue || !this.isTrainingMode) return
      const formattedSymbolData = this.formattedSymbolData
      const symbolData = this.getSymbolData
      let trainingModeStartingIndex = this.trainingModeStartingIndex
      const trainingOffset = this.trainingOffset
      let selectedIndex = newValue?.index
      let selectedIndexOld = oldValue?.index
      const selectedTime = newValue?.time
      let trainingModeDataRaw = JSON.parse(
        JSON.stringify(this.trainingModeDataRaw)
      )
      if (selectedIndex <= trainingOffset) {
        if (selectedTime) {
          // When we are using chart to change data index
          this.setTimeStampToSplitter(selectedTime)
        } else {
          // when we are using arrow button to change data index
          if (selectedIndex >= 0) {
            const timeFrame = trainingModeDataRaw[selectedIndex][0]
            this.setTimeStampToSplitter(timeFrame)
          }
        }
        return
      }
      const selectedFromChart = newValue?.chartHit
      let endPoint = 0
      if (selectedFromChart) {
        let index = 0
        //   find index

        for (const dt of this.getSymbolData) {
          if (dt[0] > selectedTime) break
          index++
        }
        endPoint = index
      } else {
        endPoint = trainingModeStartingIndex + (selectedIndex - trainingOffset)
      }

      const maximumIndex = symbolData.length
      console.log(
        'trainingModeIndex',
        JSON.stringify({
          selectedIndex,
          selectedIndexOld,
          selectedTime,
          endPoint,
          maximumIndex,
          trainingModeStartingIndex,
          trainingOffset,
        })
      )
      if (endPoint <= maximumIndex && this.isTrainingMode) {
        const trainingModeData = this.trainingModeData
        const oldIndexStart = selectedIndexOld + 1
        let startPoint =
          trainingModeStartingIndex + (oldIndexStart - trainingOffset)
        if (selectedIndex > selectedIndexOld) {
          //this.getSymbolData[newValue]

          if (selectedFromChart) {
            let sliceData = symbolData.slice(startPoint, endPoint)
            let slicedDataIndex = 0
            for (let i = oldIndexStart; i <= selectedIndex; i++) {
              trainingModeDataRaw[i] = sliceData[slicedDataIndex]
              slicedDataIndex++
            }
            console.log(
              JSON.stringify({ sliceData, oldIndexStart, selectedIndex })
            )
          } else {
            trainingModeDataRaw[selectedIndex] = symbolData[startPoint]
          }
          trainingModeDataRaw = trainingModeDataRaw.map((candle) => [
            candle[0],
            candle[1] ? candle[1] : NaN,
            candle[2] ? candle[2] : NaN,
            candle[3] ? candle[3] : NaN,
            candle[4] ? candle[4] : NaN,
            candle[5] ? candle[5] : NaN,
          ])
       
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
        } else if (selectedIndex < selectedIndexOld) {
          for (let i = trainingModeDataRaw.length - 1; i > selectedIndex; i--) {
            trainingModeDataRaw[i] = [
              trainingModeDataRaw[i][0],
              NaN,
              NaN,
              NaN,
              NaN,
              NaN,
            ]
          }
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
          // this.dc.data.chart.data.pop()
        }
        if (selectedTime) this.setTimeStampToSplitter(selectedTime)
        else {
          const timeFrame = trainingModeDataRaw[selectedIndex][0]
          this.setTimeStampToSplitter(timeFrame)
        }
      }
    
    },

    }
}
</script>
<style scoped></style>
