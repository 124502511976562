<template>
  <div>
    <CusrtomizePatternWrapper
      :headingName="'Measures'"
      :tag="'Financial '"
      :detail="'Set financial performance criteria to evaluate earnings,'"
      :highlighted="'valuation, and dividend yields.'"
    >
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Earnings Yield</div>
            <div
              class="flex xxs:flex-col md:flex-row xxs:items-start gap-2 md:items-center md:space-x-3 w-full"
            >
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Specify earnings yield:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <select
                    type="select"
                    style="width: 150px; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.earningYield.type"
                  >
                    <option
                      v-for="earn in earnigYeilds"
                      :key="earn.value"
                      :value="earn.value"
                    >
                      {{ earn.text }}
                    </option>
                  </select>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.earningYield.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]">%</span>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on their earnings ${settings.earningYield.type} yield relative to their market price ${settings.earningYield.value}.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('earningYield', 'isActive')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('earningYield', 'isActive') ? '#1C274C' : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>

      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">P/E Ratio</div>
            <div
              class="flex xxs:flex-col md:flex-row xxs:items-start gap-2 md:items-center md:space-x-3 w-full"
            >
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set the P/E ratio threshold:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <select
                    type="select"
                    style="width: 150px; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.peRatio.type"
                  >
                    <option
                      v-for="ratio in ratioOption"
                      :key="ratio.value"
                      :value="ratio.value"
                    >
                      {{ ratio.text }}
                    </option>
                  </select>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.peRatio.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]">%</span>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="` Select companies with a price-to-earnings ${settings.peRatio.type} ratio ${settings.peRatio.value} within your specified range.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('peRatio', 'isActive')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('peRatio', 'isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>

      <!-- Third one input... -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Dividend Yield</div>
            <div
              class="flex xxs:flex-col md:flex-row xxs:items-start gap-2 md:items-center md:space-x-3 w-full"
            >
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set the P/E ratio threshold:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <select
                    type="select"
                    style="width: 150px; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.dividendYield.type"
                  >
                    <option
                      v-for="ratio in dividendYieldOption"
                      :key="ratio.value"
                      :value="ratio.value"
                    >
                      {{ ratio.text }}
                    </option>
                  </select>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.dividendYield.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]">%</span>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="` Select companies with a ${settings.dividendYield.type} price-to-earnings ratio ${settings.dividendYield.value} within your specified range.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('dividendYield', 'isActive')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('dividendYield', 'isActive') ? '#1C274C' : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>

      <!-- Fourth One Input -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-7 w-full">
            <div class="text-bold font-[700] text-[15px]">Franking Credits</div>
            <div
              class="flex xxs:flex-col md:flex-row gap-3 md:items-center md:space-x-3 w-full"
            >
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Specify franking credits requirement:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  type="radio"
                  style="width: 20px; height: 20px"
                  id="WFC_only100"
                  name="franking-type"
                  value="100"
                  v-model="settings.frankedPct.type"
                />
                <label for="WFC_only100" :style="{ cursor: 'pointer' }"
                  >100% only</label
                >
              </div>

              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  style="width: 20px; height: 20px"
                  :style="{ cursor: 'pointer' }"
                  id="WFC_anycredit"
                  type="radio"
                  name="franking-type"
                  value="any"
                  v-model="settings.frankedPct.type"
                />
                <label for="WFC_anycredit" :style="{ cursor: 'pointer' }"
                  >any credit</label
                >
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Choose whether to include only stocks with 100% franking credits ${settings.frankedPct.type} or any level of credits.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('frankedPct', 'isActive')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('frankedPct', 'isActive') ? '#1C274C' : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
  </div>
</template>
  
<script>
const DEFAULT_SETTINGS = {
  earningYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  peRatio: {
    isActive: false,
    type: 'more',
    value: 20,
  },
  dividendYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  frankedPct: {
    isActive: false,
    type: '100',
  },
  capitalisation: {
    topSymbols: {
      isActive: false,
      value: 20,
    },
    sharesIssued: {
      isActive: false,
      type: 'more',
      value: 5,
    },
    marketCap: {
      isActive: false,
      type: 'more',
      value: 5,
    },
  },
  returns: {
    isActive: false,
    type: 'more',
    value: 100,
    month: 'return12m',
  },
}
import CusrtomizePatternWrapper from '../../CusrtomizePatternWrapper.vue'
import DescriptionInput from '../../DescriptionInput.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { DescriptionInput, CusrtomizePatternWrapper },
  name: 'Measures',
  data() {
    return {
      // settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      id: 'fundementals',
      dividendYieldOption: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
      earnigYeilds: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
      ratioOption: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
    }
  },
  props: {},
  mounted() {},
  methods: {
    ...mapActions(['setFundementalsScanSettings']),
    isHasTrue() {
      const fundamental = this.getFundementalSetting
      for (let key in fundamental) {
        if (key !== 'capitalisation' && key !== 'returns') {
          // If isActive is found and is true, return true
          if (fundamental[key].isActive) {
            return true
          }
        }
      }
      return false
    },
    isActiveHandler(key, key2) {
      const val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
      const re = this.isHasTrue()
      this.$emit('measures',re)
    },
    setSetting(key1, key2, val) {
      const filters = this.getFundementalSetting
      if (key1 == '') {
        filters[key1] = val
      } else if (key1 == '') {
        filters[key1] = val
      } else {
        filters[key1][key2] = val
      }
      this.setFundementalsScanSettings(filters)
    },
    getActive(key1, key2) {
      const filters = this.getFundementalSetting
      if (key1) {
        return filters[key1][key2]
      }
    },
  },
  computed: {
    ...mapGetters(['getFundementalSetting']),
    settings: {
      get() {
        return this.getFundementalSetting
      },
      set(value) {
        this.setFundementalsScanSettings(value)
      },
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    // settings: {
    //   handler: function (newSettings) {
    //     this.setFundementalsScanSettings(newSettings)
    //   },
    //   deep: true,
    // },
  },
}
</script>
<style>
</style>  