<template>
    <div class="w-full space-y-10 ">
        <div class="w-full h-full -bg-slate-400" v-for="(item,i) in data" :key="i">
            <div class="flex items-center space-x-2">
                <span class="text-[#454545] uppercase font-[700] text-[16px]">
                    <span :class="item.tag == 'Bullish' ? 'text-[rgb(65,144,28)]' : 'text-[#f20e1c]'">{{ item.tag }}</span>
                    {{ item.name }}
                </span>
            </div>
            <div v-if="getShowDescription" class="text-[13px] font-[500] mb-3 text-[#5C5C5C]">
                {{ item.detail  }}
                <span class="font-[700]" >{{ item.highlighted }}</span>
            </div>
            <div class="w-auto h-auto px-3 py-2 border rounded-[6px] mt-1">
                <div class="w-full h-auto space-y-2">
                    <div v-for="(val,index) in item?.group" @click="handleClick(val?.key)" :key="index" :class="settingData[val?.key] ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'" class="flex  cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full" >
                        <div class="flex items-center space-x-4">
                            <div v-html="getSvg(val?.icon)"></div>
                            <div class="space-y-1" >
                                <div class="flex items-center space-x-3" >
                                    <span class="text-[#001A2C] font-[700] text-[15px]">{{ val?.name }}</span>
                                </div>
                                <div v-if="getShowDescription" class="text-[#5C5C5C] font-[400] text-[13px]" >
                                    {{ val?.desc }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z" :fill="settingData[val?.key] ?'#1C274C':'#E2E2E2'"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSvgByName } from '../assets/leftsidebarsvgs/Utils/SvgUtils'
import {mapGetters} from 'vuex'

export default {
    name:'PatternWrapper',
    props:{
        data:Array,
        settingData:Object
    },
    methods:{
        getSvg(name) {
            return getSvgByName(name)
        },
        handleClick(val){
            this.$emit('onPress',val)
        },
    },
    computed:{
        ...mapGetters(['getShowDescription'])
    }
}

</script>