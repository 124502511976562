<template>
  <div class="flex justify-start items-center w-full h-full space-x-2 mt-3">
    <span @click="$emit('Info-Emit')">
      <svg width="20" height="20" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99992 12.3327C3.77826 12.3327 1.16659 9.72101 1.16659 6.49935C1.16659 3.27769 3.77826 0.666016 6.99992 0.666016C10.2216 0.666016 12.8333 3.27769 12.8333 6.49935C12.8333 9.72101 10.2216 12.3327 6.99992 12.3327Z" stroke="#1C274C" stroke-width="0.5"/>
          <path d="M7 9.41602V5.91602" stroke="#1C274C" stroke-width="0.5" stroke-linecap="round"/>
          <path d="M6.99992 3.58333C6.67775 3.58333 6.41659 3.8445 6.41659 4.16667C6.41659 4.48883 6.67775 4.75 6.99992 4.75C7.32208 4.75 7.58325 4.48883 7.58325 4.16667C7.58325 3.8445 7.32208 3.58333 6.99992 3.58333Z" fill="#1C274C"/>
      </svg>
    </span>
    <span class="text-[#5c5c5c] font-[500] text-[13px]">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'DescriptionInput',
  props: {
    description: {
      type: String,
      default: '',
    },
    backgroundTrue:{
      type: Boolean,
      default: false
    }
  },
}
</script>

<style></style>
