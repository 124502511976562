import http from './http'
import store from '../store/index'
const { CancelToken } = http
let cancel
const scansUrlBase = 'scans/'
/**
 * Send the Darvas Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 */
export async function darvasScan(
  settings,
  filterSettings,
  dataSettings,
  userId
) {
  const payload = {
    scanType: 'darvasScan',
    filterSettings,
    dataSettings,
    settings,
    userId,
    clientToken: userId
  }
  handleSaveScanInLocalStorage(payload)
  return http.post(
    scansUrlBase + 'darvasScan',
    {
      scanType: 'darvasScan',
      filterSettings,
      dataSettings,
      settings,
      userId,
      clientToken: userId
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

// For Favourite SCan Popup

/**
 * Save the Darvas Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 * @param scanName : String
 */
export async function saveDarvasScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'darvasScan',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  return http.post(
    scansUrlBase + 'saveDarvasScan',
    {
      savedSettings,
      scanName,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function saveSamsPatternScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'samsPattern',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  return http.post(
    scansUrlBase + 'saveSamsPatternScan',
    {
      savedSettings,
      scanName,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Send the Fundementals Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 */
export async function fundementalScan(
  settings,
  filterSettings,
  dataSettings,
  userId
) {
  //console.log('dataSettings ==', dataSettings)

  const payload = {
    scanType: 'fundementals',
    filterSettings,
    dataSettings,
    settings,
    userId,
    clientToken: userId,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    scansUrlBase + 'fundementalScan',
    {
      scanType: 'fundementals',
      filterSettings,
      dataSettings,
      settings,
      userId,
      clientToken: userId,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Save the Fundementals Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 * @param scanName : String
 */
export async function saveFundementalScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'fundementals',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  //console.log('dataSettings ==', dataSettings)
  return http.post(
    scansUrlBase + 'saveFundementalScan',
    {
      scanName,
      savedSettings,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Send the Activity Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 */
export async function activityScan(
  settings,
  filterSettings,
  dataSettings,
  userId
) {
  const payload = {
    scanType: 'activity',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    scansUrlBase + 'activityScan',
    {
      scanType: 'activity',
      filterSettings,
      dataSettings,
      settings,
      userId,
      clientToken: userId,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

// For Favourite Scan Popup

/**
 * Save the Activity Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ scanType: String, scanPeriod: Number, topConfirmationPeriod: Number, bottomConfirmationPeriod: Number, percentageRiskBuffer, limitTotalRisk: Number, volumeIncreasedOver: { percent: Number, previousBars: Number }, inBox: String, within: { type: String, pricePoints: Number, restrictBoxToMaxBox: Number, restrictCurrentBoxWidth: {start: Number, end: Number}, onlyShowBoxOfTopBottomOnFirstBar: Boolean } }} settings
 * @param userId : String
 * @param scanName : String
 */
export async function saveActivityScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'activity',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  return http.post(
    scansUrlBase + 'saveActivityScan',
    {
      savedSettings,
      scanName,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Send the Candlestick Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param userId : String
 */
export async function candlestickScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  clientToken,
) {
  const payload = {
    scanType: 'candlesticks',
    filterSettings,
    dataSettings,
    settings,
    userId,
    clintToken: userId,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    scansUrlBase + 'candlestickScan', 
     
    {
      scanType: 'candlesticks',
      filterSettings,
      dataSettings,
      settings,
      userId,
      clientToken: userId,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

//  For Favourite Scan Popup

/**
 * Save the Candlestick Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param userId : String
 * @param scanName : String
 */
export async function saveCandlestickScan(
  settings,
  filterSettings,
  dataSettings,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'candlesticks',
    filterSettings,
    dataSettings,
    settings,
    userId,
  }
  return http.post(
    scansUrlBase + 'saveCandlestickScan',
    {
      savedSettings,
      scanName,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Send the Chart pattern Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param events
 * @param userId : String
 */

export async function patternsScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'patterns',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    settings,
    events,
  }
  handleSaveScanInLocalStorage(payload)
  http.setJwt()
  return http.post(
    scansUrlBase + 'patternsScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

export async function runProBreakout(
  // settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'runProBreakout',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    clientToken: userId,
    // settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'runProBreakout',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function proDivergenceScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'proDivergenceScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proDivergenceScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function proDownTradeScan(
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'proDownTradeScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    // settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proDownTradeScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function proTradeScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'proTradeScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proTradeScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function proSwingUpScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'proSwingUpScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proSwingUpScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}
export async function proSwingDownScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId
) {

  const payload = {
    scanType: 'proSwingDownScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings,
    settings: {},
    events,
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proSwingDownScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

export async function proReversalScan(
  dataSettings,
  filterSettings,
  userId
) {

  const payload = {
    scanType: 'proReversalScan',
    userId: userId,
    clientToken: userId,
    filterSettings,
    dataSettings
  }
  handleSaveScanInLocalStorage(payload)

  return http.post(
    'advanceScans/' + 'proReversalScan',
      payload,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}



//  For Favourite Scan Popup

/**
 * Send the Chart pattern Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param events
 * @param userId : String
 * @param scanName : String
 */
export async function savePatternsScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'patterns',
    userId,
    filterSettings,
    dataSettings,
    settings,
    events,
  }
  //console.log("scanName:",scanName);
  return http.post(
    scansUrlBase + 'savePatternsScan',
    {
      savedSettings,
      scanName,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
  )
}

/**
 * Send the Indicators Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param events
 * @param userId : String
 */
export async function indicatorsScan(settings,filterSettings, dataSettings,events,userId) {
  let options = {
    timeout: 120000,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c
    }),
  }
  //console.log(' options axios --- ', options)
  const payload = {
    scanType: 'indicators',
    userId,
    filterSettings,
    dataSettings,
    settings,
    events,
  }
  handleSaveScanInLocalStorage(payload)
  return http.post(
    scansUrlBase + 'indicatorsScan',
    {
      scanType: 'indicators',
      userId,
      clientToken: userId,
      filterSettings,
      dataSettings,
      settings,
      events,
    },
    options
  )
}

//  For Favourite Scan Popup

/**
 * Send the Indicators Scan request to the API
 * @param filterSettings
 * @param {{ timeFrame: String, type: String, filterBy: String, sector: String, industry: String, subIndustry: String }} dataSettings
 * @param {{ patternName: Boolean }} settings
 * @param events
 * @param userId : String
 * @param scanName : String
 */
export async function saveIndicatorsScan(
  settings,
  filterSettings,
  dataSettings,
  events,
  userId,
  scanName
) {
  let savedSettings = {
    scanType: 'indicators',
    userId,
    filterSettings,
    dataSettings,
    settings,
    events,
  }
  let options = {
    timeout: 120000,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c
    }),
  }
  //console.log(' options axios --- ', options)
  return http.post(
    scansUrlBase + 'saveIndicatorsScan',
    {
      savedSettings,
      scanName,
    },
    options
  )
}

// deleteScanSettings

function cancelScan() {
  cancel?.()
}

export function handleSaveScanInLocalStorage(value) {
  let scans = {}
  // console.log("value",value)
  const scanResult = localStorage.getItem('ScanRequests')
  // console.log("scanResult",scanResult)
  if (!scanResult) {
    scans[value.scanType] = value
    localStorage.setItem('ScanRequests', JSON.stringify(scans))
    console.log("scans if===>",scans);
  } else {
    scans = JSON.parse(scanResult)
    scans[value.scanType] = value
    localStorage.setItem('ScanRequests', JSON.stringify(scans))
    console.log("scans else===>",scans);
  }
  store.dispatch("updateScansSettingForLatestScans",value)

}

export async function deleteScanId(userId, scanId) {
  const token = localStorage.getItem('state')
  try {
    const { data } = await http.get(
      `${scansUrlBase}deleteScanSettings?userId=${userId}&scanId=${scanId}`
    )

    return data
  } catch (e) {
    // alert(e)
    // logout()
    return null
  }
}

export async function updateScanName(payload) {
  try {
    const { data } = await http.put(`${scansUrlBase}editScanName`, payload)

    return data
  } catch (e) {
    // alert(e)
    // logout()
    return null
  }
}

export async function getScanList(userId) {
  const token = localStorage.getItem('state')
  try {
    const { data } = await http.get(`/users/scanList/${userId}`)

    return data
  } catch (e) {
    // alert(e)
    // logout()
    return null
  }
}
export default {
  updateScanName,
  darvasScan,
  fundementalScan,
  activityScan,
  candlestickScan,
  patternsScan,
  indicatorsScan,
  cancelScan,
  saveFundementalScan,
  saveActivityScan,
  saveCandlestickScan,
  savePatternsScan,
  saveIndicatorsScan,
  saveDarvasScan,
  saveSamsPatternScan,
  getScanList,
  deleteScanId,
  proReversalScan,
  proSwingUpScan,
  proTradeScan,
  proSwingDownScan,
  proDownTradeScan,
  proDivergenceScan,
  runProBreakout
}
