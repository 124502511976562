<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Events'">
      <events-filter @isValid="isValid"  :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Technical Indicators'">
      <IndicatorsComp @isIndicatorSetting="isIndicatorSetting" :id="id" />
    </div>
  </div>
</template>
    
    <script>
import EventsFilter from './EventsFilter.vue'
import Filters from './Filters.vue'
import IndicatorsComp from '../../IndicatorsComp.vue'

export default {
  name: 'Indicators',
  components: { Filters, EventsFilter, IndicatorsComp },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: 'indicators',
      dialogId: 'chartEvent',
    }
  },
  methods: {
    isValid(isValid){
      this.$emit('is_valid',isValid)
    },
    isIndicatorSetting(indicator){
      this.$emit("indicator",indicator)
      console.log("indicator==>",indicator);
    }
  },
  computed: {},
  mounted() {},
}
</script>
    
    <style>
</style>