<!-- BaseModal.vue -->

<template>
  <div class="h-[25rem] w-[40rem] flex flex-col">
    <!-- Header -->
    <div
      class="h-[15%] p-4 w-full border flex flex-row justify-between border-b-4"
    >
      <modal-header @close="closeHandler" :header="'Update Profile Settings'" />
    </div>
    <!-- Main content -->
    <div class="flex flex-1 flex-col gap-3 p-4 w-full h-65%">
      <label>{{
        isPasswordVerfied
          ? ' Enter your new email address'
          : 'Please verify your password'
      }}</label>

      <div
        v-if="isPasswordVerfied"
        class="inputHeight space-y-10"
        style="height: 25%; width: 60%"
      >
        <DynamicInput
          v-if="isPasswordVerfied"
          type="text"
          placeholder="Enter a new email"
          :value="email"
          :isError="isEmailError"
          :iconImg="
            require(`@/assets/${isEmailError ? 'redEmail.svg' : 'email.svg'}`)
          "
          v-model="email"
          @enter-pressed="onSubmitHandler"
          :inputColor="'bg-[#f6f6f6]'"
          :imageColor="'bg[#f6f6f6]'"
        />

        <div class="w-full h-full">
          <div class="w-full h-full">
            <DynamicInput
              v-if="emailVerified"
              type="text"
              placeholder="Enter 6-digit OTP code"
              :value="userOtp"
              :isError="isPasswordError"
              :iconImg="
                require(`@/assets/${
                  isEmailError ? 'redEmail.svg' : 'email.svg'
                }`)
              "
              v-model="userOtp"
              @enter-pressed="onSubmitHandler"
              :inputColor="'bg-[#f6f6f6]'"
              :imageColor="'bg[#f6f6f6]'"
            />
            <div v-if="otpErrorMessage" class="mt-2">
              <span class="text-[13px]">{{ otpMessage }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isPasswordVerfied"
        class="inputHeight"
        style="height: 25%; width: 60%"
      >
        <DynamicInput
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          :value="password"
          :isError="isPasswordError"
          :iconImg="
            require(`@/assets/${
              isPasswordError
                ? 'redEye.svg'
                : showPassword
                ? 'openEye.svg'
                : 'closeEye.svg'
            }`)
          "
          v-model="password"
          @password-visibility-toggled="handlePasswordVisibilityToggle"
          @enter-pressed="onSubmitHandler"
          :inputColor="'bg-[#f6f6f6]'"
          :imageColor="'bg[#f6f6f6]'"
        />
      </div>
      <!-- password error -->

      <div v-if="passwordErrorMsg" style="height: 2%" class="-mt-3">
        <span class="errorText text-errorText text-[13px]"
          >{{ showWarningIcon ? '⚠' : '' }} {{ passwordErrorMsg }}</span
        >
      </div>

      <!-- v-if="isError && !passwordErrorMsg" -->
      <div v-if="isError && !passwordErrorMsg" class="-mt-3">
        <span class="errorText text-errorText text-[13px]">{{
          errorMessage
        }}</span>
      </div>

      <!-- <div
        v-if="!isError && !passwordErrorMsg"
        style="height: 2%; margin-top: 1%"
        class="mb-3"
      >
        <span class="text-green-600 text-[13px]">{{ errorMessage }}</span>
      </div> -->
    </div>
    <!-- Button -->
    <div
      class="p-4 h-[20%] border border-t-1 w-full flex gap-2 flex-row justify-end"
    >
      <ModalFooter
        firstTitle="Close"
        secondTitle="Save"
        colorType="color"
        :isLoading="isLoading"
        @close="closeHandler"
        @save="onSubmitHandler"
      />
    </div>
  </div>
</template>
  <script>
import { mapGetters } from 'vuex'
import DynamicInput from './DynamicInput.vue'
import {
  VerifyPassword,
  SendOneTimePassword,
  verifyOTP,
} from '../services/user.js'

import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'
export default {
  name: 'PasswordVerify',
  data() {
    return {
      isPasswordError: false,
      showPassword: false,
      veruifyPassword: {
        password: '',
        userId: this.userId,
      },
      password: '',
      passwordErrorMsg: '',
      isError: false,
      errorMessage: 'errorMessage',
      isPasswordVerfied: false,
      email: '',
      isEmailError: false,
      emailVerified: false,
      userOtp: '',
      otpMessage: '',
      otpErrorMessage: false,
      emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      isLoading: false,
    }
  },
  props: {},
  components: { ModalHeader, DynamicInput, ModalFooter },
  computed: {
    ...mapGetters(['userSavedScans', 'getUserId']),
    errorSuccess() {
      return this.otpErrorMessage
        ? 'errorText text-errorText'
        : 'text-green-600'
    },
    userId() {
      return this.getUserId
    },
  },
  methods: {
    setErorrMethod(errorCheck, errorMessage) {
      this.isError = errorCheck
      this.errorMessage = errorMessage

      setTimeout(() => {
        this.errorMessage = ''
      }, 2000)
    },
    async handleVerifyOTP() {
      try {
        const response = await verifyOTP({
          userId: this.getUserId,
          email: this.email,
          otp: this.userOtp,
        })
        if (response.data.success) {
          console.log('handlerVerifyOTP======>', response)
          const token = response?.data?.data?.accessToken
          localStorage.setItem('state', token)
          location.reload()
          this.password = ''
          this.email = ''
          this.userOtp = ''
          this.passwordVerified = false
          this.emailVerified = false
          this.setErorrMethod(false, '')
        } else {
          this.setErorrMethod(true, response.data.msg)
        }
      } catch (err) {
        this.setErorrMethod(true, err.message)
      }
    },
    async handleSendOTP() {
      try {
        const response = await SendOneTimePassword({
          userId: this.getUserId,
          email: this.email,
        })
        if (response.data.success) {
          console.log('response===>', response)
          this.emailVerified = true
          const { msg } = response.data
          this.setErorrMethod(false, msg)
        } else {
          console.log('else block error')
          this.setErorrMethod(
            true,
            response.data.msg || 'Your OTP could not be generated'
          )
        }
      } catch (err) {
        console.log('error: ', err)
        this.setErorrMethod(true, err.message)
      }
    },
    async onSubmitHandler() {
      console.log('submit handler')
      if (!this.isPasswordVerfied) {
        if (!this.password) {
          this.isPasswordError = true
          this.passwordErrorMsg = '*Required Field'
          return
        }
        await this.handleVerifyPassword()
      } else if (this.isPasswordVerfied && !this.emailVerified) {
        console.log('second else if block')
        if (!this.emailRegix.test(this.email)) {
          // this.isPasswordError = true
          this.isEmailError = true
          this.passwordErrorMsg = '*Please enter the valid Email'
          return
        } else if (!this.email) {
          this.isEmailError = true
          this.passwordErrorMsg = '*Required Field'
        }
        await this.handleSendOTP()
      } else {
        if (!this.userOtp) {
          return
        }
        await this.handleVerifyOTP()
      }
    },
    async handleVerifyPassword() {
      if (!this.password) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        return
      }

      const payload = {
        userId: this.getUserId,
        password: this.password,
      }

      try {
        const response = await VerifyPassword(payload)
        if (response.data.success) {
          this.password = ''
          this.isPasswordVerfied = response.data.success
          this.setErorrMethod(
            false,
            'Password you entererd was currect, please wait.'
          )
        } else {
          this.setErorrMethod(true, 'Password you entererd was incorrect')
        }
      } catch (error) {
        this.setErorrMethod(true, err.message)
      }
    },
    closeHandler() {
      this.$emit('closeHandler')
    },
    handlePasswordVisibilityToggle() {
      this.showPassword = !this.showPassword
    },
  },
  watch: {
    veruifyPassword: {
      handler(value) {
        if (value) {
          this.isPasswordError = false
          this.passwordErrorMsg = ''
        }
      },
      deep: true,
    },
    password(value) {
      if (value) {
        this.isPasswordError = false
        this.passwordErrorMsg = ''
      }
    },
    email(val) {
      if (val) {
        this.isEmailError = false
        this.passwordErrorMsg = ''
      }
    },
  },
}
</script>
  
  <style scoped>
</style>
  