<template>

  <div
    class="xxs:w-[88vw] md:w-[60vw] h-[37vh] lg:w-[40vw] -px-3 space-y-1 bg-white !overflow-hidden"
  >
    <div
      class="w-full !h-[50px] !border-b border-[#D1D1D1] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <ModalHeader header="Training Mode" @close="closeModal" />
    </div>

    <div class="w-full h-[70%] px-4 py-3 scroll-container">
      <div class="w-full h-auto">
        Training mode is designed to simulate your trading procedures. Once
        training mode is on, the data displayed reverts to a date of your choice
        (we suggest going back approximately 200 days). You may then arrow
        forward day by day, thereby testing your trading skills. Using the
        cursor controls (left and right arrows) in the data panel (located to
        the right of the stock date, above the open/high/low/close data), the
        cursor may be moved to the right revealing each new days' data. Using
        the training mode you may safely 'practice trading', resulting in
        testing and improving your trading knowledge and trading plan without
        risking your capital. Select your desired starting date, and click OK to
        get started.

       
      </div>
      <br />
      <!-- <div class="w-full h-auto">
        <div class="flex items-center space-x-4">
          <label>Starting Date</label>
          <DatePicker
            v-model="startingDate"
            :placeholder="startingDate"
            type="date"
          ></DatePicker>
        </div>
      </div> -->
      <br />
      <b> Note:</b> The application will remain in training mode until you click
      the training mode refresh icon.
    </div>

    <div
      class="w-full !h-[50px] !border-t border-[#D1D1D1] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <ModalFooter
        firstTitle="Close"
        secondTitle="Save"
        :hideSave="true"
        colorType="color"
        @close="closeModal"
        @save="onPressOk"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment/moment'
import { FormatUnixDate } from '../../utils/candle'
import ModalHeader from '../ModalHeader.vue'
import ModalFooter from '../ModalFooter.vue'
export default {
  name: 'TrainingMode',
  components: {
    ModalHeader,
    ModalFooter,
  },
  model: {
    prop: 'open',
    event: 'change',
  },

  data() {
    return {
      startingDate: '2023-08-21',
      inputcolor: 'light',
    }
  },
  methods: {
    ...mapActions(['updateTrainingModeState', 'setNewChartType']),
    closeModal() {
      this.$emit('close')
    },
    async onPressOk() {
      const date = moment(this.startingDate)
      const formattedDate = date?.format('YYYY-MM-DD')
      const startingTime = new Date(formattedDate).getTime()
      const symbolDataLastIndex = this.getSymbolData.length - 1
      const symbolDatumElement = this.getSymbolData[symbolDataLastIndex][0]
      if (startingTime > symbolDatumElement) return

      let index = 0
      for (const dt of this.getSymbolData) {
        if (dt[0] > startingTime) break
        index++
      }

      const end = index + 1
      const trainingOffset = this.trainingOffset
      const trainingModeData = this.formattedSymbolData.slice(
        index - trainingOffset,
        end
      )

      const trainingModeDataRemaining = this.getSymbolData.slice(
        index + 1,
        this.getSymbolData.length
      )

      const trainingModeDataRaw = trainingModeData.map((candle) => [
        moment(candle.date).valueOf(),
        candle.open,
        candle.high,
        candle.low,
        candle.close,
        candle.volume,
        null,
        candle.value,
      ])
      const trainingModeDataRaw2 = trainingModeDataRemaining.map((candle) => [
        candle[0],
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ])
      const finalRawDataSet = [...trainingModeDataRaw, ...trainingModeDataRaw2]
      console.log(
        'last dataSlice',
        FormatUnixDate(finalRawDataSet[finalRawDataSet.length - 1][0]),
        finalRawDataSet.length,
        FormatUnixDate(this.getSymbolData[symbolDataLastIndex][0]),
        this.getSymbolData.length
      )

      console.log('training-mode', {
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })

      if (this.getChartType != 'Candles') {
        this.setNewChartType({ type: 'Candles', location: 'FromTrainingMode' })
      }

      this.updateTrainingModeState({
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })
      await this.$nextTick()
      this.closeModal()
    },
  },
  computed: {
    ...mapGetters([
      'isTrainingMode',
      'dc',
      'getSymbolData',
      'formattedSymbolData',
      'trainingOffset',
      'getChartType',
    ]),
    today() {
      return Date.now()
    },
  },
}
</script>

<style>
input {
  color-scheme: v-bind(inputcolor);
}
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
</style>
