import http from './http'
//import WatchesLoader from '../components/'

//const showloader = false
//console.log(showloader)

export  const showloaderjs = "booleannnn"


export async function saveWatches(watches) {
    try {
           //console.log("true")
        const res = await http.post(`/users/watchlist`,watches)
        //console.log(res)
        if (res.status === 200) {
            //console.log("false")
            return res.data.isError ?  false : res.data
        } else {
            return []
        }
    } catch (error) {      
        console.log(error)
        return []
    }
}

export const getWatchListData = async (userId) => {
    const token = localStorage.getItem('state')
    try{
        const {data} = await http.get(`/users/watchlist/${userId}`)
        // console.log(data)
        return data
    } catch(e) {
        // alert(e)
        // logout()
        return null
    }
}
export const deleteWatchList = async (id) => {
   
    const token = localStorage.getItem('state')
    try{
        //console.log("true")
        const {data} = await http.delete(`/users/watchlist/${id}`)
        //console.log(data)
        //console.log("false")
        return data  
    } catch(e) {
        // alert(e)
        // logout()
        return null
    }
}