<template>
   
    <div
      class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden"
    >
      <div class="w-full h-full text-[#000000] !overflow-x-hidden">
        <div class="w-full !h-[50px] -!bg-slate-500 px-3 flex flex-row justify-between items-center space-x-3">
          <ModalHeader
            :header="`Remove ${symbol} from ${data?.name}`"
            @close="closeRemoveModal"
          />
        </div>
  
        <div class="px-8 py-3 border-b border-t border-[#E2E8F0] w-full flex flex-col -justify-center min-h-[130px] -min-h-[158px]">
          <div class="h-full flex flex-col gap-2 justify-center">
            <span
              class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[16px] font-normal"
              >Are you sure you want to remove this ticker from your watchlist?
            </span>
            <span
              class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[16px] font-normal"
              >Don't worry, you can always add it back whenever you need.
            </span>
            <span v-if="showError" class="text-errorText text-[13px] font-normal"
              >{{ showError }}
            </span>
          </div>
        </div>
  
    
        <div class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3">
          <ModalFooter
            firstTitle="No, Keep this"
            secondTitle="Yes, Remove"
            colorType="color"
            :isLoading="isLoading"
            @close="closeRemoveModal"
            @save="saveRemovedSymbol"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import Loader from '../component/Loading.vue'
  import ModalHeader from './ModalHeader.vue'
  import ModalFooter from './ModalFooter.vue'
  
  export default {
    name: 'RemoveUnselectedWatchlistSymbol',
  
    data() {
      return {
        screenWidth: window.innerWidth,
      }
    },
  
    props: {
      data: Object,
      symbol:String,
      isLoading:Boolean,
      showError:String
    },
  
    components: {
      Loader,
      ModalFooter,
      ModalHeader,
    },
  
    computed: {
      ...mapGetters(['getTheme', 'getSelectedWatchlist']),
    },
  
    methods: {
      ...mapActions(['removeCurrentSymbolFromList']),
  
      saveRemovedSymbol() {
        this.$emit('save')
        // this.isLoading = true
        // let res = await this.removeCurrentSymbolFromList(this.data)
        // if (res?.data) {
        //   this.isLoading = false
        //   this.closeRemoveModal()
        // } else {
        //   this.showError = 'Error while removing symbol from watchlist'
        //   setTimeout(() => {
        //     this.showError = ''
        //   }, 5000)
        // }
        // this.isLoading = false
      },
  
      closeRemoveModal() {
        this.$emit('close')
      },
  
      updateScreenWidth() {
        this.screenWidth = window.innerWidth
      },
    },
  
    watch: {},
  
    mounted() {
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('resize', this.updateScreenWidth)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      window.addEventListener('resize', this.updateScreenWidth)
    },
  }
  </script>
  
  <style scoped>
  @media screen and (max-width: 500px) {
    .suggestionText {
      font-size: 8px !important;
    }
  }
  @media screen and (max-width: 375px) {
    .suggestionText {
      font-size: 7px !important;
      white-space: nowrap;
    }
  }
  
  .scroll-container {
    /* Adjust height as needed */
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
  }
  .scroll-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  </style>
  