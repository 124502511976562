<template>
    <div class="p-4">
      <div class=" h-[40rem] flex justify-center items-center" v-if="selectedButtonTypeName == 'ProBreakout Alert'">
       <h1> Pro Breakout Alert Setting still not Yet.</h1></div>
      <div v-if="selectedButtonTypeName == 'Filters'">
        <Filters :id="id" />
      </div>
    </div>
  </template>
  
  <script>
  import EventsFilter from './EventsFilter.vue'
  import Filters from './Filters.vue'
  export default {
    name: 'ProDowntrendMonitor',
    data() {
      return {
        id: 'proDowntrendMonitor',
      }
    },
    props: {
      selectedButtonTypeName: {
        type: String,
        default: '',
      },
    },
    components: { Filters,EventsFilter },
    methods: {},
    
      EventsFiltercomputed: {},
    mounted() {},
  }
  </script>
  
  <style>
  </style>