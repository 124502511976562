<template>
    <div :class="isLoading ? 'pointer-events-none' : ''" class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden">
        <div class="w-full h-full text-[#000000]">

      <div
        class="w-full !h-[50px] px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="heading"
          @close="closeModal"
        />
      </div>
      <div
        class="border-t border-b border-[#E2E8F0] py-3 !px-8 w-full flex flex-col min-h-[130px]"
      >
        <div class="">
          <div
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[14px] font-normal"
          >
          {{ inputHeading }}
          </div>
          <div
            class="flex items-center -h-[40%] w-[70%] space-x-1 dark:!border-[#2D2D2D] mt-3 dark:hover:bg-[#23232370] px-2 py-1.5 cursor-pointer text-sm hover:bg-[#eaeaea] dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
          >
            <input
              v-model="inputValue"
              :placeholder="placeholder"
              @input="handleInput"
              @keydown.enter="handleSave"
              class="bg-transparent text-[#181818] dark:text-[#ffffff80] placeholder:text-[#323232] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
            />
          </div>
        </div>

        <div v-if="errorMessage" class="pt-2 text-errorText font-normal">
          <span>{{ errorMessage }}</span>
        </div>
        <div v-if="successMessage" class="pt-2 text-[#0CAF2F] font-normal">
          <span>{{ successMessage }}</span>
        </div>
      </div>

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3 dark:bg-[#1A1A1A]"
      >
        <ModalFooter
          firstTitle="Close"
          secondTitle="Save"
          colorType="color"
          :isLoading="isLoading"
          @close="closeModal"
          @save="handleSave"
        />
      </div>
    </div>
    </div>
</template>

<script>
import ModalHeader from '../ModalHeader.vue';
import ModalFooter from '../ModalFooter.vue';

export default{
    name:'InputModal',
    components:{
        ModalHeader,
        ModalFooter
    },
    data() {
        return {
            // Your data here
             inputValue: ""
        }
    },
    props:{
        heading:String,
        inputHeading:String,
        errorMessage: String,
        successMessage: String,
        isLoading: Boolean,
        placeholder: String,
        initialValue: String,
    },
    methods: {
        // Your methods here
        closeModal(){
            this.$emit('close')
        },
        handleSave(){
            this.$emit('save')
        },
        handleInput() {
        this.$emit("input", this.inputValue);
      },
    },
    mounted() {
        // Your code here
        if(this.initialValue){
          this.inputValue = this.initialValue;
        }
    }
 
}


</script>