<template>
    <div id="chart" class="w-full h-full">
      <apexchart
        type="line"
        height="100%"
        width="100%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import VueApexCharts from "vue-apexcharts";
  
  Vue.component("apexchart", VueApexCharts);
  
  export default {
    name: "LineWithBar",
  
    data() {
      return {
        series: [
          {
            name: "Revenue",
            type: "bar",
            data: [20, 40, 55, 90, 100] // Example data, replace with your actual data
          },
          {
            name: "Net income",
            type: "bar",
            data: [10, 20, 25, 35, 40] // Example data, replace with your actual data
          },
          {
            name: "Net Margin %",
            type: "line",
            data: [10, 25, 50, 70, 100] // Example data, replace with your actual data
          }
        ],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false // Disable header tools
            }
          },
          plotOptions: {
            bar: {
              columnWidth: "50%"
            }
          },
          markers: {
            size: 6,
            colors: ["#FF5733"] // Customize the color of the markers
          },
          stroke: {
            width: [0, 0, 2], // Line width for the scatter line
            curve: 'smooth' // Optional: smooth line for the scatter
          },
          xaxis: {
            categories: ['19', '20', '21', '22', '23'] // Replace with your actual categories
          },
          yaxis: [
            {
              opposite: true,
              title: {
                text: undefined
              },
              labels: {
                formatter: (value) => `${value} B`
              }
            },
            {
              show: false,
              title: {
                text: "Net Margin %"
              },
              labels: {
                formatter: (value) => `${value}%`
              }
            }
          ],
          tooltip: {
            shared: true,
            intersect: false
          },
          legend: {
            position: "bottom",
            markers: {
              fillColors: ["#1E90FF", "#00C49F", "#FF5733"] // Customize colors for legend markers
            }
          }
        }
      };
    }
  };
  </script>
  
  <style scoped>
  #chart {
    max-width: 400px;
    margin: 35px auto;
  }
  </style>
  