<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Candlestick'">
        <CandleStickPattern @isSelectedCandleStick="isSelectedCandleStick" :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Filters'">
        <Filters :id="id" />
    </div>
  </div>
</template>
  
  <script>
import Filters from './Filters.vue';
import CandleStickPattern from "../../CandleStickPattern.vue"

export default {
  name: 'Candle',
  components: {Filters,CandleStickPattern},
  props: {
    selectedButtonTypeName:{
        type: String,
        default: ""
    }
  },
  data() {
    return {
        id: 'candlesticks',
      dialogId: 'chartEvent',
    }
  },
  methods: {
    isSelectedCandleStick(pay){
      this.$emit('isSelectedCandleStick',pay)
    }
  },
  computed: {},
  mounted() {},
}
</script>
  
  <style>
</style>