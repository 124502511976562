import Main from './main.js'
Main.__name__ = 'legend-buttons'

const widgets = {}
const components = {}
const overlays = {}
const colorpacks = {}
const skins = {}

const Pack = {
  widgets,
  components,
  overlays,
  colorpacks,
  skins,
  Main
}

export default Pack

export { widgets, components, overlays, colorpacks, skins, Main }
