<template>
  <div style="width: 100%; height: 100%;" >
    <div style="height: 10%; " class="sm:p-[20px]">
      <Header></Header>
    </div>
    <div
      class="welcomeParent mt-0 sm:mt-20 -bg-red-400 overflow-y-auto no-scrollbar"
      style="
        height: 89%;

        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        padding-bottom: 10rem;
      "
    >
      <div
        style="
          width: 60%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
        class="welcome-container h-full flex flex-col justify-center -bg-slate-200"
      >
        <div class="w-full -bg-slate-400">
          <span
            class="extraLargeText text-appBlue xxs:leading-[50px] md:leading-[80px] xxs:!text-[30px] xs:!text-[34px] md:!text-[50px] 1.5lg:!text-[70px] font-bold"
            style="font-weight: bold"
            >Your account has been
            <span class="text-[#300003]">created</span></span
          >
        </div>
        <div
          style="
            margin-bottom: 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="welcome-Detail mt-2" style="width: 50%; line-height: 0.5">
            <span class="-mediumText" style="font-size: 16px"
              ><span
                class="text-appBlue"
                style="font-weight: 800; line-height: 25px"
                >Enjoy your 3-months free trial!</span
              >
              Our software is a Web Application and is best used on a laptop or
              computer device.</span
            >
          </div>
        </div>

        <div
          class="mainFormContainer w-[50px] h-[50px]"
          style="
            /* height: 30%;
            width: 30%; */
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            class="bg-appBlue hover:bg-blueHover"
            @click="handleRoute()"
            style="
              padding: 14px;
              height: 100%;
              color: white;
              cursor: pointer;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            Start
            <div style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
      </div>

      <div class="font-[300] mt-8 px-2 py-4 text-justify sm:hidden">
          <span class="text-[#828282]">
            <span class="font-bold ">IMPORTANT NOTICE:</span class="!text-[#d13030] text-[104px]" style="color: #828282 !important;"> ProTrading Scans website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. ProTrading Scans are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.
          </span>
        
    </div>
    </div>

    

    <div class="smallFooter" style="display: none; width: 85%">
      <SmallScreenFooter></SmallScreenFooter>
    </div>

    <div style="height: 5%" class="hideDetail">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '../component/Header.vue'
import Footer from '../component/Footer.vue'
import SmallScreenFooter from '../component/SmallScreenFooter.vue'

export default {
  name: 'WelcomePage',
  props: {
    token: String,
  },
  components: {
    Footer,
    Header,
    SmallScreenFooter,
  },
  data() {
    return {}
  },
  methods: {
    handleRoute() {
      localStorage.setItem('auth', true)
      localStorage.setItem('state', this.token)
      this.$router.push('/')
    },
  },
}
</script>

<style scoped></style>
