<template>
  <div>
    <CusrtomizePatternWrapper
      :headingName="'Detection'"
      :tag="'Divergence '"
      :detail="'Identify divergences between price action and various technical indicators to detect potential trend '"
      :highlighted="'reversals or continuations.'"
    >
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              OBV (On-Balance Volume)
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]">OBV</span>
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <!-- here was the select and options -->
                  <input
                    style="width: 20px; height: 20px"
                    type="radio"
                    class="hidden"
                    id="indicator_settings"
                    name="indicator_settings"
                    value="obv"
                    v-model="settings.selectedScan"
                  />
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based ${settings.selectedScan} on their earnings yield relative to their market price.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('obv')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('obv') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>

      <!-- Second Input -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              STOC (Stochastic Oscillator)
            </div>
            <div
              class="flex xxs:flex-col md:flex-row md:items-center gap-2 md:space-x-3 w-full"
            >
              <div class="w-auto flex gap-2 items-center h-full">
                <div
                  class="w-[100%] gap-2 flex xxs:flex-col md:flex-row md:items-center -bg-slate-400"
                >
                  <div class="flex items-center space-x-3 w-full">
                    <span
                      class="text-[#5C5C5C] font-[400] text-[16px] shrink-0"
                    >
                      STOC Period:</span
                    >
                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.stoc.period"
                    />
                  </div>
                  <div class="flex items-center space-x-3 w-full">
                    <span>K%:</span>
                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.stoc.kPeriod"
                    />
                    <span>D%:</span>

                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.stoc.dPeriod"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on ${settings.stoc.period} their earnings yield ${settings.stoc.kPeriod} relative to their ${settings.stoc.dPeriod} market price.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('stoc')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('stoc') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>

      <!-- Third Input -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              MACD (Moving Average Convergence Divergence)
            </div>
            <div class="flex items-center space-x-3 w-full">
              <div class="w-auto flex gap-2 items-center h-full">
                <div
                  class="w-[100%] gap-3 flex xxs:flex-col md:flex-row md;items-center"
                >
                  <div class="flex items-center space-x-3">
                    <span class="text-[#5C5C5C] font-[400] text-[16px]">
                      Fast:</span
                    >
                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.macd.fast"
                    />
                  </div>
                  <div class="flex items-center space-x-3">
                    <span>Slow:</span>
                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.macd.slow"
                    />
                  </div>
                  <div class="flex items-center space-x-3">
                    <span>SIGNAl%:</span>
                    <input
                      class="px-3 rounded-[2.5px] border-2 border-appBlue"
                      style="width: 100px; height: 45px; font-size: 16px"
                      type="number"
                      tabindex="3"
                      v-model="settings.macd.signal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on ${settings.macd.fast} their earnings ${settings.macd.slow} yield relative to ${settings.macd.signal} their market price.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('macd')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('macd') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>

      <!-- Fourth Input -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              RSI (Relative Strength Index)
            </div>
            <div class="flex items-center space-x-3 w-full">
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="w-[100%] gap-3 flex items-center">
                  <span class="text-[#5C5C5C] font-[400] text-[16px]"
                    >Period:</span
                  >

                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    tabindex="3"
                    v-model="settings.rsi"
                  />
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on their earnings yield ${settings.rsi} relative to their market price.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('rsi')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('rsi') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>

      <!-- Fifth Input -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Scanning Timeframe
            </div>
            <div class="flex items-center space-x-3 w-full">
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="w-[100%] gap-3 flex xxs:flex-col md:flex-row md:items-center">
                  <span class="text-[#5C5C5C] font-[400] text-[16px]"
                    >Maximum number of days in the past to be scanned:</span
                  >
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 100px; height: 45px; font-size: 16px"
                    type="number"
                    tabindex="3"
                    v-model="settings.maxRange"
                  />
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on their earnings ${settings.maxRange} yield relative to their market price.`"
              />
            </div>
          </div>
        </div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="'#1C274C'"
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CusrtomizePatternWrapper from '../../CusrtomizePatternWrapper.vue'
import DescriptionInput from '../../DescriptionInput.vue'
export default {
  components: { CusrtomizePatternWrapper, DescriptionInput },
  name: 'DivergenceScanComponents',
  data() {
    return {
      settingsData: {
        selectedScan: 'obv',
        maxRange: 20,
        stoc: {
          kPeriod: 12,
          dPeriod: 26,
          period: 9,
        },
        rsi: 14,
        macd: {
          fast: 12,
          slow: 26,
          signal: 9,
        },
      },
    }
  },
  mounted() {
    console.log('gurusScanSettings==>', this.gurusScanSettings?.divergenceScan)
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    settings: {
      get() {
        return this.gurusScanSettings?.divergenceScan || this.settingsData
      },
      set(value) {
        this.setDivergenceScanSettings(value)
      },
    },
  },

  methods: {
    ...mapActions(['setDivergenceScanSettings']),
    isActiveHandler(key) {
      this.settings.selectedScan = key
    },
    getActive(key) {
      return this.settings.selectedScan == key
    },
  },
  watch: {},
}
</script>

<style></style>
