<template>
  <div class="w-full h-full">
    <div class="w-full -bg-green-300">
      <RightBarHead
        :mainHeading="`${getSelectedWatchlist?.name || 'Watchlist'}`"
        :subHeading="`${getSelectedWatchlist?.symbolList?.length || 0} result(s) found`"
        :selectedRight="selectedRight"
        @handleOpenWatchlistModal="handleOpenWatchlistDetailModal"
        @handleOpenDownloadSymbolModal="handleOpenDownloadSymbolModal"
        
      />
    </div>
    <div
      class="w-full !h-[calc(100%-4rem)] -bg-slate-400 overflow-y-scroll scroll-container"
    >
      <WatchlistSymbols />
    </div>
  </div>
</template>
<script>
import RightBarHead from '../../RightBarHead.vue'
import WatchlistSymbols from './WatchlistSymbols.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'WatchList',
  data() {
    return {}
  },
  components: {
    WatchlistSymbols,
    RightBarHead,
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
  },
  methods: {
    handleOpenWatchlistDetailModal() {
      this.$emit('handleOpenWatchlistDetailModal')
      console.log('open "watchlist" Modal Detail')
    },

    handleOpenDownloadSymbolModal(watchlist) {
      this.$emit('handleOpenDownloadSymbolModal', watchlist)
      console.log('open "watchlist" Modal Download')
    },

    // openAddSymbolModal() {
    //   this.$emit('openAddSymbolModal')
    //   console.log('open add symbol Modal watchlist')
    // },
  },
  computed: {
    ...mapGetters(['getSelectedWatchlist']),
  },
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>
