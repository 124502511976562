<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Darvas Box'">
        <DarvasBoxCom/>
    </div>
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
  </div>
</template>

<script>
import Filters from './Filters.vue'
import DarvasBoxCom from './DarvasBoxCom.vue'
export default {
  name: 'DarvasBoxes',
  data() {
    return {
      id: 'darvasScan',
    }
  },
  components: {
    Filters,
    DarvasBoxCom
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    selectedButtonTypeName(value){
      console.log("value==>",value);
    }
  },
  mounted(){
    console.log("selectedButtonTypeName",this.selectedButtonTypeName);
  }
}
</script>

<style>
</style>