import http from './http'
import LocalStorageHelper from './localStorage'

export async function getListOfSymbols() {
    try {
      const res = await http.get(`/symbols`)
      if (res.status === 200) {
        return res?.data?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  /**
 * Get list of symbols
 *
 * @returns {[[]]}
 */
let cancelToken
export async function getListOfSymbolsStartWith(symbol,isRange = false,selectedMarket,categoriesAlphabet = false,fromSearchBox = false) {
  try {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = http.CancelToken.source()

    const res = await http.get(`/symbols/startWith/${symbol}?market=${selectedMarket}${isRange ? '&isRange=Y' : ''}${categoriesAlphabet ? '&categoriesAlphabet=Y' : ''}${isRange ? '&isRange=Y' : ''}${fromSearchBox ? '&fromSearchBox=Y' : ''}`,
        { cancelToken: cancelToken.token })
    if (res.status === 200) {
      return res.data.data
    } else {
      return fromSearchBox ? res : []
    }
  } catch (error) {
    return fromSearchBox ? "Error" : []
  }
}

export async function getSymbolData(symbol,timeFrame='D'){
  try {
    const res = await http.get(`/symbols/${symbol}?timeFrame=${timeFrame}&isIntra=false&market=NASDAQ&minusIndex=0`)    
  if(res?.data?.data){
    return res?.data?.data
  }else{
    return false
  }
  } catch (error) {
  return false    
  }

}

/**
 * @param {String[]} symbolList symbol names
 * @param {String} timeFrame time frame of the requested data
 * @returns {[{}]}
 */
export async function getMultipleSymbolData(symbolList, timeFrame = 'D',symbolName= '',rangeMap = []) {
  try {
    const res = await http.post('/symbols/multiple-symbol-data', {
      symbolList,
      timeFrame,
      symbolName,
      rangeMap
    })
    if (res.status === 200) {
      return res.data
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

/**
 * @param {String} symbolName symbol name
 * @param {String} timeFrame time frame of the requested data
 * @returns {[[Number]]}
 */
export async function getData(symbolName, timeFrame = 'D',isIntra = false,market,minusIndex= 0) {
  try {
    //console.log('req market == ',market)
    // return singleSymbolSet.data
    const res = await http.get(`/symbols/${symbolName}`, {
      params: { timeFrame ,isIntra,market,minusIndex}
    })
    if (res.status === 200) {
      if(res.data.data){
        return res.data.data  
      }else{
        return { symbol: 'Error', ohlcvData: [] }  
      }
      
    } else {
      return { symbol: 'Error', ohlcvData: [] }
    }
  } catch (error) {
    return { symbol: 'Error', ohlcvData: [] }
  }
}


export async function symbolPrice(symbolName, from = '2023-03-01',to = '2023-03-10') {
  try {
    const res = await http.get(`/symbols/data/${symbolName}`)
    if (res.status === 200) {
      if(res?.data){
        return res?.data
      }else{
        return false
      }
      
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}


  
export default {
    getListOfSymbols,
    getListOfSymbolsStartWith,
    getMultipleSymbolData,
    getData,
    symbolPrice,
    getSymbolData
  }


  export async function fetchSymbolByName(self,text,isStr = true,isRange = false,selectedMarket = "",fromSearchBox = false,categoriesAlphabet = false) {
    const symbolsList = await getListOfSymbolsStartWith(text,isRange,selectedMarket,categoriesAlphabet,fromSearchBox)
  
  
    if(fromSearchBox){
      // let filtered2 = []
      // for (const key in symbolsList) {
      //   console.log(key)
      //   let newObj = symbolsList[key];
      //   // console.log(newObj)
      //   // if(isStr){
      //   //   newObj = newObj.slice(0, self.limit)
      //   // }
      //   // console.log(newObj)
      //   filtered2.push({
      //     name:key,
      //     data: newObj.map(m => ({symbol:m.symbol,companyName:m.companyName}))
      //   })
      // }
      return symbolsList
    }else{
      let filtered2 = []
      for (const key in symbolsList) {
        // console.log(key)
        let newObj = symbolsList[key];
        // console.log(newObj)
        if(isStr){
          newObj = newObj.slice(0, self.limit)
        }
        // console.log(newObj)
        filtered2.push({
          name:key,
          data: newObj.map(m => ({symbol:m.symbol,companyName:m.companyName,close:m?.close,change:m?.change,changePercent:m?.changePercent}))
        })
      }
      return filtered2
    }
  
  }

  export function getLastSymbol() {
    return LocalStorageHelper.getItem('symbol')
  }

  export function setLastSymbol(symbol) {
    return LocalStorageHelper.setItem('symbol', symbol)
  }