<template>
  <!-- bg hover #2D2D2D -->
  <!-- bg normal #1A1A1A -->
  <!-- title text #e9ecf8 -->
  <!-- subTitle text #858a97 -->
  <!-- price text #34c244 -->
  <!-- border #404248 -->
  <!-- text #6C707A -->
  <!-- brandicon dark:bg-[#676868] -->

  <div class="w-full h-[100%] bg-white dark:!bg-[#1A1A1A]">
    <div class="w-full -border h-[100%]">
      <div
        class="w-full py-3 border-b border-[#DEDEDE]  dark:!border-[#404248] justify-start items-center px-[10px] flex gap-3"
      >
        <div
          class="w-[40px] h-[40px] bg-[#E2E8F0] dark:bg-[#676868] dark:text-[#f6f6f6] flex justify-center text-[16px] items-center rounded-full"
        >
          {{ getSymbolDetail?.companyName[0] }}
        </div>

        <div class="flex flex-col justify-center w-[75%] space-y-0.5">
          <p
            class="text-[#000000] dark:text-[#dedede] text-[14px] font-sans font-bold leading-5"
          >
            {{ getSymbolDetail?.companyName}}
          </p>
          <div class="flex justify-start gap-2.5 -border text-[10px]">
            <p
              class="text-[#000000] dark:!text-[#34c244] text-[12px] font-sans font-semibold"
            >
              {{
                getSymbolDetail?.close
                  ? `$${getSymbolDetail?.close}`
                  : '$185.79'
              }}
            </p>
            <p class="text-appBlue text-[12px] font-sans font-[400]">
              {{
                getSymbolDetail?.close
                  ? `${getSymbolDetail?.close}%`
                  : '-1.11(-0.59%)'
              }}
            </p>
            <p
              class="text-[#7C7C7C] dark:!text-[#9aa0ae] text-[12px] font-sans font-semibold"
            >
              {{
                getSymbolDetail?.sharevolume
                  ? `Vol: ${getSymbolDetail?.sharevolume}%`
                  : 'Vol: 4,055,411'
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full h-[72%]  -border flex flex-wrap justify-end space-y-2 items-start px-[10px] gap-1 scroll-container py-2 overflow-y-auto"
      >
        <div
          class="w-[49%] border-l-4 border-[#EFEFEF] dark:border-[#404248] flex flex-col justify-center pl-2"
          v-for="(item, index) in stackInfoData"
          :key="index"
        >
          <div class="w-full h-full flex flex-col justify-center" key="index">
            <p class="text-[12px] font-sans text-[#000000] font-[800] dark:text-[#bbbaba]">
              {{ item.name }}
            </p>
            <p :title="item.key=='sector' && getSymbolDetail[item.key]" class="text-[13px] font-sans font-[500] text-[#000000] dark:text-[#dedede]">
              {{ getSymbolDetail[item.key] ? getValue(item.key,getSymbolDetail[item.key]) : 'N/A' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSvgByName } from '../assets/rightsidebarsvgs/utils/SvgUtils'
import { mapGetters } from 'vuex'
import {formatNumber,getTruncateText} from '../utils/common.js'

export default {
  name: 'StackInfo',
  data() {
    return {
      stackInfoData: [
        {key:'sector',name:'Sector'},
        // {key:'industry',name:'Industry'},
        {key:'issue',name:'Issued'},
        {key:'marketCap',name:'Market Cap'},
        {key:'peRatio',name:'Pe Ratio'},
        {key:'eps',name:'EPS'},
        {key:'category',name:'Category'},
        {key:'dividendRate',name:'Dividend Rate'},
        {key:'dividendYield',name:'Dividend Yield'},
        {key:'exchange',name:'Exchange'},
        {key:'frankedPct',name:'Franked Pct'},
        {key:'return12m',name:'Return 12M'},
        {key:'return1m',name:'Return 1M'},
        {key:'return3m',name:'Return 3M'},
        {key:'return6m',name:'Return 6M'},
        ],
    }
  },
  components: {},
  methods: {
    getSvg(name) {
      return getSvgByName(name)
    },
    getValue(key,val){
      if(key == 'marketCap' || key == 'issue' ){
        return formatNumber(val)
      }else if(key=='sector'){
        return getTruncateText(val,18)
      }
      else{
        return val
      }
    },
    Toggle(toggle) {
      if ((this.toggler = !toggle)) {
        this.toggler = true
        console.log('toggler', this.toggler)
      } else {
        this.toggleIn = false
        console.log('toggler', this.toggler)
      }
    },
  },
  computed: {
    ...mapGetters(['getSymbolDetail']),
  },
  mounted() {
    // console.log('Sheharyar', this.getSymbolDetail)
  },
  watch: {},
}
</script>
<style scoped>
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
