<template>
  <div class="space-y-2 w-full h-full">
    <!-- first filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('priceRangeDollar', 'isActive')"
      :selectedKey="'priceRangeDollar'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Price Range Filter
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Stocks with a price range between</span>
          <input
            class="px-3 rounded-[2.5px] border-2 "
            :class="focusHandler(settings.priceRangeDollar.start)"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.priceRangeDollar.start"
          />
          <span>and</span>
          <input
            class="px-3 rounded-[2.5px] border-2"
            :class="focusHandler(settings.priceRangeDollar.end)"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.priceRangeDollar.end"
          />
          <span>dollars.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Select stocks priced between $${settings.priceRangeDollar.start} and $${settings.priceRangeDollar.end} dollars.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- Second filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('ignoreResults', 'isActive')"
      :selectedKey="'ignoreResults'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Data Sufficiency Filter
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span class="">Filter out stocks with fewer than</span>
          <input
            class="px-3 rounded-[2.5px] border-2"
            :class="focusHandler(settings.ignoreResults.minDays)"
            style="width: 18%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.ignoreResults.minDays"
          />
          <span>of trading data.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Exclude stocks with less than ${settings.ignoreResults.minDays} day(s) of data.`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- Third filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="
        getActive('isIgnoreResultsWithNoRangeForLastDay', 'isActive')
      "
      :selectedKey="'isIgnoreResultsWithNoRangeForLastDay'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          No Trading Range Filter
        </h3>
        <div class="w-full py-[0.7rem] gap-3 flex items-center">
          <span
            >Filter out stocks that had no trading range on the last day.</span
          >
        </div>
        <div>
          <DescriptionInput
            :description="`Exclude stocks with no trading range on the last day.`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- fourth filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('onlyShowResultsThatHaveTraded', 'isActive')"
      :selectedKey="'onlyShowResultsThatHaveTraded'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Recent Trading Activity Filter
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Show only stocks that have traded within the last </span>
          <input
            class="px-3 rounded-[2.5px] border-2"
            :class="focusHandler(settings.onlyShowResultsThatHaveTraded.pastDays)"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.onlyShowResultsThatHaveTraded.pastDays"
          />
        </div>

        <div>
          <DescriptionInput
            :description="`Include only stocks that have traded in the past ${settings.onlyShowResultsThatHaveTraded.pastDays} day(s).`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- fifth filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('ignoreResultsWithValueLess', 'isActive')"
      :selectedKey="'ignoreResultsWithValueLess'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Minimum Value Filter
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Filter out stocks with a value below </span>
          <input
            class="px-3 rounded-[2.5px] border-2"
            :class="focusHandler(settings.ignoreResultsWithValueLess.value)"
            style="width: 28%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.ignoreResultsWithValueLess.value"
          />
        </div>

        <div>
          <DescriptionInput
            :description="`Exclude stocks with a value less than ${settings.ignoreResultsWithValueLess.value}`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- SIX filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('ignoreResultsWithVolumeLess', 'isActive')"
      :selectedKey="'ignoreResultsWithVolumeLess'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Minimum Volume Filter
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Filter out stocks with a trading volume below </span>
          <input
            class="px-3 rounded-[2.5px] border-2"
            :class="focusHandler(settings.ignoreResultsWithVolumeLess.volume)"
            style="width: 25%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.ignoreResultsWithVolumeLess.volume"
          />
        </div>

        <div>
          <DescriptionInput
            :description="`Exclude stocks with a volume less than 25,000 ${settings.ignoreResultsWithVolumeLess.volume}`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- sevevth\ filter -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('isExcludeIntraDayData', 'isActive')"
      :selectedKey="'isExcludeIntraDayData'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Intra-Day Data Exclusion
        </h3>
        <div class="w-full py-[0.7rem] gap-3 flex items-center">
          <span>Filter out all intra-day data.</span>
        </div>
        <div>
          <DescriptionInput
            :description="`Exclude stocks with intra-day data.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'Filters',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      settingsData: {
        priceRangeDollar: {
          isActive: false,
          start: 0,
          end: 0,
        },
        ignoreResults: {
          isActive: false,
          minDays: 280,
        },
        isIgnoreResultsWithNoRangeForLastDay: false,
        onlyShowResultsThatHaveTraded: {
          isActive: false,
          pastDays: 10,
        },
        ignoreResultsWithValueLess: {
          isActive: false,
          value: 25000,
        },
        ignoreResultsWithVolumeLess: {
          isActive: false,
          volume: 25000,
        },
        isExcludeIntraDayData: false,
      },
    }
  },
  components: { DescriptionInput, Wrapper },
  computed: {
    ...mapGetters([
      'filterSettingsForScan',
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'favSelectedScanId',
      'userChoice',
      'themeDarkMode',
    ]),
    modalID() {
      return this.$props.id
    },
    settings: {
      get() {
        if (this.modalID) {
          let filterSettingsForScan = this.filterSettingsForScan(this.modalID)
          // console.log(
          //   'setScanFilterSettings 0',
          //   this.modalID,
          //   filterSettingsForScan
          // )
          if (!filterSettingsForScan) {
            let value = JSON.parse(JSON.stringify(this.settingsData))
            this.setScanFilterSettings({
              id: this.modalID,
              settings: value,
            }).then((d) => {
              return this.filterSettingsForScan(this.modalID)
            })
          }
          return filterSettingsForScan || this.settingsData
        }

        return this.filterSettings || this.settingsData
      },
      set(value) {
        if (this.modalID) {
          this.setScanFilterSettings({ id: this.modalID, settings: value })
        } else this.setScanFilterSettingsOld(value)
      },
    },
  },
  methods: {
    ...mapActions([
      'setScanFilterSettings',
      'setScanFilterSettingsOld',
      'resetGurusSettings',
      'getUserScanList',
      'setScanFilterSettings',
    ]),
    focusHandler(param){
      return !param ? 'focus:border-red-600 focus:ring-0' : 'border-appBlue'
    },
    isActiveHandler(key) {
      console.log('key is finding===>', key)
      const val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
    },
    setSetting(key1, key2, val) {
      const filters = this.filterSettingsForScan(this.modalID)
      if (key1 == 'isExcludeIntraDayData') {
        filters[key1] = val
      } else if (key1 == 'isIgnoreResultsWithNoRangeForLastDay') {
        filters[key1] = val
      } else {
        filters[key1][key2] = val
      }
      const payload = {
        l: 'settings_single',
        id: this.id,
        filters,
      }
      this.setScanFilterSettings(payload)
    },
    getActive(key1, key2) {
      const filters = this.filterSettingsForScan(this.modalID)
      if (key1 === 'isIgnoreResultsWithNoRangeForLastDay') {
        return filters[key1]
      } else if (key1 == 'isExcludeIntraDayData') {
        return filters[key1]
      } else {
        return filters[key1][key2]
      }
    },
  },
}
</script>

<style></style>
