<template>

  <sui-modal
    v-model="isDialogOpen"
    :closable="false"
    :closeIcon="closeIcon"
    @changed="$emit('onClose')"
    :size="size"
  >
  <!-- <DeleteFavouriteList :deleteFavScanItem="deleteFavScanItem" :scanName="scanName" :showDeleteDialog="showDeleteDialog" @SureDeleteFavScanItem="SureDeleteFavScanItem" @HideDeletePopup="HideDeletePopup"/> -->
  <!-- <FavouriteScanPopup @saveScanSettings="saveScanSettings" /> -->
    <sui-modal-header :style="{...themePrimaryColor,...themeFontColor}" v-if="modalHeader.length" >{{ modalHeader }}</sui-modal-header>
    <sui-modal-content :scrolling="scrolling" :style="{...themePrimaryColor,...themeFontColor}" ><slot /></sui-modal-content>
    <sui-modal-actions  v-if="action" :style="{...themePrimaryColor,...themeFontColor}" >
      <sui-button :disabled="isDisabled" :style="themeSecondaryColor" @click.native="$emit('ok')"> OK </sui-button>
      <sui-button @click.native="$emit('onClose')" secondary>
        Cancel
      </sui-button>
    </sui-modal-actions>
    <slot name="action" v-else :style="{...themePrimaryColor,...themeFontColor}"/>
  </sui-modal>

</template>

<script>
import {mapGetters} from "vuex";
// import FavouriteScanPopup from '../components/dialogBoxes/patterns/FavouriteScanPopup.vue'
// import DeleteFavouriteList from '../components/dialogBoxes/patterns/DeleteFavouriteList.vue'

export default {
  props: {
    modalHeader: {
      type: String,
      default: 'Modal Header'
    },
    open: {
      type: Boolean,
      default: false
    },
    action: {
      type: Boolean,
      default: true
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    scrolling: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'small'   //'standard'
    },
    scanName: {
      type: String,
      default: ''   //'standard'
    },
    deleteFavScanItem: {
      type: String,
      default:''
    },
    isDisabled:{
      type:Boolean,
      default:false
    },
    showDeleteDialog: {
      type: Boolean,
      default:false
    }
  },
  // components:{FavouriteScanPopup, DeleteFavouriteList},
  name: 'DialogBox',
  data() {
    return {
      isDialogOpen: false
    }
  },
  computed:{
    ...mapGetters(['themePrimaryColor'
      ,'themeSecondaryColor'
      ,'themeFontColor'])
  },
  methods:{
    saveScanSettings(name){
      this.$emit('saveScanSettings', name)

    },
    SureDeleteFavScanItem(check){
      this.$emit('SureDeleteFavScanItem',check)
    },
    HideDeletePopup(){
      this.$emit('HideDeletePopup')
    }
   
  },
  watch: {
    open(newValue) {
      this.isDialogOpen = newValue
    },
    isDialogOpen(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$emit('onClose')
      }
    }
  },

}
</script>

<style scoped>


</style>
