<template>
  <div class="flex space-y-2 w-full h-full">
    <div class="w-full h-full">
      <PatternWrapper
        :data="data"
        :settingData="patternSettingsData"
        @onPress="handlePress"
      />
    </div>
  </div>
</template>

<script>
import PatternWrapper from '../../src/component/PatternWrapper.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChartingPatternComp',
  components: {
    PatternWrapper,
  },
  props: {
    dialogId: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          name: 'Reversal Patterns',
          tag: 'Bullish',
          detail:
            'These patterns suggest a trend is changing from a downtrend to an uptrend, indicating a ',
          highlighted: 'potential buying opportunity.',
          group: [
            {
              name: 'Bullish Harami Cross',
              key: 'bullishHaramiCross',
              icon: 'bullish-harami-cross',
              desc: 'A Doji within a bearish candle, signaling a potential bullish reversal.',
            },
            {
              name: 'Inverted Hammer',
              key: 'invertedHammer',
              icon: 'inverted-hammer',
              desc: ' A small body with a long upper shadow after a downtrend, indicating potential bullish reversal.',
            },
            {
              name: 'Bullish Engulfing',
              key: 'bullishEngulfing',
              icon: 'bullish-engulfing',
              desc: 'A larger bullish candle that engulfs a smaller bearish candle, signaling strong buying pressure and potential trend reversal.',
            },
            {
              name: 'Bullish Harami',
              key: 'bullishHarami',
              icon: 'bullish-harami',
              desc: 'A small bullish candle within the body of a larger bearish candle, suggesting a weakening downtrend and potential reversal.',
            },
            {
              name: 'Hammer',
              key: 'hammer',
              icon: 'hammer',
              desc: 'A small body with a long lower shadow after a downtrend, indicating buyers are gaining control and a bullish reversal may occur.',
            },
          ],
        },
        {
          name: 'Reversal Patterns',
          tag: 'Bearish',
          detail:
            'These patterns suggest a trend is changing from an uptrend to a downtrend, indicating a ',
          highlighted: 'potential selling opportunity.',
          group: [
            {
              name: 'Bearish Harami Cross',
              key: 'bearishHaramiCross',
              icon: 'bearish-harami-cross',
              desc: 'A Doji within a bullish candle, signaling a potential bearish reversal.',
            },
            {
              name: 'Shooting Star',
              key: 'shootingStar',
              icon: 'shooting-star',
              desc: 'A small body with a long upper shadow after an uptrend, indicating potential bearish reversal.',
            },
            {
              name: 'Bearish Engulfing',
              key: 'bearishEngulfing',
              icon: 'bearish-engulfing',
              desc: 'A larger bearish candle that engulfs a smaller bullish candle, signaling strong selling pressure and potential trend reversal.',
            },
            {
              name: 'Bearish Harami',
              key: 'bearishHarami',
              icon: 'bearish-harami',
              desc: 'A small bearish candle within the body of a larger bullish candle, suggesting a weakening uptrend and potential reversal.',
            },
            {
              name: 'Hanging Man',
              key: 'hangingMan',
              icon: 'hanging-man',
              desc: ' A small body with a long lower shadow after an uptrend, indicating sellers are gaining control and a bearish reversal may occur.',
            },
          ],
        },
        {
          name: 'Patterns',
          tag: 'Indecision ',
          detail:
            'Candlestick formations indicate market uncertainty, where buyers and sellers are in balance. To signal potential trend reversal or continuation depending on ',
          highlighted: 'subsequent market action.',
          group: [
            {
              name: 'Doji',
              key: 'doji',
              icon: 'doji',
              desc: 'A candlestick with little to no body, where the open and close prices are nearly the same. Signals indecision in the market, can indicate a potential reversal when appearing after a strong trend, but it can also suggest a continuation depending on the context.',
            },
          ],
        },
      ],
    }
  },

  methods: {
    ...mapActions(['setPatternSettings']),
    isHasTrueValue(pattern) {
      for (const key of Object.keys(pattern)) {
        if(pattern[key] == true){
          return true
        }
      }
      return false
    },
    handlePress(key) {
      let updatedSetting = this.patternSettingsData
      updatedSetting[key] = !updatedSetting[key]
      let payload = {
        id: this.id,
        settings: updatedSetting,
      }
      this.setPatternSettings(payload)
      const r = this.isHasTrueValue(this.patternSettingsData)
      this.$emit('isSelectedCandleStick',r)
    },
  },

  computed: {
    ...mapGetters(['getPatternSettings']),
    patternSettingsData() {
      return this.getPatternSettings(this.id)
    },
  },
  mounted() {
    
  },
}
</script>