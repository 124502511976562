<template>
  <div class="p-8">
    <!-- Button Tabs Components -->

    <div v-if="selectedButtonTypeName == 'Activity'">
      <ActivityComp :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
    <div v-else-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
  </div>
</template>
<script>
import ChartingPatternComp from '../../dialogBoxes/patterns/ChartPatternsIndicator.vue'
import EventsFilter from '../../dialogBoxes/patterns/EventsFilter.vue'
import ActivityComp from './ActivityComp.vue'
import Filters from './Filters.vue'

export default {
  name: 'App',
  data() {
    return {
      id: 'activity',
      dialogId: 'chartEvent',
    }
  },
  components: {
    ActivityComp,
    EventsFilter,
    Filters,
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  methods: {},
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>
