<!-- FormLayout.vue -->
<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 40px 40px 40px 40px;
    "
    class="page-background mobileMainContainer -bg-orange-400"
  >
    <div
      class="pageContainerParent flex xxs:flex-col sm:flex-row xxs:px-3 -bg-green-200"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      "
    >
      <div
        class="pageContainer -!bg-red-500"
        style="
          height: 100%;
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow-x: auto;
        "
      >
        <div style="height: 9%" class="-bg-green-800 flex justify-start !items-center">
          <Header></Header>
        </div>

        <div class="-bg-green-500 xxs:h-[91%] pb-4 sm:!h-[86%]">
          <slot></slot>
        </div>

        <div class="hideDetail -bg-red-300" style="height: 5%; white-space: nowrap">
          <Footer></Footer>
        </div>
      </div>

      <div class="smallFooter" style="display: none; z-index: 0">
        <SmallFooter></SmallFooter>
      </div>

      <div
        class="xxs:block sm:!hidden"
        style="
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* background-color: bisque; */
          margin-top: 0px;
        "
      >
        <div
          class=""
          style="color: #333333; font-size: 16px; font-weight: 500"
        >
          &copy; ProTrading Scans
        </div>
      </div>

      <div style="height: 100%; width: 68%" class="hideDetail">
        <TrialInfo></TrialInfo>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import SmallFooter from './SmallScreenFooter.vue'
import TrialInfo from './TrialInfo.vue'

export default {
  name: 'FormLayout',
  components: {
    Header,
    Footer,
    SmallFooter,
    TrialInfo,
  },
}
</script>

<style scoped>
.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}
</style>
