export const THEME_DETAILS = {
    classic: {
      font: '#000000',
      primary: '#FFFFFF',
      secondary: '#E72510',
    },
    grey: {
      font: '#000000',
      primary: '#C3C3C3',
      secondary: '#2196F3',
    },
    dark: {
      font: '#A5A9B2',
      primary: '#151515',
      secondary: '#64E639',
    },
  }
  export const DEFAULT_THEME_SETTINGS = {
    settings: {
      font: '#000001',
      primary: '#ffffff',
      secondary: '#2780e3',
    },
    id: 'classic',
    themeDarkMode: false,
  }
  export const isThemePrimary = (themeSettings) => {
    let decision =
      themeSettings.id &&
      themeSettings.id.length > 0 &&
      THEME_DETAILS.hasOwnProperty(themeSettings.id)
    if (decision) {
      /*console.log(
              "themeSettings.id",themeSettings.id,
              "themeSettings.id.length",themeSettings.id.length,
              "THEME_DETAILS.hasOwnProperty(themeSettings.id))",THEME_DETAILS.hasOwnProperty(themeSettings.id),
              "result",decision)   */
    } else {
      decision = false
    }
    // console.log('isThemePrimary',decision)
  
    return decision
  }
  