<template>
  <div class="main-container !p-0 !w-full !h-full -bg-red-400">
    <!-- Top Box -->
    <div
      :style="{ height: child1Height }"
      class="child !w-full bg-white"
      ref="child1"
    >
      <div v-if="selectedRight === 'watchlist'" class="w-full h-full">
        <WatchList
          @handleOpenWatchlistDetailModal="handleOpenWatchlistDetailModal"
          @handleOpenDownloadSymbolModal="handleOpenDownloadSymbolModal"
          :selectedRight="selectedRight"
        />
      </div>

      <div
        v-if="selectedRight === 'scannerResults'"
        class="w-full h-full -bg-slate-500"
      >
        <ScannerResult
          :selectedRight="selectedRight"
          :socketScannerResult="socketScannerResult"
          :progressPercentage="progressPercentage"
          :totalSymbolCount_second_props="totalSymbolCountProps"
        />
      </div>

      <div v-if="selectedRight === 'trainingMode'" class="w-full h-full">
        <TrainingMode :selectedRight="selectedRight" />
      </div>

      <div v-if="selectedRight === 'comparingSymbols'" class="w-full h-full">
        <ComparingSymbolsRight :selectedRight="selectedRight" />
      </div>
    </div>
    <!-- Resize Handle -->
    <div
      class="resize-handle !bg-[#E0E3EB] -!bg-[#c6d1d8] z-[100] flex justify-center items-center !sticky top-0 dark:!bg-[#7a8290]"
      @mousedown="startResizing"
      @mouseup="stopResizing"
      @touchstart="startResizing"
      @touchend="stopResizing"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 23"
        fill="currentColor"
        class="size-10"
      >
        <path
          fill-rule="evenodd"
          d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <!-- Bottom Box -->
    <div
      :style="{ height: child2Height }"
      class="child !w-full bg-white"
      ref="child2"
    >
      <div
        class="content w-full h-full overflow-y-auto no-scrollbar overflow-x-hidden -bg-black z-[100] dark:!bg-[#1A1A1A] !text-[#000] dark:!text-[#F6F6F6]"
      >
        <TickerInfo />
      </div>
    </div>
    <!-- 1 AddWatchlistSymbolModal -->

    <div v-if="isOpenAddSymbolModal" style="z-index: 12005">
      <BaseModal>
        <AddWatchlistSymbolModal
          @closeModal="handleCloseWatchlistSymbolModal"
        />
      </BaseModal>
    </div>

    <!-- 2 DownloadSymbolModal -->

    <div v-if="isOpenDownloadSymbolModal" style="z-index: 12005">
      <BaseModal>
        <DownloadSymbolModal
          @close-Download-Modal="handleCloseDownloadSymbolModal"
        />
      </BaseModal>
    </div>

    <!-- 3 WatchlistDetailModal -->

    <div v-if="isOpenWatchlistDetailModal">
      <BaseModal>
        <WatchlistModalTv
          @closeModal="handleCloseWatchlistDetailModal"
          @handleOpenDownloadSymbolModal="handleOpenDownloadSymbolModal"
          @handleOpenEditWatchlistModal="handleOpenEditWatchlistModal"
          @handleOpenRemoveWatchlistModal="handleOpenRemoveWatchlistModal"
          @handleOpenAddWatchlistModal="handleOpenAddWatchlistModal"
          :watchlistData="getSelectedWatchlist"
        />
      </BaseModal>
    </div>

    <!-- 4 EditWatchlistModal -->
    <div v-if="isOpenEditWatchlistModal" style="z-index: 12005">
      <BaseModal>
        <EditWatchlistModal
          @close-Edit-Modal="handleCloseEditWatchlistModal"
          :isAdd="isAddModal"
        />
      </BaseModal>

      <!-- 5 DeleteWatchlistModal -->
    </div>

    <!-- 5 removeWatchlistModel -->
    <div v-if="isOpenDeleteWatchlistModal" style="z-index: 12005">
      <BaseModal>
        <RemoveWatchlistModal
          @closeModal="handleCloseRemoveWatchlistModal"
          :deleteWatchlistId="deleteWatchId"
        />
      </BaseModal>
    </div>

    <!-- 6 RemoveSymbolModal -->
    <div v-if="isOpenRemoveSymbolModal">
      <BaseModal>
        <RemoveSymbolModal
          @closeRemoveModal="handleCloseRemoveSymbolModal"
          :data="removeSymbol"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import StackInfoVue from './StackInfo.vue'
import ScanResultVue from './ScanResult.vue'
import MyWatchListVue from './MyWatchList.vue'
import WatchlistModalTv from './WatchlistModalTv.vue'
import RemoveWatchlistModal from './RemoveWatchlistModal.vue'
import RemoveSymbolModal from './RemoveSymbolModal.vue'
import EditWatchlistModal from './EditWatchlistModal.vue'
import AddWatchlistSymbolModal from './AddWatchlistSymbolModal.vue'
import DownloadSymbolModal from './DownloadSymbolModal.vue'
import TickerInfo from './TickerInfo.vue'
import { mapActions, mapGetters } from 'vuex'
import Minimize from './Minimize.vue'
import SymbolTable from './SymbolTable.vue'
import BaseModal from './BaseModal.vue'
import ScannerUi from './ScannerUi.vue'
import TrainingModeComp from './TrainingModeComp'
import RightBarComp from './RightBarComp.vue'
import RightBarHead from './RightBarHead.vue'
import WatchList from './RightSideComponent/Watchlist/WatchList.vue'
import ScannerResult from './RightSideComponent/ScannerResult/ScannerResult.vue'
import TrainingMode from './RightSideComponent/TrainingMode/TrainingMode.vue'
import ComparingSymbolsRight from './RightSideComponent/ComparingSymbols/ComparingSymbolsRight.vue'
import { chartEvents } from '../chartEvents'

export default {
  data() {
    return {
      isResizing: false,
      child1Height: '35%', // Set initial height for Child 1
      child2Height: '65%', // Set initial height for Child 2
      initialMouseY: 0,
      initialChild1Height: 0,
      initialChild2Height: 0,

      isMinimizeStock: true,
      isMinimizeScan: true,
      isAddModal: false,
      isMinimizeWatch: true,
      isOpenEditWatchlistModal: false,
      isOpenDeleteWatchlistModal: false,
      isOpenRemoveSymbolModal: false,
      isOpenWatchlistDetailModal: false,
      isOpenDownloadSymbolModal: false,
      isOpenAddSymbolModal: false,
      isdisplay: true,

      width: innerWidth,

      trainingData: [
        {
          id: 1,
          name: 'Open',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'High',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Low',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Close',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Volume',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Value',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'VWAP',
          price: '$220.32',
        },
      ],
      symbols: [],
    }
  },
  components: {
    TrainingModeComp,
    StackInfoVue,
    ScanResultVue,
    MyWatchListVue,
    Minimize,
    SymbolTable,
    TickerInfo,
    BaseModal,
    RemoveWatchlistModal,
    EditWatchlistModal,
    RemoveSymbolModal,
    RightBarHead,
    ScannerUi,
    RightBarComp,
    WatchList,
    ScannerResult,
    TrainingMode,
    WatchlistModalTv,
    DownloadSymbolModal,
    AddWatchlistSymbolModal,
    ComparingSymbolsRight,
  },
  methods: {
    ...mapActions([
      'addToWatchlist',
      'updatedWatchlist',
      'removedWatchlist',
      'updateCopySymbol',
    ]),

   
      startResizing(event) {
        console.log('Starting resize...')
        this.isResizing = true
        // Check if the event is a touch event; otherwise, use mouse event
        this.initialMouseY = event.touches
          ? event.touches[0].clientY
          : event.clientY
        this.initialChild1Height = this.$refs.child1.offsetHeight
        this.initialChild2Height = this.$refs.child2.offsetHeight

        // Add event listeners for both touch and mouse events
        document.addEventListener('mousemove', this.resize)
        document.addEventListener('mouseup', this.stopResizing)
        document.addEventListener('touchmove', this.resize, { passive: false })
        document.addEventListener('touchend', this.stopResizing, {
          passive: false,
        })
      },

      stopResizing() {
        console.log('Stopped resizing')
        this.isResizing = false

        // Remove event listeners for both touch and mouse events
        document.removeEventListener('mousemove', this.resize)
        document.removeEventListener('mouseup', this.stopResizing)
        document.removeEventListener('touchmove', this.resize)
        document.removeEventListener('touchend', this.stopResizing)
      },

      resize(event) {
        if (!this.isResizing) return

        // Check if the event is a touch event; otherwise, use mouse event
        const clientY = event.touches ? event.touches[0].clientY : event.clientY
        const deltaY = clientY - this.initialMouseY // Calculate the mouse/touch movement
        const containerHeight = this.$el.offsetHeight // Get the total container height

        // Calculate the new percentage-based heights for the children
        const newHeight1 =
          ((this.initialChild1Height + deltaY) / containerHeight) * 100
        const newHeight2 =
          ((this.initialChild2Height - deltaY) / containerHeight) * 100

        // Ensure that the new heights don't fall below a reasonable minimum (in percentage)
        if (newHeight1 > 6 && newHeight2 > 7) {
          this.child1Height = `${newHeight1}%`
          this.child2Height = `${newHeight2}%`
        }
      
    },

    // Screen Width
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },

    // WatchlistModalTv
    handleOpenWatchlistDetailModal() {
      this.isOpenWatchlistDetailModal = true
    },

    handleCloseWatchlistDetailModal() {
      this.isOpenWatchlistDetailModal = false
    },

    handleCloseRemoveSymbolModal() {
      this.removeSymbol = null
      this.isOpenRemoveSymbolModal = false
    },
    closeWatchlistModal() {
      this.isAddModal = false
      this.isOpenWatchlistDetailModal = false
    },

    // RemoveWatchlistSymbolModal
    openAddSymbolModal() {
      this.isOpenAddSymbolModal = true
      console.log('openAddSymbolModal', this.isOpenAddSymbolModal)
    },

    handlecloseSymbolModal() {
      this.isOpenRemoveSymbolModal = false
    },

    // EditWatchlistModal
    handleOpenEditWatchlistModal() {
      this.isOpenEditWatchlistModal = true
    },
    handleOpenRemoveWatchlistModal(id) {
      this.deleteWatchId = id
      this.isOpenDeleteWatchlistModal = true
    },
    handleCloseWatchlistSymbolModal() {
      this.isOpenAddSymbolModal = false
    },
    handleOpenAddWatchlistModal() {
      this.isAddModal = true
      this.isOpenEditWatchlistModal = true
    },

    handleCloseEditWatchlistModal() {
      this.isAddModal = false
      this.isOpenEditWatchlistModal = false
      this.updateCopySymbol([])
    },
    handleCloseRemoveWatchlistModal() {
      this.deleteWatchId = null
      this.isOpenDeleteWatchlistModal = false
    },
    // OpenDownloadSymbolModal

    handleOpenDownloadSymbolModal(watchlist) {
      this.isOpenDownloadSymbolModal = true
      // conosole.log(watchlistdataSymbol)
      // if (watchlist.length > 0) {
      //   this.isOpenDownloadSymbolModal = true
      //   console.log('download 1')
      // } else if (this.getSelectedWatchlist.length > 0) {
      //   this.isOpenDownloadSymbolModal = true
      //   console.log('download 2')
      // } else {
      //   alert('First add symbol in your watchlist.')
      //   console.log('download 3')
      // }
    },

    handleCloseDownloadSymbolModal() {
      this.isOpenDownloadSymbolModal = false
    },

    openEditWatchlist(item) {
      console.log('edit emit', item)
      this.$emit('openEditWatchlist', item)
    },

    handleAddWatchlist() {
      this.$emit('handleAddWatchlist')
    },
    onDeleteWatchlistSymbol(item) {
      this.$emit('openRemoveWatchlistSymbol', item)
    },
  },
  mounted() {
    console.log('scanner percentage==>', this.progressPercentage)
    const openRemoveSymbolModalFunc = (sym) => {
      this.removeSymbol = sym
      this.isOpenRemoveSymbolModal = true
      console.log('delete 1', this.isOpenRemoveSymbolModal)
    }

    window.addEventListener('resize', this.updateScreenWidth)

    chartEvents.$on('openRemoveSymbolModal', openRemoveSymbolModalFunc)
    chartEvents.$on('openAddSymbolModal1', this.openAddSymbolModal)

    // Ensure refs are set correctly
    if (this.$refs.box) {
      this.boxHeight = this.$refs.box.offsetHeight
      this.boxWidth = this.$refs.box.offsetWidth
      this.boxTop = this.$refs.box.offsetTop
      this.boxLeft = this.$refs.box.offsetLeft
    }

    if (this.windowHeight >= 800) {
      this.boxTop = 328
      this.boxHeight = 535
    } else if (this.windowHeight >= 756) {
      this.boxTop = 280
      this.boxHeight = 420
    } else {
      this.boxTop = 779
    }
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateScreenWidth)
    chartEvents.$off('openRemoveSymbolModal')
    chartEvents.$off('openAddSymbolModal')
  },
  watch: {
    selectedRight() {
      console.log('selectedRightW', this.selectedRight)
    },
    rboxStyle() {
      windowHeight
    },
    progressPercentage(val) {
      console.log('progressivePercentage rightsidebar==>', val)
    },
    totalSymbolCountProps(val){
  console.log("totalSymbolCount==>",val)
    }
  },
  props: {
    selectedRight: String,
    wHeight: Number,
    socketScannerResult: Array,
    progressPercentage: Number,
    totalSymbolCountProps: Number,
  },
}
</script>

<style>
.main-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  width: 100% !important;
  /* padding: 0px; */
  height: 100% !important;
  /* border: 2px solid black; */
}

.child {
  /* border: 1px solid #000; */
  padding: -10px;
  /* width: 100%; */
  box-sizing: border-box;
  overflow: hidden !important;
}

.resize-handle {
  height: 8px; /* Height of the resize handle */
  width: 100% !important; /* width of the resize handle */
  cursor: ns-resize !important; /* Cursor style for resizing */
  touch-action: none; /* Prevent default touch behavior like scrolling */
  background-color: #ccc; /* Color of the handle */
}
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
