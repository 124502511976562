<template>
    <div class="w-[18rem]">
       <img src="../assets/largelogo.svg" alt="ProTrading logo" class="logoImg" />
      </div>
 </template>
 
 <script>
 export default {
   name: 'Header',
   props: {
  
   },
   data() {
     return {
     
     };
   },
   methods: {
 
   }
 };
 </script>
 
 <style scoped>
 
 </style>