<template>
    <div id="app" class="w-full h-full">
      <apexchart type="line" width="100%" height="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import VueApexCharts from 'vue-apexcharts';
  
  Vue.component('apexchart', VueApexCharts);
  
  export default {
    name:'LineChart',
    data() {
      return {
        series: [
          {
            name: "Growth Rate",
            data: [
              { x: '1W', y: 50 },
              { x: '2W', y: 20 },
              { x: '1M', y: 62 },
              { x: '2M', y: 33 },
              { x: '3M', y: 57 },
              { x: '6M', y: 39 },
              { x: '9M', y: 62 },
              { x: '1Y', y: 30 },
            ]
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            toolbar: {
              show: false // Disable header tools
            },
            type: 'line',
          
        },
          xaxis: {
            categories: ['1W', '2W', '1M', '2M', '3M', '6M', '9M', '1Y'],
            type: 'category', // Use 'category' to handle custom labels
          },
          yaxis: {
            opposite: true,
            labels: {
              formatter: function (val) {
                return val + "%"; // Add percentage sign to y-axis labels
              }
            }
          },
          stroke: {
            curve: 'straight',
          },
         
          tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return val + "%"; // Add percentage sign to tooltip
              }
            }
          }
        }
      }
    }
  }
  </script>
  
  <style>
  #app {
    text-align: center;
    margin-top: 40px;
  }
  </style>
  