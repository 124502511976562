<template>
    <div :class="rounded" class="bg-white h-full w-full flex flex-row justify-end items-center px-[13px]">
        <!-- <div class="text-[#FFFFFF] font-sans font-[400] text-[12px]">{{ title }}</div> -->
 
        <div class="flex justify-end items-center space-x-10">

          <div  @click="$emit('selectAllEmit')"  class="text-[#646464] font-sans text-[14px] cursor-pointer">{{ Select }}</div>
          <div   @click="$emit('clearAllEmit')"  class="text-[#646464] font-sans text-[14px] cursor-pointer">{{ Clear }}</div>

            <!-- <div  @click="handleSvg">
              <div v-if="!isMinimizeProp" class=" cursor-pointer rounded-[4px] bg-darkBlue !px-1 !py-1">
                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#FFFFFF" class="size-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            </div>

            <div v-else class="cursor-pointer rounded-[4px] bg-darkBlue !px-1.5 !py-2">
                <svg width="9.5" height="6" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 1.5625C5.56065 1.5625 5.8125 1.31065 5.8125 1C5.8125 0.68935 5.56065 0.4375 5.25 0.4375H0.75C0.439342 0.4375 0.1875 0.68935 0.1875 1C0.1875 1.31065 0.439342 1.5625 0.75 1.5625H5.25Z" fill="#FFFFFF"/>
                </svg>
            </div>
            </div> -->

            
        </div>
        
       

    </div>
</template>
  <script>

export default {
  name: 'Minimize',
  data() {
    return {}
  },
  props: {
    title: String,
    isMinimizeProp: Boolean,
    rounded: String,
    Select: String,
    Clear: String,
  },
  components: {
  },
  methods: {
    handleSvg(){
      this.$emit("hide-Show-Svg")
    },
  },
  computed: {},
  mounted() {},
  watch: {},
}
</script>
  <style scoped>
</style>
  