<template>
  <div class="w-full h-full -bg-slate-800">
    <FormLayout>
      <div class="w-full h-full md:space-y-6 2lg:space-y-0 overflow-auto no-scrollbar -bg-slate-300 ">
        
        <!-- form -->
        <div
          style="display: flex; align-items: center; font-size: 15px"
          class="errorText -bg-yellow-300"
        >
          <span class="dontHaveText text-[#828282]"
            >Don't have an account?</span
          >
          <router-link :to="{ name: 'SignUp' }">
            <span style="display: flex; align-items: center">
              <span
                style="margin: 0px 5px 0px 5px; cursor: pointer"
                class="hoverUnderline dontHaveText text-appBlue"
              >
                Sign Up
              </span>
              <img src="../assets/login.svg" />
            </span>
          </router-link>
        </div>

        <!-- <div> -->

        

        <div class="w-full h-[40%] sm:h-[20%] -bg-slate-200 ">
          <img src="../assets/registrationSvgs/Login.svg" class="w-full h-full sm:hidden">
        </div>
       
        <div
          class="formFreeTrial xxs:!justify-between md:!justify-center -bg-slate-400"
          style="
            height: 20%;
            display: flex;
            flex-direction: column;
            font-size: 30px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 10px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>

          <span class="-headerTitle text-appGrayDark"> Sign In</span>
          <span
            v-if="showResponseError"
            class="errorText text-errorText"
            style="font-size: 15px; margin-top: 4%; font-weight: 300"
            >{{ showResponseError }}</span
          >
        </div>

        <!-- <div
          class="formBorder h-full flex -sm:justify-start xxs:mt-0 sm:mt-0 -xxs:justify-center flex-col -bg-green-400"
        > -->
          <!-- email -->

          <div class="inputHeight" style="height: 7% !important">
            <DynamicInput
              type="text"
              placeholder="Email"
              :isError="isEmailError"
              :iconImg="
                require(`@/assets/${
                  isEmailError ? 'redEmail.svg' : 'email.svg'
                }`)
              "
              v-model="email"
            />
          </div>

          <!-- email error -->

          <div style="height: 4%; margin-top: 1%">
            <span
              v-if="emailErrorMsg == 'already exits'"
              class="text-appBlue text-[13px]"
              >⚠ It seems you already have an account with Pro Trader.
              <router-link :to="{ name: 'SignIn' }" class="text-appBlue">
                <span style="text-decoration: underline; cursor: pointer"
                  >Login here</span
                >
              </router-link>
              or
              <router-link :to="{ name: 'ResetPassword' }" class="text-appBlue">
                <span style="text-decoration: underline; cursor: pointer"
                  >reset your password</span
                >
              </router-link>
            </span>
            <span v-else class="errorText text-errorText text-[13px]">{{
              emailErrorMsg
            }}</span>
          </div>

          <!-- password -->

          <div
            class="inputHeight"
            style="height: 7% !important; margin: 2% 0px 1% 0px"
            :style="{
              marginTop: emailErrorMsg == 'already exits' ? '4%' : '2%',
            }"
          >
            <DynamicInput
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              :iconImg="
                require(`@/assets/${
                  isPasswordError
                    ? 'redEye.svg'
                    : showPassword
                    ? 'openEye.svg'
                    : 'closeEye.svg'
                }`)
              "
              v-model="password"
              :isError="isPasswordError"
              @enter-pressed="handleSignin"
              @password-visibility-toggled="handlePasswordVisibilityToggle"
            />
          </div>

          <!-- password error -->

          <div style="height: 2%; margin-top: 1%" class="mb-3">
            <span
              v-if="passwordErrorMsg"
              class="errorText text-errorText text-[13px]"
              >{{ showWarningIcon ? '⚠' : '' }} {{ passwordErrorMsg }}</span
            >
          </div>

          <!-- button -->
          <div
            style="height: 7% !important"
            class="btnStyle inputHeight xxs:mt-[8rem] sm:mt-2"
          >
            <button
              :class="email && password ? 'btnRedHover' : 'btnGrayHover'"
              @click="handleSignin"
              :style="{
                background: email && password ? '#110024' : '#E0E0E0',
                color: email && password ? 'white' : '#828282',
                cursor: isLoading ? 'not-allowed' : 'pointer',
                pointerEvents: isLoading ? 'none' : 'auto',
              }"
              style="
                height: 100%;
                width: 100%;
                border-radius: 5px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              "
            >
              <span v-if="isLoading" style="margin-right: 10px">
                <Loader customClass="w-8 h-8" />
              </span>
              <span>
                {{ isLoading ? 'Signing In' : 'Sign In' }}
              </span>
              <div v-if="email && password" style="margin-left: 8%">
                <img src="../assets/rightarrow.svg" />
              </div>
            </button>
          </div>

          <div class="w-full flex justify-end pt-1">
            <router-link :to="{ name: 'ForgetPassword' }">
              <div class="w-fit hover:underline text-appBlue cursor-pointer">
                Forgot your password?
              </div>
            </router-link>
          </div>

        <div class="font-[300] mt-8 text-justify sm:hidden">
          <span class="text-[#828282] leading-1">
            <span class="font-bold ">IMPORTANT NOTICE:</span class="!text-[#d13030] text-[104px]" style="color: #828282 !important;"> ProTrading Scans website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. ProTrading Scans are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.
          </span>
        <!-- </div> -->
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import DynamicInput from '../component/DynamicInput.vue'
import ResendEmail from '../component/ResendEmail.vue'
import Loader from '../component/Loading.vue'

import { signin,isClientAccountActive } from '../services/user'
import FormLayout from '../component/FormLayout.vue'

export default {
  name: 'SigninPage',
  data() {
    return {
      isUnderstood: false,
      email: '',
      password: '',
      emailErrorMsg: '',
      passwordErrorMsg: '',
      isEmailError: false,
      isPasswordError: false,
      requiredError: false,
      showPassword: false,
      showResponseError: '',
      isLoading: false,
      emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      showWarningIcon: false,
      priceId: null,
    }
  },
  components: {
    DynamicInput,
    ResendEmail,
    Loader,
    FormLayout,
  },

  methods: {
    async handleSignin() {
      if (!this.email && !this.password) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        this.requiredError = true
        return
      }

      if (!this.email) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        return
      }
      if (!this.password) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        return
      }

      if (this.email) {
        if (!this.emailRegix.test(this.email)) {
          this.isEmailError = true
          this.emailErrorMsg = 'Invalid Email'
          return
        }
      }

      this.isLoading = true
      const payload = {
        username: this.email,
        password: this.password,
      }
      const res = await signin(payload)     
      if (res?.data?.accessToken) {
        console.log("res==>",res)
        const token = res?.data?.accessToken
        const status = res?.data?.status
        const trialStatus = res?.data?.trialStatus
        localStorage.setItem('state', token)
        localStorage.setItem('stripeSession', status)
        if(trialStatus == 'valid'){
            localStorage.setItem('auth', true)
            this.$router.push('/')
        }else if(trialStatus == 'expired'){
              const isUserActive = await isClientAccountActive()
              console.log("isUserActive",isUserActive)
              if (isUserActive?.status === 'active'){
                localStorage.setItem('auth', true)
                this.$router.push('/')
              }else{
                this.$router.push('/product')
              }
        }else{
          this.showResponseError = 'Invalid credentials'
          setTimeout(() => {
            this.showResponseError = ''
          }, 5000)
        }


        // const isUserActive = await isClientAccountActive()
        // console.log("isUserActive",isUserActive)
        // if (isUserActive?.status === 'active'){
        // localStorage.setItem('auth', true)
        //   this.$router.push('/')
        // }else{
        //   this.$router.push('/product')
        // }
      
        // if(res?.status == "active" && res?.accessToken){
        //     this.$router.push('/')
        // }else{
        //   this.$router.push('/product')
        // }  
      } else {
        this.showResponseError = 'Invalid credentials'
        setTimeout(() => {
          this.showResponseError = ''
        }, 5000)
      
    }

      this.isLoading = false
    },
    handlePasswordVisibilityToggle() {
      this.showPassword = !this.showPassword
    },
  },
  computed: {},
  mounted() {},

  watch: {
    password(newPassword) {
      this.isPasswordError = false
      this.passwordErrorMsg = ''
      this.showWarningIcon = false
    },
    email() {
      this.isEmailError = false
      this.emailErrorMsg = ''
    },
  },
}
</script>

<style>
.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}
</style>
